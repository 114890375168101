import {
  GENERATE_PLAN,
  CREATE_CUSTOM_EVENT,
  HAS_PLAN,
  GET_STUDY_PLAN,
  DELETE_PLAN,
  TODAY_EVENTS,
  CLEAR_PLAN,
} from './actions'

export const hasPlanSelector = ({ studyPlan }) => studyPlan.hasPlan

export const userHasPlanLoading = ({ loading }) => !!loading[HAS_PLAN.ACTION]

export const isCreatingPlanLoading = ({ loading }) => !!loading[GENERATE_PLAN.ACTION]

export const creatingPlanError = ({ error }) => error[GENERATE_PLAN.ACTION]

export const getUniversitiesSelector = ({ studyPlan }) =>
  studyPlan.universities.map(university => ({
    id: university.id,
    label: `${university.name} - ${university.initials}`,
    value: university.name,
  }))

export const getCoursesSelector = ({ studyPlan }) =>
  studyPlan.courses.map(course => ({
    id: course.id,
    label: course.name,
    value: course.name,
  }))

export const getPlan = ({ studyPlan }) => studyPlan.plan

export const isGettingPlanLoading = ({ loading }) => !!loading[GET_STUDY_PLAN.ACTION]

export const isCreatingCustomEventLoading = ({ loading }) => !!loading[CREATE_CUSTOM_EVENT.ACTION]

export const createCustomEventError = ({ error }) => error[CREATE_CUSTOM_EVENT.ACTION]

export const isDeletingPlanLoading = ({ loading }) => !!loading[DELETE_PLAN.ACTION]

export const isCleaningPlanLoading = ({ loading }) => !!loading[CLEAR_PLAN.ACTION]

export const deletePlanError = ({ error }) => error[DELETE_PLAN.ACTION]

export const clearPlanError = ({ error }) => error[CLEAR_PLAN.ACTION]

export const geTodayEvents = ({ studyPlan }) => {
  const { todayEvents } = studyPlan
  return Object.entries(todayEvents).reduce(
    (res, val) => [...res, ...val[1].map(event => ({ ...event, type: val[0] }))],
    []
  )
}

export const isGettingTodayEventsLoading = ({ loading }) => loading[TODAY_EVENTS.ACTION]
