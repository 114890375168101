import { defineAction } from '_utils/redux'
import * as studyPlan from '_services/study-plan'

export const HAS_PLAN = defineAction('HAS_PLAN')
export const GENERATE_PLAN = defineAction('GENERATE_PLAN')
export const GET_UNIVERSITIES = defineAction('GET_UNIVERSITIES')
export const GET_COURSES = defineAction('GET_COURSES')
export const GET_STUDY_PLAN = defineAction('GET_STUDY_PLAN')
export const CREATE_CUSTOM_EVENT = defineAction('CREATE_CUSTOM_EVENT')
export const DELETE_PLAN = defineAction('DELETE_PLAN')
export const CLEAR_PLAN = defineAction('CLEAR_PLAN')
export const TODAY_EVENTS = defineAction('TODAY_EVENTS')

export const hasPlan = () => (dispatch, getState) =>
  dispatch({
    type: HAS_PLAN.ACTION,
    payload: studyPlan.hasPlan(getState().user.key),
  })

export const generatePlan = payload => (dispatch, getState) =>
  dispatch({
    type: GENERATE_PLAN.ACTION,
    payload: studyPlan.generateStudyPlan(getState().user.key, payload),
  })

export const getUniversities = () => (dispatch, getState) =>
  dispatch({
    type: GET_UNIVERSITIES.ACTION,
    payload: studyPlan.getUniversities(getState().user.key),
  })

export const getCourses = universityId => (dispatch, getState) =>
  dispatch({
    type: GET_COURSES.ACTION,
    payload: studyPlan.getCourses(getState().user.key, universityId),
  })

export const getStudyPlan = payload => (dispatch, getState) =>
  dispatch({
    type: GET_STUDY_PLAN.ACTION,
    payload: studyPlan.getStudyPlan(getState().user.key, payload),
  })

export const getEventsByDay = payload => (dispatch, getState) =>
  dispatch({
    type: TODAY_EVENTS.ACTION,
    payload: studyPlan.getEventsByDay(getState().user.key, payload),
  })

export const createCustomEvent = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_CUSTOM_EVENT.ACTION,
    payload: studyPlan.createCustomEvent(getState().user.key, payload),
  })

export const deletePlan = () => (dispatch, getState) =>
  dispatch({
    type: DELETE_PLAN.ACTION,
    payload: studyPlan.deletePlan(getState().user.key),
  })

export const deletePlanAndHistory = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_PLAN.ACTION,
    payload: studyPlan.deletePlanAndHistory(getState().user.key),
  })
