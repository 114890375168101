export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api-staging.prouniversidade.com.br/api/v1/'

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID

export const WIRECARD_KEY = process.env.REACT_APP_WIRECARD_KEY

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
