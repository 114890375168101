export const ROUTES = {
  LOGIN: 'login',
  RESET_PASSWORD: 'recuperar-senha',
  SIGNUP: 'cadastro',
  DASHBOARD: 'dashboard',
  CALENDAR: 'calendario',
  SUBJECTS: 'disciplinas',
  ESSAY: 'redacao',
  ESSAY_THEMES: 'redacao/temas',
  EXAMS: 'simulados',
  ENEM_EXAMS: 'simulados/enem',
  MOCK_EXAMS: 'simulados/vestibulares',
  PROFILE: 'meu-perfil',
  NOTIFICATIONS: 'notificacoes',
  PAYMENTS: 'pagamentos',
  MONITORING: 'monitoria',
  LIVE_CLASSES: 'aula-ao-vivo',
  MATERIALS: 'material-didatico',
  MATERIALS_ENEM: 'material-didatico/enem',
  MATERIALS_EXAMS: 'material-didatico/vestibular',
}
