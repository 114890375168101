/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useReducer, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'react-loader-spinner'

import Input from '_components/input'
import Button, { BUTTON_THEMES } from '_components/button'
import ForgotPasswordModal from '_components/forgot-password-modal'
import { validateEmail, validatePassword } from '_utils/helpers'
import { login } from '_modules/user/actions'
import { isLoginLoadingSelector, loginErrorSelector } from '_modules/user/selectors'

import { INITIAL_STATE, reducer, UPDATE_FORM, EMAIL, PASSWORD } from './reducer'
import styles from './styles.css'

const LoginForm = () => {
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)
  const dispatchAction = useDispatch()
  const isLoginLoading = useSelector(isLoginLoadingSelector)
  const error = useSelector(loginErrorSelector)

  const onInputChange = useCallback(event => {
    const { name, value } = event.target

    dispatch({
      type: UPDATE_FORM,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const handleDispatch = useCallback(
    data => {
      dispatchAction(login(data))
    },
    [dispatchAction]
  )

  const validateFields = useMemo(
    () => validateEmail(formData[EMAIL]) && validatePassword(formData[PASSWORD]),
    [formData]
  )

  const modalRef = useRef()

  const openModal = useCallback(() => modalRef.current && modalRef.current.setVisibility(true), [])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      handleDispatch(formData)
    },
    [handleDispatch, formData]
  )

  const errorMessage = useMemo(() => (error ? 'Email e/ou senha incorreto(s).' : ''), [error])
  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          placeholder="E-mail"
          type={EMAIL}
          autoComplete={EMAIL}
          onChange={onInputChange}
          error={error}
          name={EMAIL}
        />
        <Input
          placeholder="Senha"
          type={PASSWORD}
          autoComplete={PASSWORD}
          onChange={onInputChange}
          name={PASSWORD}
          error={error}
          errorText={errorMessage}
        />
        <Button
          className={styles.button}
          theme={BUTTON_THEMES.PRIMARY}
          type="submit"
          disabled={!validateFields}
        >
          {isLoginLoading ? (
            <Loader type="Oval" color="white" height="16px" width="16px" />
          ) : (
            'Entrar'
          )}
        </Button>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span onClick={openModal} className={styles.forgot}>
          Esqueceu sua senha? Clique aqui
        </span>
      </form>
      <ForgotPasswordModal ref={modalRef} />
    </>
  )
}

export default React.memo(LoginForm)
