import React from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'

import Arrow from '_assets/svgs/breadcrumb-icon.svg'

import styles from './styles.css'

const Breadcrumb = ({ text, to }) => {
  return (
    <Link className={styles.link} to={`/${to}`}>
      <img alt="Icon Back" src={Arrow} />
      <p className={styles.text}>{text}</p>
    </Link>
  )
}

Breadcrumb.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default Breadcrumb
