import React, { useMemo } from 'react'
import Lottie from 'react-lottie'
import { Link } from '@reach/router'

import useWindowSize from '_hooks/use-windows-size'
import TourAnimation from '_assets/lotties/tour-animation.json'
import { lottiesDefaultOptions } from '_utils/helpers'

import styles from './styles.css'

const generatingTourAnimationOptions = {
  ...lottiesDefaultOptions,
  loop: true,
  animationData: TourAnimation,
}
const PlansSection = () => {
  const currentWdith = useWindowSize().width

  const animationSize = useMemo(() => {
    if (currentWdith <= 1024) {
      return 280
    }
    return 400
  }, [currentWdith])

  return (
    <section className={styles.tour}>
      <h1 className={styles['tour-title']}>
        A nova plataforma do Pró Online chegou para facilitar os seus estudos
      </h1>
      <div className={styles['button-content']}>
        <h2 className={styles['tour-subtitle']}>
          Criamos uma nova experiência pensando em você. Agora você tem mais controle e praticidade
          para estudar. Faça um tour gratuito para conferir.
        </h2>
        <Link className={styles.button} to="/cadastro">
          Tour gratuito pela plataforma
        </Link>
      </div>
      <div className={styles.animation}>
        <Lottie options={generatingTourAnimationOptions} width={animationSize} />
      </div>
    </section>
  )
}

export default PlansSection
