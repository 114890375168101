import { defineAction } from '_utils/redux'
import * as service from '_services/essay'

export const GET_ESSAY_THEMES = defineAction('GET_ESSAY_THEMES')
export const CREATE_ESSAY = defineAction('CREATE_ESSAY')
export const SELECT_THEME = defineAction('SELECT_THEME')
export const GET_ESSAYS = defineAction('GET_ESSAYS')
export const GET_ESSAY = defineAction('GET_ESSAY')
export const DELETE_ESSAY = defineAction('DELETE_ESSAY')

export const getEssayThemes = () => (dispatch, getState) =>
  dispatch({
    type: GET_ESSAY_THEMES.ACTION,
    payload: service.getEssayThemes(getState().user.key),
  })

export const getEssays = () => (dispatch, getState) =>
  dispatch({
    type: GET_ESSAYS.ACTION,
    payload: service.getEssays(getState().user.key),
  })

export const createEssay = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_ESSAY.ACTION,
    payload: service.createEssay(getState().user.key, payload),
  })

export const selectTheme = payload => dispatch =>
  dispatch({
    type: SELECT_THEME.ACTION,
    payload,
  })

export const getEssay = essayId => (dispatch, getState) =>
  dispatch({
    type: GET_ESSAY.ACTION,
    payload: service.getEssay(essayId, getState().user.key),
  })

export const deleteEssay = (essayId, callback) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_ESSAY.PENDING,
  })
  try {
    await service.deleteEssay(essayId, getState().user.key)
    dispatch({
      type: DELETE_ESSAY.FULFILLED,
      payload: essayId,
    })
    callback()
  } catch (error) {
    dispatch({ type: DELETE_ESSAY.REJECTED, payload: error })
  }
}
