import React from 'react'

import Tag from '_components/calendar/tag'
import { EVENTS } from '_utils/helpers'

import styles from './styles.css'

const mock = [
  {
    id: 1,
    title: 'Início das inscrições ENEM 2020',
    type: EVENTS.CUSTOM_EVENTS,
    subtitle: 'Vestibulares',
    link: 'https://google.com.br',
  },
  {
    id: 2,
    title: 'Funções inorgânicas (Ácidos II)',
    type: EVENTS.LESSON,
    topic: { name: 'Teste', subject: { name: 'teste2' } },
    course: 'Química',
    fontColor: 'e01e55',
    link: '/disciplinas/quimica/12/1',
  },
  {
    id: 3,
    title: 'Matemática com Prof. Felipe',
    type: EVENTS.MONITORING,
    course: 'Matemática',
    link: 'disciplinas/quimica/12/1',
    timeStart: '15h',
  },
  {
    id: 4,
    title: 'Física - Forças conservativas e não conservativas',
    type: EVENTS.LIVE_CLASSES,
    course: 'Física',
    link: 'disciplinas/quimica/12/1',
    timeStart: '16h',
    timeEnd: '17h',
  },
]

const TodayLessonsCard = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Agenda de hoje</p>
      {mock.map(activity => (
        <Tag key={activity.id} data={activity} />
      ))}
    </div>
  )
}

export default React.memo(TodayLessonsCard)
