import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { ROUTES } from '_utils/constants'
import Modal from '_components/common/modal'
import Button, { BUTTON_THEMES } from '_components/button'
import { getUserSelector } from '_modules/user/selectors'

import styles from './styles.css'

const StartExamModal = React.forwardRef(({ startExam }, ref) => {
  const { hasActivePlan, onFreeTrial } = useSelector(getUserSelector)

  const closeModal = useCallback(() => {
    ref.current.setVisibility(false)
  }, [ref])

  const handleStart = useCallback(() => {
    if (!hasActivePlan || onFreeTrial) {
      navigate(`/${ROUTES.DASHBOARD}`)
      toast(
        <Alert
          theme={ALERT_THEMES.ERROR}
          message="Você precisa ter um plano ativo para realizar novos simulados!"
        />
      )
    } else {
      startExam()
      ref.current.setVisibility(false)
    }
  }, [hasActivePlan, onFreeTrial, ref, startExam])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Iniciar simulado" hideCloseButton>
      <div className={styles.start}>
        <h1 className={styles.title}>Iniciar simulado</h1>
        <p className={styles.description}>Tem certeza que deseja iniciar este simulado?</p>

        <div className={styles.buttons}>
          <Button
            theme={BUTTON_THEMES.NO_BACKGROUND}
            className={styles.button}
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button theme={BUTTON_THEMES.PINK_NORMAL} onClick={handleStart} className={styles.button}>
            Iniciar
          </Button>
        </div>
      </div>
    </Modal>
  )
})

StartExamModal.propTypes = {
  startExam: PropTypes.func,
}

StartExamModal.defaultProps = {
  startExam: () => {},
}

export default React.memo(StartExamModal)
