export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const EMAIL = 'email'
export const BIRTH_DATE = 'birthDate'
export const CPF = 'cpf'
export const PHONE_NUMBER = 'phoneNumber'
export const CEP = 'cep'
export const ADDRESS = 'address'
export const ADDRESS_NUMBER = 'addressNumber'
export const ADDRESS_COMPLEMENT = 'addressComplement'
export const STATE = 'stateInitials'
export const CITY = 'city'
export const NEIGHBORHOOD = 'neighborhood'

export const INITIAL_STATE = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [EMAIL]: '',
  [BIRTH_DATE]: '',
  [CPF]: '',
  [CEP]: '',
  [PHONE_NUMBER]: '',
  [ADDRESS]: '',
  [ADDRESS_NUMBER]: '',
  [ADDRESS_COMPLEMENT]: '',
  [NEIGHBORHOOD]: '',
  [STATE]: '',
  [CITY]: '',
}

export const UPDATE_FORM = 'UPDATE_FORM'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
