import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'

import { ReactComponent as ArrowDown } from '_assets/svgs/arrow-down.svg'
import { ReactComponent as ArrowUp } from '_assets/svgs/bold-arrow-up.svg'
import useWindowSize from '_hooks/use-windows-size'

import styles from '../styles.css'

const BarItem = ({ id, isMenuOpen, link, subMenu, pathname, label, icon, toggle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentWidth = useWindowSize().width
  const isMobile = currentWidth <= 480
  const toggleItem = useCallback(() => setIsOpen(prev => !prev), [])

  const handleNavigate = useCallback(
    event => {
      const route = event.currentTarget.name
      if (isMobile) {
        toggle()
      }
      navigate(`/${route}`)
    },
    [isMobile, toggle]
  )

  const renderSubMenu = useMemo(() => {
    if (!subMenu?.length || !isOpen) {
      return null
    }

    return subMenu.map(item => (
      <button
        key={item.id}
        className={classnames(styles['menu-option'], styles['sub-menu'])}
        name={item.link}
        onClick={handleNavigate}
        type="button"
      >
        {item.icon}
        {isMenuOpen && <span className={styles['menu-label']}>{item.label}</span>}
      </button>
    ))
  }, [handleNavigate, isMenuOpen, isOpen, subMenu])

  const renderWrapper = children => {
    if (!subMenu?.length) {
      return (
        <button
          key={id}
          className={classnames(styles['menu-option'], {
            [styles.selected]: link === pathname,
          })}
          onClick={handleNavigate}
          name={link}
          type="button"
        >
          {children}
        </button>
      )
    }
    return (
      <button
        type="button"
        onClick={toggleItem}
        className={classnames(styles['menu-option'], styles['sub-menu'], {
          [styles.selected]: link === pathname,
        })}
      >
        {children}
      </button>
    )
  }

  return (
    <>
      {renderWrapper(
        <>
          {icon}
          {isMenuOpen && <span className={styles['menu-label']}>{label}</span>}
          {subMenu?.length &&
            (isOpen ? (
              <ArrowUp className={styles.arrow} />
            ) : (
              <ArrowDown className={styles.arrow} />
            ))}
        </>
      )}
      {renderSubMenu}
    </>
  )
}

BarItem.propTypes = {
  id: PropTypes.number.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.shape({}).isRequired,
    })
  ),
  pathname: PropTypes.string.isRequired,
  toggle: PropTypes.func,
}

BarItem.defaultProps = {
  subMenu: null,
  toggle: () => {},
}

export default BarItem
