import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import { SUBJECT_THEMES, getThemeBackground } from '_utils/helpers'
import { ReactComponent as ProCameraLogo } from '_assets/svgs/pro-camera-logo.svg'

import styles from './styles.css'

const SubjectCard = ({ subject, theme, numberOfLessons, watchedLessons, subjectId, icon }) => {
  const cardStyle = useMemo(() => ({ backgroundImage: `url(${getThemeBackground(theme)})` }), [
    theme,
  ])

  return (
    <Link to={`${subjectId}`} className={styles.link}>
      <div className={classnames(styles.card, styles[`theme-${theme}`])} style={cardStyle}>
        <h2 className={styles.title}>{subject}</h2>
        <div className={styles.classes}>
          <ProCameraLogo className={styles.camera} />
          <p>{`${watchedLessons}/${numberOfLessons}`}</p>
        </div>
        <img className={styles.icon} src={icon} alt="" />
      </div>
    </Link>
  )
}

SubjectCard.propTypes = {
  icon: PropTypes.string,
  subject: PropTypes.string,
  theme: PropTypes.string,
  numberOfLessons: PropTypes.number,
  watchedLessons: PropTypes.number,
  subjectId: PropTypes.number.isRequired,
}

SubjectCard.defaultProps = {
  icon: '',
  subject: '',
  theme: SUBJECT_THEMES.RED,
  numberOfLessons: 0,
  watchedLessons: 0,
}

export default SubjectCard
