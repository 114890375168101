import React, { useCallback, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import parse from 'html-react-parser'

import styles from '../styles.css'

const EnemAlternatives = forwardRef(({ alternatives }, ref) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null)

  const handleSelectAlternative = useCallback(
    ({ currentTarget: { name } }) => setSelectedAnswer(prev => (prev !== name ? name : null)),
    []
  )

  useEffect(() => {
    if (alternatives) {
      setSelectedAnswer(null)
    }
  }, [alternatives])

  useImperativeHandle(ref, () => ({
    getSelectedAnswer: () => selectedAnswer,
  }))

  return (
    <div className={styles.alternatives}>
      {alternatives
        .sort((a, b) => {
          if (typeof a === 'string') {
            return a.value.localeCompare(b.value)
          }
          return Number(a.value) - Number(b.value)
        })
        .map(({ value, text, id, image }) => (
          <button
            key={id}
            type="button"
            name={value}
            onClick={handleSelectAlternative}
            className={classnames(styles.alternative, {
              [styles.selected]: selectedAnswer === value,
            })}
          >
            {image && (
              <div className={styles['image-container']}>
                <img src={image} alt={`Imagem da alternativa ${value}`} className={styles.image} />
              </div>
            )}
            <span className={styles['alternative-number']}>{value}.</span>
            <span className={styles.text}>{parse(text)}</span>
          </button>
        ))}
    </div>
  )
})

EnemAlternatives.propTypes = {
  alternatives: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default EnemAlternatives
