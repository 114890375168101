import React, { useState, useCallback, useMemo, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { usePrevious } from '_hooks/use-previous'
import Modal from '_components/common/modal'
import { getSubjectsSelector } from '_modules/subjects/selectors'
import InputCheck from '_components/input/input-check'
import Button, { BUTTON_THEMES } from '_components/button'
import { generatePlan } from '_modules/study-plan/actions'
import { isCreatingPlanLoading, creatingPlanError } from '_modules/study-plan/selectors'
import GeneratingPlanAnimation from '_assets/lotties/generating-study-plan.json'
import { lottiesDefaultOptions } from '_utils/helpers'

import styles from './styles.css'

const FILTER_SUBJECTS = {
  HORA_DO_ENEM: 'Hora do Enem',
  ESPANHOL: 'Espanhol',
  INGLES: 'Inglês',
}

const generatingPlanAnimationOptions = {
  ...lottiesDefaultOptions,
  loop: true,
  animationData: GeneratingPlanAnimation,
}

const SelectSubjects = ({ data, handleStep, setCreatePlan }) => {
  const dispatch = useDispatch()
  const isCreatingPlan = useSelector(isCreatingPlanLoading)
  const wasCreatingPlan = usePrevious(isCreatingPlan)
  const hasCreatePlanError = useSelector(creatingPlanError)

  useEffect(() => {
    if (wasCreatingPlan && !isCreatingPlan) {
      if (!hasCreatePlanError) {
        toast(<Alert theme={ALERT_THEMES.SUCCESS} message="Plano de estudo criado com sucesso!" />)
      } else {
        toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao tentar criar plano de estudo." />)
      }
      setCreatePlan(false)
    }
  }, [hasCreatePlanError, isCreatingPlan, setCreatePlan, wasCreatingPlan])

  const subjects = useSelector(getSubjectsSelector)

  const [selectedSubjects, setSelectedSubjects] = useState(
    subjects.filter(subject => subject.name !== FILTER_SUBJECTS.HORA_DO_ENEM)
  )
  const subjectOptions = useMemo(
    () =>
      subjects.filter(subject => {
        if (
          subject.name === FILTER_SUBJECTS.HORA_DO_ENEM ||
          subject.name === FILTER_SUBJECTS.ESPANHOL ||
          subject.name === FILTER_SUBJECTS.INGLES
        ) {
          return ''
        }
        return subject
      }),
    [subjects]
  )

  const languagesOptions = useMemo(
    () =>
      subjects.filter(subject => {
        if (subject.name === FILTER_SUBJECTS.ESPANHOL || subject.name === FILTER_SUBJECTS.INGLES) {
          return subject
        }
        return ''
      }),
    [subjects]
  )

  const onChangeSubjects = useCallback(
    event => {
      const id = Number(event.target.name)
      const wasInclude = selectedSubjects.find(subject => subject.id === id)
      if (wasInclude) {
        setSelectedSubjects(prevState => prevState.filter(subject => subject.id !== id))
      } else {
        setSelectedSubjects(prevState => [
          ...prevState,
          ...subjects.filter(subject => subject.id === id),
        ])
      }
    },
    [selectedSubjects, setSelectedSubjects, subjects]
  )

  const handlePreviousStep = useCallback(() => {
    handleStep(prevState => prevState - 1)
  }, [handleStep])

  const handleSubmit = useCallback(() => {
    const newSubjects = selectedSubjects.map(subject => subject.id)
    const payload = { ...data, subjectsToStudy: newSubjects }
    dispatch(generatePlan(payload))
  }, [selectedSubjects, data, dispatch])

  const shouldDisable = !selectedSubjects.length
  const isSubjectSelected = useCallback(
    subject => !!selectedSubjects.find(item => item.id === subject.id),
    [selectedSubjects]
  )
  return (
    <>
      {isCreatingPlan ? (
        <Modal className={styles.modal} defaultVisibility titleText="Gerando plano de estudo">
          <Lottie options={generatingPlanAnimationOptions} height={377} />
          <h1 className={styles['modal-title']}>Gerando calendário...</h1>
          <p className={styles['modal-subtitle']}>Aguarde um instante</p>
        </Modal>
      ) : (
        <>
          <h2 className={styles.title}>Disciplinas</h2>
          <h2 className={classnames(styles.title, styles.subtitle)}>
            Quais disciplinas você deseja estudar?
          </h2>
          <div className={styles['select-checkbox']}>
            {subjectOptions.map(subject => (
              <div key={subject.id} className={styles.subject}>
                <InputCheck
                  checked={isSubjectSelected(subject)}
                  onChange={onChangeSubjects}
                  name={subject.id}
                  id={subject.name}
                />
                <p className={styles.name}>{subject.name}</p>
              </div>
            ))}
          </div>
          <h2 className={classnames(styles.title, styles.subtitle)}>
            Qual língua estrangeira você deseja estudar?
          </h2>
          <div className={styles['select-checkbox']}>
            {languagesOptions.map(subject => (
              <div key={subject.id} className={styles.subject}>
                <InputCheck
                  checked={isSubjectSelected(subject)}
                  onChange={onChangeSubjects}
                  name={subject.id}
                  id={subject.name}
                />
                <p className={styles.name}>{subject.name}</p>
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.next}
              theme={BUTTON_THEMES.NO_BACKGROUND}
              onClick={handlePreviousStep}
            >
              Voltar
            </Button>
            <Button
              className={styles.next}
              theme={BUTTON_THEMES.PINK_NORMAL}
              onClick={handleSubmit}
              disabled={shouldDisable}
            >
              Gerar
            </Button>
          </div>
        </>
      )}
    </>
  )
}

SelectSubjects.propTypes = {
  handleStep: PropTypes.func.isRequired,
  data: PropTypes.shape({
    course: PropTypes.number,
    preparationTest: PropTypes.string,
    university: PropTypes.number,
    weekDaysHours: PropTypes.shape({
      monday: PropTypes.number,
      tuesday: PropTypes.number,
      wednesday: PropTypes.number,
      thursday: PropTypes.number,
      friday: PropTypes.number,
      saturday: PropTypes.number,
      sunday: PropTypes.number,
    }),
  }),
  setCreatePlan: PropTypes.func.isRequired,
}

SelectSubjects.defaultProps = {
  data: {},
}

export default SelectSubjects
