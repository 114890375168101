import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import ReactPlayer from 'react-player'
import StarRatingComponent from 'react-star-rating-component'
import { Link } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Arrow from '_assets/svgs/pink-left-arrow.svg'
import Report from '_components/report'
import ReportErrorModal from '_components/report-error-modal'
import { ROUTES } from '_utils/constants'
import { getLiveLesson, getMonitoring, rateMonitoring } from '_modules/lesson/actions'
import { getLiveLessonData } from '_modules/lesson/selectors'
import { getYoutubeVideoId, getYoutubeEmbedURL, getYoutubeLiveCommentsURL } from '_utils/helpers'

import styles from './styles.css'

const LiveLesson = ({ location, lessonId }) => {
  const pathname = useMemo(() => location.pathname.split('/')[1], [location.pathname])
  const reportErrorModalRef = useRef()
  const isMonitoring = pathname === ROUTES.MONITORING
  const dispatch = useDispatch()

  const { title, rating: currentRating, videoUrl } = useSelector(getLiveLessonData(isMonitoring))
  const [rating, setRating] = useState(currentRating)
  const [isChatVisible, setChatVisibility] = useState(true)
  const embedURL = useMemo(() => getYoutubeEmbedURL(getYoutubeVideoId(videoUrl)), [videoUrl])
  const commentsURL = useMemo(() => getYoutubeLiveCommentsURL(getYoutubeVideoId(videoUrl)), [
    videoUrl,
  ])
  const handleChatVisibility = useCallback(() => setChatVisibility(prev => !prev), [])

  useEffect(() => {
    setRating(currentRating)
  }, [currentRating])

  useEffect(() => {
    if (isMonitoring) {
      dispatch(getMonitoring(lessonId))
    } else {
      dispatch(getLiveLesson(lessonId))
    }
  }, [dispatch, isMonitoring, lessonId])

  const onHating = useCallback(
    nextValue => {
      dispatch(rateMonitoring(lessonId, nextValue))
      setRating(nextValue)
    },
    [dispatch, lessonId]
  )

  const handleTemporaryRate = useCallback(nextValue => {
    setRating(nextValue)
  }, [])

  const onRatingOut = useCallback(() => {
    setRating(currentRating || 0)
  }, [currentRating])

  const openModal = useCallback(
    () => reportErrorModalRef.current && reportErrorModalRef.current.setVisibility(true),
    []
  )

  return (
    <div className={styles.container}>
      <div className={styles['top-container']}>
        <div className={styles['video-content']}>
          <div className={styles.wrapper}>
            <ReactPlayer
              url={embedURL}
              width="100%"
              height="100%"
              controls
              className={styles['react-player']}
            />
          </div>
          <div className={styles.buttons}>
            <StarRatingComponent
              name=""
              className={styles.rater}
              starCount={5}
              value={rating}
              onStarHover={handleTemporaryRate}
              onStarHoverOut={onRatingOut}
              emptyStarColor="#afafaf"
              onStarClick={onHating}
              editing
            />
            <Report className={styles.report} onClick={openModal} />
            <ReportErrorModal ref={reportErrorModalRef} />
          </div>
        </div>
        <div className={styles.side}>
          <h2 className={styles['chat-title']}>Chat ao vivo</h2>
          <button className={styles['chat-button']} type="button" onClick={handleChatVisibility}>
            {isChatVisible ? 'Ocultar' : 'Exibir'}
          </button>
          {isChatVisible && (
            <iframe title="Chat ao vivo" className={styles.chat} src={commentsURL} />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.tag}>
            <div
              className={classnames(styles['tag-color'], { [styles.monitoring]: isMonitoring })}
            />
            <p>{isMonitoring ? 'MONITORIA' : 'AO VIVO'}</p>
          </div>
          <Link to="/calendario" className={styles.link}>
            <img src={Arrow} alt="Icone voltar para Calendário" />
            <span className={styles['link-text']}>Calendário</span>
          </Link>
          <h1 className={styles.topic}>{title}</h1>
        </div>
      </div>
    </div>
  )
}

LiveLesson.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),

  lessonId: PropTypes.string,
}

LiveLesson.defaultProps = {
  location: {},
  lessonId: '',
}

export default LiveLesson
