import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import cookies from 'react-cookies'
import * as Sentry from '@sentry/react'

import Toast from '_components/toast'
import { SENTRY_DSN } from '_config/environment'

import configureStore from './store/configure-store'
import * as serviceWorker from './serviceWorker'
import Router from './router'
import './styles/global'

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN })
}

const key = cookies.load('key')
const data = cookies.load('data')

const initialState = {
  user: {
    key,
    data,
  },
}
const store = configureStore(initialState)

ReactDOM.render(
  <Provider store={store}>
    <Router />
    <Toast />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
