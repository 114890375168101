import { get } from './requests'

export const getMaterialsSubject = (key, params) =>
  get(['handout-subjects'], {
    key,
    transformPayload: true,
    params,
  })

export const getMaterials = (key, id) =>
  get(['handout-subjects', id, 'handouts'], {
    key,
    transformPayload: true,
  })
