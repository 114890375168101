import { navigate } from '@reach/router'

import { defineAction } from '_utils/redux'
import * as mockExamsService from '_services/mockExams'

export const GET_CURRENT_MOCK_EXAM = defineAction('GET_CURRENT_MOCK_EXAM')
export const GET_MOCK_EXAMS_RESULTS = defineAction('GET_MOCK_EXAMS_RESULTS')
export const GET_ENTRANCE_EXAMS = defineAction('GET_ENTRANCE_EXAMS')
export const GET_ENTRANCE_EXAM = defineAction('GET_ENTRANCE_EXAM')
export const GET_EXAM_QUESTIONS = defineAction('GET_EXAM_QUESTIONS')
export const STAR_EXAM_QUESTION = defineAction('STAR_EXAM_QUESTION')
export const ANSWER_EXAM_QUESTION = defineAction('ANSWER_EXAM_QUESTION')
export const GET_CURRENT_EXAM = defineAction('GET_CURRENT_EXAM')
export const FINISH_EXAM = defineAction('FINISH_EXAM')
export const START_EXAM = defineAction('START_EXAM')

export const getMockExams = () => (dispatch, getState) =>
  dispatch({
    type: GET_CURRENT_MOCK_EXAM.ACTION,
    payload: mockExamsService.getMockExams(getState().user.key),
  })

export const getMockExamsResults = (exam = '') => (dispatch, getState) =>
  dispatch({
    type: GET_MOCK_EXAMS_RESULTS.ACTION,
    payload: mockExamsService.getMockExamsResults(exam.toLocaleLowerCase(), getState().user.key),
  })

export const getEntranceExams = isEnem => (dispatch, getState) => {
  const exam = isEnem && 'enem'

  return dispatch({
    type: GET_ENTRANCE_EXAMS.ACTION,
    payload: mockExamsService.getEntranceExams(exam, getState().user.key),
  })
}

export const getEntranceExam = id => (dispatch, getState) =>
  dispatch({
    type: GET_ENTRANCE_EXAM.ACTION,
    payload: mockExamsService.getEntranceExam(id, getState().user.key),
  })

export const getCurrentExam = () => (dispatch, getState) =>
  dispatch({
    type: GET_CURRENT_EXAM.ACTION,
    payload: mockExamsService.getCurrentExam(getState().user.key),
  })

export const getExamQuestions = params => (dispatch, getState) =>
  dispatch({
    type: GET_EXAM_QUESTIONS.ACTION,
    payload: mockExamsService.getExamQuestions({ ...params, key: getState().user.key }),
  })

export const answerExamQuestion = params => (dispatch, getState) =>
  dispatch({
    type: ANSWER_EXAM_QUESTION.ACTION,
    payload: mockExamsService.answerExamQuestion({ ...params, key: getState().user.key }),
  })

export const startExamQuestion = params => (dispatch, getState) =>
  dispatch({
    type: STAR_EXAM_QUESTION.ACTION,
    payload: mockExamsService.startExamQuestion({ ...params, key: getState().user.key }),
  })

export const finishExam = params => (dispatch, getState) =>
  dispatch({
    type: FINISH_EXAM.ACTION,
    payload: mockExamsService.finishExam({ ...params, key: getState().user.key }),
  })

export const startExam = ({ examId, selectedUniversityId, selectedExam }) => async (
  dispatch,
  getState
) => {
  dispatch({ type: START_EXAM.PENDING })

  try {
    const response = await mockExamsService.startExam({ examId, key: getState().user.key })

    dispatch({ type: START_EXAM.FULFILLED, payload: response })
    navigate(`./prova/${selectedUniversityId}/edicao/${examId}/area/${selectedExam}`)
  } catch (error) {
    dispatch({ type: START_EXAM.REJECTED, payload: error })
  }
}
