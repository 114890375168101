import React, { useCallback, useReducer, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { usePrevious } from '_hooks/use-previous'
import Input from '_components/input'
import Button, { BUTTON_THEMES } from '_components/button'
import { changePassword } from '_modules/user/actions'
import { isChangingPasswordLoading, changePasswordError } from '_modules/user/selectors'
import { validatePassword } from '_utils/helpers'

import styles from './styles.css'
import { OLD_PASS, NEW_PASS, CONFIRM_PASS, INITIAL_STATE, reducer, UPDATE_FORM } from './reducer'

const ChangePassword = () => {
  const isChangingPassword = useSelector(isChangingPasswordLoading)
  const wasChangingPassword = usePrevious(isChangingPassword)
  const changePassError = useSelector(changePasswordError)

  const dispatchRequest = useDispatch()
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target

      dispatch({
        type: UPDATE_FORM,
        payload: {
          [name]: value,
        },
      })
    },
    [dispatch]
  )

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      dispatchRequest(changePassword(formData))
    },
    [dispatchRequest, formData]
  )

  useEffect(() => {
    if (wasChangingPassword && !isChangingPassword) {
      if (changePassError) {
        if (changePassError.old_password) {
          toast(<Alert theme={ALERT_THEMES.ERROR} message="Senha atual incorreta" />)
        } else if (changePassError.new_password2) {
          toast(
            <Alert theme={ALERT_THEMES.ERROR} message="Nova senha e confirmação devem ser iguais" />
          )
        } else {
          toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao salvar alterações !" />)
        }
      } else {
        toast(
          <Alert theme={ALERT_THEMES.SUCCESS} message="Suas alterações foram salvas com sucesso!" />
        )
        dispatch({
          type: UPDATE_FORM,
          payload: INITIAL_STATE,
        })
      }
    }
  }, [wasChangingPassword, isChangingPassword, changePassError])

  const disableButton = useMemo(
    () => !validatePassword(formData[NEW_PASS]) || !validatePassword(formData[CONFIRM_PASS]),
    [formData]
  )

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <Input
        placeholder="Senha atual"
        autoComplete="password"
        type="password"
        name={OLD_PASS}
        value={formData[OLD_PASS]}
        highlight={false}
        onChange={onInputChange}
      />
      <Input
        placeholder="Nova senha (6 ou mais caracteres)"
        autoComplete="password"
        type="password"
        name={NEW_PASS}
        value={formData[NEW_PASS]}
        highlight={false}
        onChange={onInputChange}
      />
      <Input
        placeholder="Confirmar nova senha"
        autoComplete="password"
        type="password"
        name={CONFIRM_PASS}
        value={formData[CONFIRM_PASS]}
        highlight={false}
        onChange={onInputChange}
      />
      <Button
        className={styles.button}
        theme={BUTTON_THEMES.PINK_NORMAL}
        disabled={disableButton}
        isLoading={isChangingPassword}
        type="submit"
      >
        Salvar Alterações
      </Button>
    </form>
  )
}

export default ChangePassword
