export const TITLE = 'title'
export const DATE = 'scheduledDate'
export const TIME = 'scheduledTime'
export const LINK = 'link'

export const INITIAL_STATE = {
  [TITLE]: '',
  [DATE]: '',
  [TIME]: '',
  [LINK]: '',
}

export const UPDATE_FORM = 'UPDATE_FORM'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
