export const EMAIL = 'email'
export const EMAIL_CONFIRMATION = 'emailConfirmation'
export const PASSWORD = 'password1'

export const INITIAL_STATE = {
  [EMAIL]: '',
  [EMAIL_CONFIRMATION]: '',
  [PASSWORD]: '',
}

export const UPDATE_FORM = 'UPDATE_FORM'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
