import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

const InputCheck = ({ onChange, id, value, ...otherProps }) => {
  return (
    <label htmlFor={id} className={styles.container}>
      <input
        onChange={onChange}
        id={id}
        type="checkbox"
        value={value}
        className={styles.checkbox}
        {...otherProps}
      />
      <span className={styles.checkmark} />
    </label>
  )
}

InputCheck.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.bool,
}

InputCheck.defaultProps = {
  id: 'checkbox-input',
  value: undefined,
}

export default React.memo(InputCheck)
