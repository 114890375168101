import { createReducer, LOGOUT } from '_utils/redux'

import {
  GET_SUBJECTS,
  GET_TOPICS,
  GET_SUBJECT_PERFORMANCE,
  GET_SUBJECT,
  GET_KEEP_WATCHING,
  SEARCH_LESSON,
  GET_RECOMMENDED_EXERCISES,
} from './actions'

const INITIAL_STATE = {
  data: [],
  currentSubject: {
    topics: [],
    keepWatching: [],
  },
  performance: {},
  searchResult: [],
  recommendedExercises: [],
}

const subjects = createReducer(INITIAL_STATE, {
  [GET_SUBJECTS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      data: payload,
    }
  },
  [GET_SUBJECT.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      currentSubject: {
        ...state.currentSubject,
        ...payload,
      },
    }
  },
  [GET_KEEP_WATCHING.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      currentSubject: {
        ...state.currentSubject,
        keepWatching: payload,
      },
    }
  },
  [GET_TOPICS.PENDING]: state => {
    return {
      ...state,
      currentSubject: {
        ...state.currentSubject,
        topics: [],
      },
    }
  },
  [GET_TOPICS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      currentSubject: {
        ...state.currentSubject,
        topics: payload.filter(({ lessons }) => lessons.length),
      },
    }
  },
  [SEARCH_LESSON.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      searchResult: payload,
    }
  },
  [GET_SUBJECT_PERFORMANCE.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      performance: payload,
    }
  },
  [GET_RECOMMENDED_EXERCISES.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      recommendedExercises: payload,
    }
  },

  [LOGOUT]: () => {
    return INITIAL_STATE
  },
})

export default subjects
