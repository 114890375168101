import React, { useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import { Link } from '@reach/router'
import Loader from 'react-loader-spinner'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'

import useWindowSize from '_hooks/use-windows-size'
import DashboardCard from '_components/cards/dashboard-card'
import CalendarHeader from '_assets/svgs/calendar-header.svg'
import ExamesHeader from '_assets/svgs/exames-header.svg'
import ExercisesHeader from '_assets/svgs/exercises-header.svg'
import TextHeader from '_assets/svgs/text-header.svg'
import PerformaceHeader from '_assets/svgs/performance-header.svg'
import Tag from '_components/calendar/tag'
import PerformanceCard from '_components/performance-card'
import { getEventsByDay } from '_modules/study-plan/actions'
import { getRecommendedExercises } from '_modules/subjects/actions'
import {
  recommendedExercisesSelector,
  areRecommendedExercisesLoadingSelector,
} from '_modules/subjects/selectors'
import {
  geTodayEvents,
  isGettingTodayEventsLoading,
  hasPlanSelector,
} from '_modules/study-plan/selectors'
import { getUserSelector } from '_modules/user/selectors'
import { getMockExamsResults } from '_modules/mock-exams/actions'
import {
  lastMockExamsResultSelector,
  mockExamsResultsLoadingSelector,
} from '_modules/mock-exams/selectors'
import { getEssays } from '_modules/essay/actions'
import { getEssaysGraphSelector, areEssaysLoadingSelector } from '_modules/essay/selectors'
import { ROUTES } from '_utils/constants'

import BuyPlanCard from './buy-plan'
import styles from './styles.css'

const GRAPH_MARGIN = { top: 10, left: -30, bottom: 10 }
const GRAPH_DOT_STYLES = { stroke: '#f83e2f', strokeWidth: 2 }

const Dashboard = () => {
  const dispatch = useDispatch()
  const todayEvents = useSelector(geTodayEvents)
  const userHasPlan = useSelector(hasPlanSelector)
  const user = useSelector(getUserSelector)
  const hasActivePlan = user?.hasActivePlan
  const onFreeTrial = user?.onFreeTrial
  const recommendedExercises = useSelector(recommendedExercisesSelector)
  const areRecommendedExercisesLoading = useSelector(areRecommendedExercisesLoadingSelector)
  const mockExamResult = useSelector(lastMockExamsResultSelector)
  const isMockExamResultLoading = useSelector(mockExamsResultsLoadingSelector)
  const essaysForGraph = useSelector(getEssaysGraphSelector)
  const isGettingEssays = useSelector(areEssaysLoadingSelector)
  const sizeScreen = useWindowSize().width
  useEffect(() => {
    dispatch(getEssays())
    dispatch(getMockExamsResults())
    dispatch(getRecommendedExercises())
    if (userHasPlan) {
      const today = new Date()
      dispatch(getEventsByDay(format(today, 'yyyy-MM-dd')))
    }
  }, [dispatch, userHasPlan])

  const isGettingTodayEvent = useSelector(isGettingTodayEventsLoading)

  const renderExams = useMemo(() => {
    if (!mockExamResult) {
      return (
        <div className={styles['no-content']}>
          {isMockExamResultLoading ? (
            <Loader type="Oval" color="#ff4429" height="32px" width="32px" />
          ) : (
            <p className={styles.text}>Primeiro complete um simulado para ver seus resultados.</p>
          )}
        </div>
      )
    }

    return (
      <div className={styles.exam}>
        <span className={styles['exam-year']}>
          {`${mockExamResult?.exam?.entranceExam?.name} ${mockExamResult?.exam?.name}`}
        </span>
        <p className={styles.area}>{/* Ciências da natureza e suas tecnologia */} </p>
        <div className={styles.infos}>
          <div className={styles.info}>
            <p>Nota:</p>
            <span className={styles.number}>{mockExamResult?.grade?.toFixed(2) || 0}</span>
          </div>
          <div className={styles.info}>
            <p>Acertos</p>
            <span className={styles.number}>{mockExamResult?.correctQuestions}</span>
          </div>
          <div className={styles.info}>
            <p>Erros</p>
            <span className={styles.number}>{mockExamResult?.wrongQuestions}</span>
          </div>
          <div className={styles.info}>
            <p>Parciais</p>
            <span className={styles.number}>{mockExamResult?.partialQuestions}</span>
          </div>
        </div>
        <p className={styles.time}>Tempo de prova:</p>
        <span className={styles.number}>{mockExamResult?.timeSpent}</span>
      </div>
    )
  }, [isMockExamResultLoading, mockExamResult])

  const renderCalendarContent = useMemo(() => {
    if (todayEvents.length) {
      const firstFiveEvents = todayEvents.slice(0, 4)
      return firstFiveEvents.map(data => <Tag key={data.id} data={data} />)
    }

    return (
      <div className={styles['no-content']}>
        {isGettingTodayEvent ? (
          <Loader type="Oval" color="#ff4429" height="32px" width="32px" />
        ) : (
          <p className={styles.text}>
            Você não possui eventos na sua agenda de hoje.{' '}
            <Link className={styles.link} to={`/${ROUTES.CALENDAR}`}>
              Clique aqui
            </Link>{' '}
            para criar seu plano de estudos.
          </p>
        )}
      </div>
    )
  }, [isGettingTodayEvent, todayEvents])

  const renderRecommendedExercises = useMemo(() => {
    if (recommendedExercises.length) {
      return recommendedExercises.slice(0, 5).map(data => <Tag key={data.id} data={data} />)
    }

    return (
      <div className={styles['no-content']}>
        {areRecommendedExercisesLoading ? (
          <Loader type="Oval" color="#ff4429" height="32px" width="32px" />
        ) : (
          <p className={styles.text}>Você não possui nenhum exercício pendente.</p>
        )}
      </div>
    )
  }, [areRecommendedExercisesLoading, recommendedExercises])

  const renderTooltip = useCallback(date => {
    if (date.active && date?.payload) {
      const current = date?.payload[0].payload
      return (
        <div className={styles.tooltip}>
          <p className={styles['tooltip-text']}>
            <b>Tipo:</b> {current.type || 'Não especificado'}
          </p>
          <p className={styles['tooltip-text']}>
            <b>Nota:</b> {current.simpleGrade || 0}
          </p>
        </div>
      )
    }
    return null
  }, [])

  const graphPadding = sizeScreen > 480 ? { left: 50, right: 50 } : { left: 10, right: 10 }

  const renderGraph = useMemo(() => {
    return isGettingEssays ? (
      <div className={styles['no-content']}>
        <Loader type="Oval" color="#ff4429" height="32px" width="32px" />
      </div>
    ) : (
      <ResponsiveContainer>
        <LineChart data={essaysForGraph} margin={GRAPH_MARGIN}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" padding={graphPadding} />
          <YAxis domain={[0, 100]} />
          <Tooltip content={renderTooltip} />
          <Line
            type="monotone"
            dataKey="nota"
            stroke="#f83e2f"
            activeDot={{ r: 7 }}
            dot={GRAPH_DOT_STYLES}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }, [essaysForGraph, graphPadding, isGettingEssays, renderTooltip])

  const displayBuyCard = !hasActivePlan || onFreeTrial

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Dashboard</h1>
      <div className={styles.cards}>
        {displayBuyCard && <BuyPlanCard isFreeTrail={onFreeTrial} />}
        <DashboardCard
          header={CalendarHeader}
          title="Agenda de hoje"
          content={renderCalendarContent}
          buttonText={todayEvents.length ? 'Ver Calendário Completo' : ''}
          path={ROUTES.CALENDAR}
        />
        <DashboardCard
          header={ExamesHeader}
          content={renderExams}
          title="Simulados"
          buttonText="ABRIR SIMULADOS"
          path="simulados/vestibulares"
        />
        <DashboardCard
          header={ExercisesHeader}
          content={renderRecommendedExercises}
          title="Exercícios para você"
        />
        <DashboardCard
          header={PerformaceHeader}
          title="Desempenho"
          content={<PerformanceCard displayOnDashboard isUserPerformance />}
        />
        <DashboardCard
          large
          header={TextHeader}
          content={renderGraph}
          title="Redações"
          buttonText="VER REDAÇÕES"
          path={ROUTES.ESSAY}
        />
      </div>
    </div>
  )
}

export default Dashboard
