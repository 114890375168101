import React from 'react'
import { Link } from '@reach/router'

import { ReactComponent as Logo } from '_assets/svgs/pro-logo-white.svg'

import { MEDIA_WHITE } from '../constants'

import styles from './styles.css'

const FooterSection = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.grid}>
        <Logo className={styles.logo} />
        <div className={styles['grid-column']}>
          <h3 className={styles['footer-title']}>Comece a estudar</h3>
          <a className={styles['footer-option']} href="#blog">
            Blog
          </a>
          <Link className={styles['footer-option']} to="/login">
            Login
          </Link>
          <a className={styles['footer-option']} href="#planos">
            Planos
          </a>
        </div>
        <div className={styles['grid-column']}>
          <h3 className={styles['footer-title']}>Sobre o Pró Online</h3>
          <a className={styles['footer-option']} href="#comentarios">
            Aprovados
          </a>
          <a className={styles['footer-option']} href="#plataforma">
            Como funciona
          </a>
          <Link className={styles['footer-option']} to="/cadastro">
            Tour gratuito pela plataforma
          </Link>
        </div>
        <div className={styles['grid-column']}>
          <h3 className={styles['footer-title']}>Rede Pró</h3>
          <a className={styles['footer-option']} href="https://prouniversidade.com.br/floripa/">
            Pró Floripa
          </a>
          <a className={styles['footer-option']} href="https://prouniversidade.com.br/proaovivo/">
            Pró Ao Vivo
          </a>
        </div>
        <div className={styles['grid-column']}>
          <h3 className={styles['footer-title']}>Siga o Pró</h3>
          <div className={styles['footer-contact']}>
            {MEDIA_WHITE.map(media => (
              <a
                key={media.link}
                target="_blank"
                rel="noopener noreferrer"
                href={media.link}
                className={styles.icon}
              >
                {media.icon}
              </a>
            ))}
          </div>

          <a href="mailto:contato@prouniversidade.com.br" className={styles['footer-option']}>
            contato@prouniversidade.com.br
          </a>
        </div>
      </div>
      <hr className={styles.divider} />
      <p className={styles.copyright}>Pró Universidade © 2020, Todos os direitos reservados.</p>
    </footer>
  )
}

export default FooterSection
