import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const ExercisesNav = ({ exercises, current, onChange }) => {
  return (
    <div className={styles.nav}>
      {exercises.map((question, index) => (
        <React.Fragment key={question.id}>
          <button
            className={classnames(styles.number, {
              [styles.selected]: index === current,
            })}
            onClick={onChange(index)}
          >
            {index + 1}
          </button>
          {index + 1 < exercises.length && <hr className={styles.line} />}
        </React.Fragment>
      ))}
    </div>
  )
}

ExercisesNav.propTypes = {
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      alternatives: PropTypes.arrayOf(
        PropTypes.shape({
          letter: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(ExercisesNav)
