import React from 'react'

import styles from './styles.css'

const BlogSection = () => {
  return (
    <section id="blog" className={styles.blog}>
      <div className={styles.box}>
        <h1 className={styles.title}>Um blog para acompanhar sua jornada de estudos</h1>
        <a
          className={styles.button}
          href="https://prouniversidade.com.br/aulasonline/blog/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Acesse o Blog do Pró
        </a>
      </div>
    </section>
  )
}

export default BlogSection
