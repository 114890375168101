import React, { useMemo, useCallback, useRef } from 'react'
import classnames from 'classnames'
import { Link, Redirect } from '@reach/router'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import Logo from '_assets/svgs/logo-pro.svg'
import Laptop from '_assets/svgs/laptop-pro.svg'
import Facebook from '_assets/svgs/facebook-button.svg'
import Gmail from '_assets/svgs/gmail-button.svg'
import LoginForm from '_components/login-form'
import SignUpForm from '_components/signup-form'
import ResetPasswordModal from '_components/reset-password-modal'
import Button, { BUTTON_THEMES } from '_components/button'
import { isLoggedSelector } from '_modules/user/selectors'
import { facebookLogin, googleLogin } from '_modules/user/actions'
import { ROUTES } from '_utils/constants'
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from '_config/environment'

import styles from './styles.css'

const Login = ({ location, uid, token, planId }) => {
  const pathname = useMemo(() => location.pathname.split('/')[1], [location.pathname])
  const subpath = useMemo(
    () => location.pathname.split('/')[2] && `/${location.pathname.split('/')[2]}`,
    [location.pathname]
  )
  const resetPasswordModalRef = useRef()
  const isResetPassword = useMemo(() => pathname === ROUTES.RESET_PASSWORD, [pathname])
  const dispacth = useDispatch()
  const isLoginSelected = useMemo(
    () => pathname === ROUTES.LOGIN || pathname === ROUTES.RESET_PASSWORD,
    [pathname]
  )

  const isLogged = useSelector(isLoggedSelector)
  const facebookResponse = useCallback(
    ({ accessToken }) => {
      if (accessToken) {
        dispacth(facebookLogin({ accessToken }))
      }
    },
    [dispacth]
  )

  const googleResponse = useCallback(
    ({ tokenObj: { access_token: accessToken } }) => {
      dispacth(googleLogin({ accessToken }))
    },
    [dispacth]
  )

  const renderContent = useCallback(() => {
    switch (pathname) {
      case ROUTES.LOGIN:
      case ROUTES.RESET_PASSWORD:
        return <LoginForm />
      case ROUTES.SIGNUP:
        return <SignUpForm />
      default:
        return null
    }
  }, [pathname])

  const renderFacebookButton = useCallback(
    renderProps => (
      <Button theme={BUTTON_THEMES.ICON} onClick={renderProps.onClick} className={styles.facebook}>
        <img src={Facebook} className={styles.icon} alt="Logotipo Facebook" />
      </Button>
    ),
    []
  )

  const renderGoogleButton = useCallback(
    ({ onClick }) => (
      <Button onClick={onClick} theme={BUTTON_THEMES.ICON}>
        <img src={Gmail} className={styles.icon} alt="Logotipo Gmail" />
      </Button>
    ),
    []
  )

  if (isLogged) {
    if (planId) {
      return <Redirect to={`/comprar-plano/${planId}`} noThrow />
    }
    return <Redirect to={`/${ROUTES.DASHBOARD}`} noThrow />
  }

  const singUpPath = `/${ROUTES.SIGNUP}${subpath ? `${subpath}` : ''}`
  const loginPath = `/${ROUTES.LOGIN}${subpath ? `${subpath}` : ''}`

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/">
          <img src={Logo} alt="Logotipo Pró Online" className={styles.logo} />
        </Link>
        <div className={styles.menu}>
          <Link
            to={loginPath}
            className={classnames(styles.links, { [styles['selected-login']]: isLoginSelected })}
          >
            Entrar
            <div className={styles.underline} />
          </Link>
          <Link
            to={singUpPath}
            className={classnames(styles.links, { [styles['selected-singin']]: !isLoginSelected })}
          >
            Cadastro
            <div className={styles.underline} />
          </Link>
        </div>
        <ResetPasswordModal
          ref={resetPasswordModalRef}
          defaultVisibility={isResetPassword}
          token={token}
          uid={uid}
        />
        <div className={styles.social}>
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            fields="name,email"
            callback={facebookResponse}
            render={renderFacebookButton}
          />
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            cookiePolicy="single_host_origin"
            onSuccess={googleResponse}
            render={renderGoogleButton}
          />
        </div>
        <div className={styles.divider}>
          <hr className={styles.line} />
          <p className={styles.text}> ou </p>
          <hr className={styles.line} />
        </div>
        {renderContent()}
      </div>
      <div className={styles.brand}>
        <h1 className={styles['brand-text']}>
          Acesse sua conta <br />
          ou cadastre-se
        </h1>
        <img src={Laptop} alt="Laptop com logotipo do Pró Online" className={styles.laptop} />
      </div>
    </div>
  )
}

Login.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  uid: PropTypes.string,
  token: PropTypes.string,
  planId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Login.defaultProps = {
  location: {},
  uid: '',
  token: '',
  planId: undefined,
}

export default React.memo(Login)
