import React, { useState, useCallback, useMemo, useEffect } from 'react'
import classnames from 'classnames'
import { Link, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import Breadcrumb from '_components/breadcrumb'
import { ROUTES } from '_utils/constants'
import Button, { BUTTON_THEMES } from '_components/button'
import Dropzone from '_components/dropzone'
import { createEssay } from '_modules/essay/actions'
import { creatingEssay, createEssayError, getSelectedTheme } from '_modules/essay/selectors'
import { usePrevious } from '_hooks/use-previous'

import styles from './styles.css'

const MENU_OPTIONS = {
  TYPE: 'DIGITAR',
  ATTACH: 'ANEXAR',
}

const ESSAY_TYPES = {
  enem: { label: 'ENEM', value: 'enem' },
  exam: { label: 'VESTIBULAR', value: 'exam' },
  concourse: { label: 'CONCURSO', value: 'concourse' },
}
const LIMIT_CREDITS_ERROR = 'User has reached maximum number of essays for this month'

const CreateEssay = ({ pdfId }) => {
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState(MENU_OPTIONS.TYPE)
  const [essayFile, setEssayFile] = useState(null)
  const [essayText, setEssayText] = useState('')
  const isCreatingEssay = useSelector(creatingEssay)
  const wasCreatingEssay = usePrevious(isCreatingEssay)
  const hasCreateEssayError = useSelector(createEssayError)
  const selectedTheme = useSelector(getSelectedTheme)
  const [essayType, setEssayType] = useState(ESSAY_TYPES[selectedTheme?.types?.[0].reviewType])

  const changeOption = useCallback(({ currentTarget: { name } }) => {
    setSelectedOption(name)
    setEssayFile(null)
    setEssayText('')
  }, [])

  const changeEssayType = useCallback(({ currentTarget: { name } }) => {
    setEssayType(Object.values(ESSAY_TYPES).find(item => item.label === name))
  }, [])

  const handleOnChangeText = useCallback(({ target: { value } }) => {
    setEssayText(value)
  }, [])

  const handleSubmit = useCallback(() => {
    const payload = {
      theme: +selectedTheme.types.find(({ reviewType }) => reviewType === essayType?.value)?.id,
      reviewType: essayType?.value,
    }
    if (essayText) {
      payload.essayText = essayText
    } else {
      payload.essayUrl = essayFile
    }
    dispatch(createEssay(payload))
  }, [dispatch, essayFile, essayText, essayType, selectedTheme.types])

  useEffect(() => {
    if (wasCreatingEssay && !isCreatingEssay) {
      if (hasCreateEssayError) {
        if (hasCreateEssayError[0] === LIMIT_CREDITS_ERROR) {
          toast(
            <Alert
              theme={ALERT_THEMES.ERROR}
              message="Você já atingiu o limite de redações enviadas."
            />
          )
        } else {
          toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao enviar redação" />)
        }
      } else {
        toast(<Alert theme={ALERT_THEMES.SUCCESS} message="Redação enviada com sucesso" />)
        navigate('/redacao')
      }
    }
  }, [hasCreateEssayError, isCreatingEssay, wasCreatingEssay])
  const enableButton = essayText || essayFile

  const renderContent = useMemo(() => {
    if (selectedOption === MENU_OPTIONS.TYPE) {
      return (
        <textarea
          className={styles['text-area']}
          onChange={handleOnChangeText}
          placeholder="Escreva sua redação"
        />
      )
    }
    return <Dropzone setFile={setEssayFile} />
  }, [handleOnChangeText, selectedOption])

  useEffect(() => {
    if (!selectedTheme.title) {
      navigate('/redacao')
    }
  }, [selectedTheme.title])

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Breadcrumb text="Temas de redação" to={ROUTES.ESSAY_THEMES} />
        <div className={styles['header-content']}>
          <h1 className={styles.title}>Enviar redação</h1>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['content-buttons']}>
          {Object.keys(MENU_OPTIONS).map(option => (
            <button
              className={classnames(styles.options, {
                [styles.selected]: selectedOption === MENU_OPTIONS[option],
              })}
              name={MENU_OPTIONS[option]}
              key={option}
              type="button"
              onClick={changeOption}
            >
              {MENU_OPTIONS[option]}
            </button>
          ))}
        </div>
        <>
          <h2 className={styles.subtitle}>Escreva a redação</h2>
          <div className={styles.essay}>
            <p className={styles.label}>{selectedTheme?.title}</p>
            <a
              href={`https://redacaonline.com.br/home/aluno/temas/${pdfId}.pdf`}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.theme}
            >
              Ver Tema
            </a>
          </div>
          <p className={classnames(styles.label, styles.bold)}>Tipo de correção:</p>
          <div className={styles['content-buttons']}>
            {selectedTheme?.types?.map(({ reviewType: option }) => (
              <button
                className={classnames(styles.options, {
                  [styles.selected]: essayType.label === ESSAY_TYPES[option].label,
                })}
                name={ESSAY_TYPES[option].label}
                key={option}
                type="button"
                onClick={changeEssayType}
              >
                {ESSAY_TYPES[option].label}
              </button>
            ))}
          </div>
          {renderContent}
          <div className={styles.buttons}>
            <Link to="/redacao/temas" className={styles.back}>
              Voltar
            </Link>
            <Button
              theme={BUTTON_THEMES.PINK_NORMAL}
              onClick={handleSubmit}
              disabled={!enableButton}
              isLoading={isCreatingEssay}
            >
              Enviar
            </Button>
          </div>
        </>
      </div>
    </section>
  )
}

CreateEssay.propTypes = {
  pdfId: PropTypes.string,
}

CreateEssay.defaultProps = {
  pdfId: '',
}

export default CreateEssay
