import React, { useCallback } from 'react'
import { navigate } from '@reach/router'

import { ReactComponent as Success } from '_assets/svgs/success.svg'
import Button, { BUTTON_THEMES } from '_components/button'

import styles from './styles.css'

const DownloadBoleto = () => {
  const handleClick = useCallback(() => {
    navigate('/dashboard')
  }, [])

  return (
    <div className={styles.boleto}>
      <h1 className={styles.title}>Pagamento</h1>
      <div className={styles.card}>
        <Success className={styles.image} />
        <h2 className={styles.subtitle}>Seu pagamento já está em análise!</h2>
        <p className={styles.text}>
          Seja bem-vindo (a) ao Pró Online! O seu pagamento já está em análise. Seu acesso à
          plataforma será liberado após a confirmação do pagamento.
        </p>
        <Button className={styles.end} theme={BUTTON_THEMES.PINK_NORMAL} onClick={handleClick}>
          Ir para plataforma
        </Button>
      </div>
    </div>
  )
}

export default DownloadBoleto
