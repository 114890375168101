import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import Loader from '_components/loader'
import { getMaterialsSubject, getMaterials } from '_modules/materials/actions'
import {
  getMaterialsSubjectSelector,
  getMaterialSelector,
  isGettingMaterialSelector,
} from '_modules/materials/selectors'
import Breadcrumb from '_components/breadcrumb'
import { ROUTES } from '_utils/constants'
import { capitalizeFirstLetter } from '_utils/helpers'
import SelectDropdown from '_components/select-dropdown'
import { usePrevious } from '_hooks/use-previous'

import styles from './styles.css'

const OPTIONS = {
  ENEM: 'enem',
  EXAM: 'vestibular',
}

const Materials = ({ location }) => {
  const currentPath = location.pathname.split('/')[2]
  const dispatch = useDispatch()
  const [material, setMaterial] = useState({})
  const onChange = useCallback(value => {
    setMaterial(value)
  }, [])

  const pdf = useSelector(getMaterialSelector)
  const options = useSelector(getMaterialsSubjectSelector)
  const isGettingMaterial = useSelector(isGettingMaterialSelector)
  const wasGettingMaterial = usePrevious(isGettingMaterial)
  const title = `Material didático - ${
    currentPath === OPTIONS.ENEM ? currentPath.toUpperCase() : capitalizeFirstLetter(OPTIONS.EXAM)
  }`

  useEffect(() => {
    if (wasGettingMaterial && !isGettingMaterial && !pdf) {
      toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao obter material didático" />)
    }
  }, [isGettingMaterial, pdf, wasGettingMaterial])

  useEffect(() => {
    const params = { exam: currentPath === OPTIONS.ENEM ? 'enem' : 'entrance_exam' }
    dispatch(getMaterialsSubject(params))
  }, [currentPath, dispatch])

  useEffect(() => {
    if (material?.value) {
      dispatch(getMaterials(material.value))
    }
  }, [dispatch, material, material.value])

  const frameRef = useRef(null)

  return (
    <div className={styles.container}>
      <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.content}>
        <SelectDropdown
          className={styles.input}
          placeholder="Selecione a disciplina"
          value={material.value ? material : null}
          options={options}
          onChange={onChange}
          isSearchable={false}
        />

        {isGettingMaterial && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        {pdf && !isGettingMaterial && (
          <iframe
            ref={frameRef}
            title="Material didático"
            type="application/pdf"
            src={pdf}
            className={styles.pdf}
            frameBorder="0"
          />
        )}
      </div>
    </div>
  )
}

Materials.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

Materials.defaultProps = {
  location: {},
}

export default Materials
