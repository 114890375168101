import { GET_MOCK_EXAMS_RESULTS, GET_ENTRANCE_EXAM } from './actions'

export const mockExamsResultsSelector = ({ mockExams }) => mockExams.results

export const lastMockExamsResultSelector = ({ mockExams }) =>
  mockExams?.results?.sort(
    (a, b) => new Date(b.finishedAt).getTime() - new Date(a.finishedAt).getTime()
  )[0] || null

export const mockExamsResultsLoadingSelector = ({ loading }) =>
  !!loading[GET_MOCK_EXAMS_RESULTS.ACTION]

export const entranceExamsSelector = ({ mockExams }) =>
  mockExams.entranceExams
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ id, label: name, value: id }))

export const entranceExamOptionsSelector = ({ mockExams }) =>
  mockExams.entranceExamOptions.sort((a, b) => b.name.localeCompare(a.name))

export const areEntranceExamOptionsLoadingSelector = ({ loading }) =>
  !!loading[GET_ENTRANCE_EXAM.ACTION]

export const examQuestionsSelector = ({ mockExams }) => mockExams.examQuestions

export const currentExamSelector = ({ mockExams }) => mockExams.currentExam
