import { defineAction } from '_utils/redux'
import * as requests from '_services/testimonials'

export const TESTIMONIALS = defineAction('TESTIMONIALS')

export const getTestimonials = () => dispatch =>
  dispatch({
    type: TESTIMONIALS.ACTION,
    payload: requests.getTestimonials(),
  })
