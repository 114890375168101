import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, navigate } from '@reach/router'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { toast } from 'react-toastify'

import { getUserSelector, isLoggedSelector } from '_modules/user/selectors'
import { getProfileData } from '_modules/user/actions'
import { ROUTES } from '_utils/constants'
import { getNotificationsCount } from '_modules/notifications/actions'
import { hasPlan } from '_modules/study-plan/actions'
import Bar from '_components/bar'
import Alert, { ALERT_THEMES } from '_components/alert'

import styles from './styles.css'

const App = ({ children, location }) => {
  const dispatch = useDispatch()
  const pathname = useMemo(() => location.pathname?.split('/'), [location.pathname])
  const hasActivePlan = useSelector(getUserSelector)?.hasActivePlan

  const [menuOpen, setMenuOpen] = useState(window.innerWidth >= 1024)

  const isLogged = useSelector(isLoggedSelector)

  useEffect(() => {
    if (isLogged) {
      dispatch(getNotificationsCount())
      dispatch(hasPlan())
      dispatch(getProfileData())
    }
  }, [dispatch, isLogged])

  const lockedPaths = pathname[1] === ROUTES.SUBJECTS && pathname[4]
  useEffect(() => {
    if (!hasActivePlan && lockedPaths && isLogged) {
      toast(
        <Alert
          theme={ALERT_THEMES.ERROR}
          message="Você precisa ter um plano ativo para assistir mais vídeos!"
        />
      )
      navigate(`/${ROUTES.DASHBOARD}`)
    }
  }, [hasActivePlan, isLogged, lockedPaths])

  if (!isLogged) {
    return <Redirect to={`/${ROUTES.LOGIN}`} noThrow />
  }

  return (
    <div className={classnames(styles.container, { [styles['menu-closed']]: !menuOpen })}>
      <Bar isOpen={menuOpen} setIsOpen={setMenuOpen} pathname={pathname[1]} />
      <div className={classnames(styles.content, { [styles['hidden-content']]: menuOpen })}>
        {children}
      </div>
    </div>
  )
}

App.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

App.defaultProps = {
  children: '',
  location: {},
}

export default React.memo(App)
