/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react'
import { ToastContainer, Slide } from 'react-toastify'

import '!style-loader!css-loader!react-toastify/dist/ReactToastify.min.css'

import styles from './styles.css'

const Toast = () => (
  <ToastContainer
    position="bottom-left"
    autoClose={4000}
    newestOnTop={false}
    closeOnClick={false}
    hideProgressBar
    pauseOnVisibilityChange
    draggable={false}
    pauseOnHover
    closeButton={false}
    toastClassName={styles['toast-content']}
    transition={Slide}
    className={styles['toast-container']}
  />
)

export default Toast
