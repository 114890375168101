import React, { useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'

import Loader from '_components/loader'
import Button, { BUTTON_THEMES } from '_components/button'
import Wrong from '_assets/svgs/red-close.svg'
import Right from '_assets/svgs/green-check.svg'
import { answerQuestion } from '_modules/lesson/actions'
import { isLoadingAnswerSelector } from '_modules/lesson/selectors'

import ExercisesNav from './exercises-nav'
import styles from './styles.css'

const Exercise = ({ exercises, subjectId, topicId, lessonId }) => {
  const dispatch = useDispatch()

  const [currentPosition, setCurrentPosition] = useState(0)
  const currentExercise = useMemo(() => exercises?.[currentPosition], [exercises, currentPosition])

  const isLoadingAnswer = useSelector(isLoadingAnswerSelector)

  const handleExercice = useCallback(
    answer => () => {
      dispatch(
        answerQuestion({ answer, subjectId, topicId, lessonId, questionId: currentExercise?.id })
      )
    },
    [currentExercise, dispatch, lessonId, subjectId, topicId]
  )

  const selectedLetter = currentExercise?.userAnswer

  const changeCurrentExercice = useCallback(
    exerciceIndex => () => {
      setCurrentPosition(exerciceIndex)
    },
    []
  )
  const handleNextExercice = useCallback(() => {
    setCurrentPosition(prevState => prevState + 1)
  }, [])

  const handlePrevExercice = useCallback(() => {
    setCurrentPosition(prevState => prevState - 1)
  }, [])

  const isFirstExercice = currentPosition === 0

  const isLastExercice = currentPosition === exercises.length - 1

  const renderExercisesAnswers = useMemo(() => {
    if (isLoadingAnswer) {
      return <Loader />
    }
    return (
      <div className={styles.answers}>
        {currentExercise?.choices?.map(answer => (
          <button
            key={answer.index}
            className={classnames(
              styles.option,
              {
                [styles.wrong]:
                  currentExercise.userAnswer === answer.index &&
                  selectedLetter !== !currentExercise.correctAnswer,
              },
              {
                [styles.right]:
                  currentExercise.correctAnswer && answer.index === currentExercise.userAnswer,
              }
            )}
            type="button"
            disabled={selectedLetter}
            onClick={handleExercice(answer.index, currentExercise.id)}
          >
            <p className={styles.letter}>{answer.index}</p>
            {parse(answer.choice)}
            {(answer.index === selectedLetter ||
              (selectedLetter && answer.index === currentExercise.correctAnswer)) && (
              <img
                className={styles.icon}
                alt={
                  answer.index === currentExercise.questionAnswer
                    ? 'Icone de Resposta Correta'
                    : 'Icone de Respota Errada'
                }
                src={answer.index === currentExercise.questionAnswer ? Right : Wrong}
              />
            )}
          </button>
        ))}
        {selectedLetter && (
          <div className={styles.feedback}>
            {currentExercise?.correctAnswer ? (
              <p className={styles.accert}>Parabéns, você acertou!</p>
            ) : (
              <p className={styles.error}>
                {`Não foi dessa vez. A alternativa correta é a letra "${currentExercise.questionAnswer}".`}
              </p>
            )}
          </div>
        )}
      </div>
    )
  }, [currentExercise, handleExercice, isLoadingAnswer, selectedLetter])

  return (
    <div className={styles.exercises}>
      <p className={styles.title}>Exercícios</p>
      <ExercisesNav
        exercises={exercises}
        current={currentPosition}
        onChange={changeCurrentExercice}
      />
      {currentExercise?.description && (
        <>
          <span className={styles.question}>{parse(exercises[currentPosition].description)}</span>
          {renderExercisesAnswers}
        </>
      )}
      <div className={styles['exercices-button']}>
        {!isFirstExercice && (
          <Button
            theme={BUTTON_THEMES.NO_BACKGROUND}
            className={styles.back}
            onClick={handlePrevExercice}
          >
            Anterior
          </Button>
        )}
        {!isLastExercice && (
          <Button
            theme={BUTTON_THEMES.PINK_NORMAL}
            onClick={handleNextExercice}
            className={styles.resize}
          >
            Próximo
          </Button>
        )}
      </div>
    </div>
  )
}

Exercise.propTypes = {
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      alternatives: PropTypes.arrayOf(
        PropTypes.shape({
          letter: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  subjectId: PropTypes.string.isRequired,
  topicId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
}

export default Exercise
