import { ANSWER_QUESTION } from './actions'

export const getLiveLessonData = isMonitoring => ({ lesson }) =>
  isMonitoring ? lesson.monitoring : lesson.liveLesson

export const getSubjectLesson = ({ lesson }) => lesson.subjectLesson

export const relatedLessonsSelector = ({ lesson }) => lesson.relatedLessons

export const isLoadingAnswerSelector = ({ loading }) => !!loading[ANSWER_QUESTION.ACTION]
