import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Breadcrumb from '_components/breadcrumb'
import NotificationCard from '_components/cards/notification-card'
import { ROUTES } from '_utils/constants'
import { getNotifications, readNotifications } from '_modules/notifications/actions'
import { getNotificationsSelector } from '_modules/notifications/selectors'

import styles from './styles.css'

const Notifications = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getNotifications())
  }, [dispatch])

  useEffect(() => {
    dispatch(readNotifications())
  }, [dispatch])
  const notifications = useSelector(getNotificationsSelector)

  const renderNoNotifications = useCallback(
    () => (
      <div className={styles['no-notifications']}>
        <h2 className={styles['default-text']}>
          <strong>O</strong>psss! <br />
          Infelizmente você ainda
          <br />
          não tem notificações
          <span role="img" className={styles.emoji} aria-label="Emoji">
            &#128517;
          </span>
        </h2>
      </div>
    ),
    []
  )
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
        <div className={styles['header-content']}>
          <h1 className={styles.title}>Notificações</h1>
        </div>
        <div className={styles.notification}>
          {notifications.length
            ? notifications.map(notification => (
                <NotificationCard
                  key={notification.id}
                  text={notification.body}
                  newNotification={!notification.read}
                />
              ))
            : renderNoNotifications()}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Notifications)
