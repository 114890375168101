import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cookies from 'react-cookies'

import Loader from '_components/loader'
import { getUser } from '_services/user'

import styles from './styles.css'

const TokenAccess = ({ token }) => {
  const access = useCallback(async () => {
    try {
      await getUser(token)
      cookies.save('key', token, { path: '/', sameSite: 'Strict' })
      window.location.replace('/dashboard')
    } catch (error) {
      window.location.replace('/login')
    }
  }, [token])

  useEffect(() => {
    access()
  }, [access])

  return (
    <div className={styles.container}>
      <Loader />
    </div>
  )
}

TokenAccess.propTypes = {
  token: PropTypes.string,
}

TokenAccess.defaultProps = {
  token: '',
}

export default TokenAccess
