import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const customStyles = {
  control: base => ({
    ...base,
    padding: 'none',
    fontSize: '1.6rem',

    background: 'transparent',
    border: 'none',
    borderRadius: 'none',
    borderBottom: 'solid 1px #afafaf',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  menu: base => ({
    ...base,
    textAlign: 'left',
    padding: 0,
    margin: 0,
    alignItems: 'flex-end',
  }),
  option: base => ({
    ...base,
    color: '#5b5b5b',
    fontSize: '1.6rem',
    cursor: 'pointer',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#e7e8e9',
    },
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: () => ({
    marginTop: '20px',
    '& > div': {
      padding: 0,
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    color: '#5b5b5b',
    '&:hover': {
      color: '#5b5b5b',
    },
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    margin: 0,
    height: '40px',
    overflow: 'none',
  }),
  placeholder: base => ({
    ...base,
    lineHeight: '2rem',
    marginLeft: null,
    marginRight: null,
    color: '#5b5b5b',
    top: '75%',
  }),
  input: base => ({ ...base, marginTop: '15px', padding: 0 }),
  singleValue: base => ({
    ...base,
    top: '75%',
  }),
}

const SelectDropdown = ({
  options,
  placeholder,
  onChange,
  defaultValue,
  className,
  ...otherProrps
}) => {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      isSearchable
      autosize
      onChange={onChange}
      className={className}
      defaultValue={defaultValue}
      {...otherProrps}
    />
  )
}

SelectDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  // TODO: Specify the shape
  defaultValue: PropTypes.shape({}),
  className: PropTypes.string,
}

SelectDropdown.defaultProps = {
  placeholder: '',
  onChange: () => {},
  defaultValue: null,
  className: '',
}
export default React.memo(SelectDropdown)
