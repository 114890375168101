import { createFormData } from '_utils/helpers'

import { get, post, del } from './requests'

export const getEssayThemes = key =>
  get(['themes'], {
    key,
    transformPayload: true,
  })

export const deleteEssay = (id, key) =>
  del(['essays', id], {
    key,
    transformPayload: true,
  })

export const getEssays = key =>
  get(['essays'], {
    key,
    transformPayload: true,
  })

export const getEssay = (id, key) =>
  get(['essays', id], {
    key,
    transformPayload: true,
  })

export const createEssay = (key, payload) => post(['essays'], { key }, createFormData(payload))
