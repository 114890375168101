import { defineAction } from '_utils/redux'
import * as requests from '_services/plans'

export const GET_PLAN_DETAILS = defineAction('GET_PLAN_DETAILS')
export const APPLY_COUPON = defineAction('APPLY_COUPON')
export const BUY_PLAN = defineAction('BUY_PLAN')
export const PLANS_BOUGHT = defineAction('PLANS_BOUGHT')

export const getPlanDetails = planId => (dispatch, getState) =>
  dispatch({
    type: GET_PLAN_DETAILS.ACTION,
    payload: requests.getPlanDetails(getState().user.key, planId),
  })

export const applyCoupon = payload => (dispatch, getState) =>
  dispatch({
    type: APPLY_COUPON.ACTION,
    payload: requests.applyCoupon(getState().user.key, payload),
  })

export const buyPlan = (plaId, payload) => (dispatch, getState) =>
  dispatch({
    type: BUY_PLAN.ACTION,
    payload: requests.buyPlan(getState().user.key, plaId, payload),
  })

export const plansBought = payload => (dispatch, getState) =>
  dispatch({
    type: PLANS_BOUGHT.ACTION,
    payload: requests.plansBought(getState().user.key, payload),
  })
