import { defineAction } from '_utils/redux'
import * as profileRequests from '_services/profile'

export const GET_ADDRESS = defineAction('GET_ADDRESS')
export const UPDATE_PROFILE = defineAction('UPDATE_PROFILE')
export const CHANGE_PICTURE = defineAction('CHANGE_PICTURE')

export const getAddress = cep => dispatch =>
  dispatch({
    type: GET_ADDRESS.ACTION,
    payload: profileRequests.getAddress(cep),
  })

export const updateProfile = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PROFILE.ACTION,
    payload: profileRequests.updateProfile(getState().user.key, id, payload),
  })
