import { get } from './requests'

export const getSubjects = key => get(['subjects'], { key, transformPayload: true })

export const getSubject = (subjectId, key) =>
  get(['subjects', subjectId], { key, transformPayload: true })

export const getKeepWatching = (subjectId, key) =>
  get(['subjects', subjectId, 'keep-watching'], { key, transformPayload: true })

export const getTopic = (subjectId, key) =>
  get(['subjects', subjectId, 'topics'], { key, transformPayload: true })

export const getSubjectPerformance = (subjectId, key) =>
  get(['performance'], {
    key,
    transformPayload: true,
    params: { subject_id: subjectId },
  })

export const searchLessoon = (searchTerm, key) =>
  get(['search'], {
    key,
    transformPayload: true,
    params: { name: searchTerm },
  })

export const getRecommendedExercises = key =>
  get(['subjects', 'recommended-exercises/'], {
    key,
    transformPayload: true,
  })
