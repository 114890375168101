import React from 'react'
import Lottie from 'react-lottie'
import ReactPlayer from 'react-player'

import useWindowSize from '_hooks/use-windows-size'
import { lottiesDefaultOptions } from '_utils/helpers'
import MediasAnimations from '_assets/lotties/medias.json'

import { MEDIAS_PINK } from '../constants'

import styles from './styles.css'

const generatingMediaAnimationOptions = {
  ...lottiesDefaultOptions,
  loop: true,
  animationData: MediasAnimations,
}
const MediaSection = () => {
  const currentWdith = useWindowSize().width
  const isMobile = currentWdith <= 480

  const animationWidth = isMobile ? 200 : 322
  return (
    <>
      <section id="media" className={styles.media}>
        <div className={styles['media-content']}>
          <h1 className={styles['media-title']}>Encontre o Pró Online nas redes sociais</h1>
          <p className={styles['media-text']}>
            Acompanhando o Pró Online nas redes sociais, você conta com videoaulas gratuitas, muitas
            dicas e uma equipe preparada para tirar as suas dúvidas
          </p>
          <Lottie options={generatingMediaAnimationOptions} height={animationWidth} />
        </div>
        <div className={styles.video}>
          <ReactPlayer
            url="www.youtube.com/watch?v=mbCC8S877IE"
            width="100%"
            controls
            className={styles['react-player']}
          />
          <a className={styles['video-button']} href="/cadastro">
            Quero ser Pró
          </a>
        </div>
      </section>
      <div className={styles['media-buttons']}>
        {MEDIAS_PINK.map(media => (
          <a key={media.link} target="_blank" rel="noopener noreferrer" href={media.link}>
            {media.icon}
          </a>
        ))}
      </div>
    </>
  )
}
export default MediaSection
