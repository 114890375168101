import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/common/modal'
import Button, { BUTTON_THEMES } from '_components/button'

import styles from './styles.css'

const EssayModal = forwardRef(({ comment }, ref) => {
  const closeModal = useCallback(() => ref?.current.setVisibility(false), [ref])

  return (
    <Modal ref={ref} titleText="Correção" className={styles.modal} hideCloseButton>
      <p className={styles.comment}>
        Comentário: <b>{comment}</b>
      </p>
      <Button theme={BUTTON_THEMES.PINK_NORMAL} className={styles.button} onClick={closeModal}>
        Fechar
      </Button>
    </Modal>
  )
})

EssayModal.propTypes = {
  comment: PropTypes.string,
}

EssayModal.defaultProps = {
  comment: '',
}

export default EssayModal
