import { createReducer } from '_utils/redux'

import { TESTIMONIALS } from './actions'

const INITIAL_STATE = {
  list: [],
}

const testimonials = createReducer(INITIAL_STATE, {
  [TESTIMONIALS.FULFILLED]: (state, { payload }) => {
    return {
      list: payload,
    }
  },
})

export default testimonials
