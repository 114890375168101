import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import classnames from 'classnames'

import { getTheme, removeBrFromString } from '_utils/helpers'

import styles from './styles.css'

const LessonMiniature = ({
  title,
  thumbnailUrl,
  lessonId,
  topic: {
    subject: {
      name: subjectName,
      id: subjectId,
      area: { name: areaName },
    },
    name: topicName,
    id: topicId,
  },
}) => {
  const theme = useMemo(() => `theme-${getTheme(areaName)}`, [areaName])

  return (
    <Link to={`${subjectId}/assuntos/${topicId}/aula/${lessonId}`} className={styles.link}>
      <div className={styles.card}>
        <img className={styles.thumbnail} src={thumbnailUrl} alt="Miniatura do vídeo" />
        <p
          className={classnames(styles.topic, styles[theme])}
        >{`Disciplinas > ${subjectName} > ${removeBrFromString(topicName)}`}</p>
        <p className={styles.title}>{title}</p>
      </div>
    </Link>
  )
}

LessonMiniature.propTypes = {
  lessonId: PropTypes.number.isRequired,
  title: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  topic: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    subject: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      area: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
}

LessonMiniature.defaultProps = {
  title: '',
  thumbnailUrl: '',
  topic: {},
}

export default LessonMiniature
