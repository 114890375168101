import { createReducer } from '_utils/redux'

import { GET_ADDRESS } from './actions'

const INITIAL_STATE = {
  address: {},
}

const profile = createReducer(INITIAL_STATE, {
  [GET_ADDRESS.FULFILLED]: (state, { payload }) => {
    const { data } = payload
    return {
      ...state,
      address: {
        stateInitials: data.uf,
        city: data.localidade,
        neighborhood: data.bairro,
        address: data.logradouro,
      },
    }
  },
})

export default profile
