export const FIRST_NAME = 'firstName'
export const CPF = 'cpf'
export const CEP = 'cep'
export const CARD_NUMBER = 'cardNumber'
export const CARD_NAME = 'cardName'
export const CARD_EXPIRATION = 'cardExpiration'
export const SECURITY_CODE = 'securityCode'
export const PARCELS = 'possibleParcels'

export const INITIAL_STATE = {
  [FIRST_NAME]: '',
  [CPF]: '',
  [CEP]: '',
  [CARD_NUMBER]: '',
  [CARD_NAME]: '',
  [CARD_EXPIRATION]: '',
  [SECURITY_CODE]: '',
  [PARCELS]: '',
}

export const UPDATE_FORM = 'UPDATE_FORM'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
