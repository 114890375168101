import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as Info } from '_assets/svgs/information.svg'

import styles from './styles.css'

const BoughtResume = ({ plan, selectedParcel, discount }) => {
  const { id, priceInCents, name, description, perks, finalValue } = plan

  const calculateFinalPrice = finalValue
    ? (finalValue / 100).toFixed(2)
    : (priceInCents / 100).toFixed(2)

  return (
    <div className={styles['plan-content']}>
      <h2 className={styles.subtitle}>Resumo da compra</h2>
      <div className={classnames(styles.plan, { [styles.loading]: !id })}>
        {!id ? (
          <Loader type="Oval" color="white" height="32px" width="32px" />
        ) : (
          <>
            <h3 className={styles['plan-title']}>{name}</h3>
            <p className={styles['plan-text']}>{description}</p>
            <hr className={styles.divider} />
            <div className={styles.topics}>
              {perks?.map(perk => (
                <p key={perk.id} className={styles['topic-text']}>{`» ${perk.text}`}</p>
              ))}
            </div>

            {!!discount && (
              <div className={styles['value-content']}>
                <p className={styles['value-text']}>Desconto</p>
                <p className={styles['topic-text']}>{`${(discount * 100).toFixed(2)} %`}</p>
              </div>
            )}
            {!!selectedParcel?.label && (
              <div className={styles['value-content']}>
                <p className={styles['value-text']}>Parcelas</p>
                <p className={styles['topic-text']}>{selectedParcel.label}</p>
                <Info data-tip data-for="info-tooltip" className={styles.info} />
                <ReactTooltip
                  id="info-tooltip"
                  type="light"
                  effect="solid"
                  aria-haspopup="true"
                  place="bottom"
                >
                  <p>Os valores das parcelas serão ajustados no momento</p>
                  <p>da cobrança podendo variar em alguns centavos.</p>
                </ReactTooltip>
              </div>
            )}

            <div className={styles.resume}>
              <p className={styles.text}>Total</p>
              <p className={styles.text}>{`R$ ${calculateFinalPrice}`}</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

BoughtResume.propTypes = {
  selectedParcel: PropTypes.shape({
    label: PropTypes.string,
  }),
  plan: PropTypes.shape({
    id: PropTypes.number,
    possibleParcels: PropTypes.number,
    priceInCents: PropTypes.number,
    finalValue: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    perks: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
  }).isRequired,
  discount: PropTypes.number.isRequired,
}

BoughtResume.defaultProps = {
  selectedParcel: null,
}

export default BoughtResume
