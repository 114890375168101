/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react'
import PropTypes from 'prop-types'
import InputRange from 'react-input-range'

import '!style-loader!css-loader!react-input-range/lib/css/index.css'
import styles from './styles.css'

const DEFAULT_CLASS_NAMES = {
  activeTrack: 'input-range__track input-range__track--active',
  disabledInputRange: 'input-range input-range--disabled',
  inputRange: 'input-range',
  labelContainer: 'input-range__label-container',
  maxLabel: 'input-range__label input-range__label--max',
  minLabel: 'input-range__label input-range__label--min',
  slider: 'input-range__slider',
  sliderContainer: 'input-range__slider-container',
  track: 'input-range__track input-range__track--background',
  valueLabel: 'input-range__label input-range__label--value',
}

const InputRangeStyled = ({ onFormat, onChange, value, minValue, maxValue, ...otherProps }) => {
  return (
    <InputRange
      classNames={{
        ...DEFAULT_CLASS_NAMES,
        inputRange: styles.range,
        labelContainer: styles['range-label'],
        valueLabel: styles.label,
        slider: styles.slider,
        sliderContainer: styles['slider-container'],
        activeTrack: styles.active,
      }}
      maxValue={maxValue}
      minValue={minValue}
      value={value}
      formatLabel={onFormat}
      onChange={onChange}
      {...otherProps}
    />
  )
}

InputRangeStyled.propTypes = {
  value: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onFormat: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default InputRangeStyled
