import React, { useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { selectTheme } from '_modules/essay/actions'
import { ReactComponent as ArrowDown } from '_assets/svgs/arrow-down.svg'
import { getUserSelector } from '_modules/user/selectors'
import { ROUTES } from '_utils/constants'

import styles from './styles.css'

const ThemeOption = ({ theme }) => {
  const [isOpen, setOpen] = useState(false)
  const { hasActivePlan, onFreeTrial } = useSelector(getUserSelector)
  const dispatch = useDispatch()
  const onSelectTheme = useCallback(
    ({ currentTarget: { name } }) => {
      if (!hasActivePlan || onFreeTrial) {
        navigate(`/${ROUTES.DASHBOARD}`)
        toast(
          <Alert
            theme={ALERT_THEMES.ERROR}
            message="Você precisa ter um plano ativo para realizar enviar redações!"
          />
        )
      } else {
        dispatch(selectTheme(theme))
        navigate(`temas/${name}`)
      }
    },
    [dispatch, hasActivePlan, onFreeTrial, theme]
  )

  const renderButtons = useMemo(() => {
    if (isOpen) {
      return (
        <div className={styles.buttons}>
          <button name={theme.pdfId} className={styles.button} onClick={onSelectTheme}>
            Enviar redação
          </button>
          <a
            href={`https://redacaonline.com.br/home/aluno/temas/${theme.pdfId}.pdf`}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.button}
          >
            Ver tema
          </a>
        </div>
      )
    }
    return null
  }, [isOpen, theme.pdfId, onSelectTheme])

  const handleClick = useCallback(() => setOpen(prev => !prev), [])
  return (
    <div className={styles.card}>
      <button
        className={classnames(styles['top-section'], {
          [styles['is-open']]: isOpen,
        })}
        type="button"
        onClick={handleClick}
      >
        <p className={styles.title}>{theme.title}</p>

        <ArrowDown className={classnames(styles.arrow, { [styles['is-open']]: isOpen })} />
      </button>
      {renderButtons}
    </div>
  )
}

ThemeOption.propTypes = {
  theme: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    pdfId: PropTypes.string,
  }).isRequired,
}

export default React.memo(ThemeOption)
