import { getActionName } from '_utils/redux'

export const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.error) {
    const error =
      action.payload && typeof action.payload === 'string'
        ? { error: action.payload }
        : action.payload || { error: true }
    return {
      ...state,
      [getActionName(action.type)]: error,
    }
  }

  const newState = { ...state }
  delete newState[getActionName(action.type)]
  return newState
}
