import React from 'react'
import { Link } from '@reach/router'

import Menu from '_components/header-menu'

import styles from './styles.css'

const HeaderContent = () => {
  return (
    <header className={styles.header}>
      <Menu />
      <div className={styles['header-title']}>
        <h1 className={styles.title}>Um novo Pró para você</h1>
        <h2 className={styles.subtitle}>
          Neste calendário atípico, estude com segurança para conquistar sua aprovação
        </h2>
        <Link className={styles.button} to="/cadastro">
          Quero ser Pró
        </Link>
      </div>
    </header>
  )
}

export default HeaderContent
