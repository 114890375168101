import { get, post } from './requests'

export const facebookLogin = payload =>
  post(['auth', 'facebook'], { transformPayload: true }, payload)

export const googleLogin = payload => post(['auth', 'google'], { transformPayload: true }, payload)

export const login = payload => post(['login'], { transformPayload: true }, payload)

export const logout = key => post(['logout'], { key })

export const register = payload => post(['register'], { transformPayload: true }, payload)

export const recoverPassword = payload => post(['password', 'reset'], {}, payload)

export const resetPassword = payload =>
  post(['password', 'reset', 'confirm'], { transformPayload: true }, payload)

export const getUser = key => get(['user'], { key })

export const getProfileData = key => get(['user'], { key, transformPayload: true })

export const changePassword = (key, payload) =>
  post(['change-password'], { key, transformPayload: true }, payload)

export const getUserPerformance = key => get(['performance'], { key, transformPayload: true })
