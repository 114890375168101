import React, { useCallback, useReducer, useRef, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import format from 'date-fns/format'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { usePrevious } from '_hooks/use-previous'
import Button, { BUTTON_THEMES } from '_components/button'
import Input from '_components/input'
import { validateCpf, validateEmail } from '_utils/helpers'
import { getAddress, updateProfile } from '_modules/profile/actions'
import {
  adrressLoadingSelector,
  getAddressSelector,
  isUpdatingProfile,
  updatingProfileError,
} from '_modules/profile/selectors'
import { getProfileData } from '_modules/user/actions'
import { getProfileDataSelector, getUserSelector } from '_modules/user/selectors'
import DefaultProfile from '_assets/images/default-profile.png'

import styles from './styles.css'
import {
  INITIAL_STATE,
  reducer,
  UPDATE_FORM,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  BIRTH_DATE,
  CPF,
  PHONE_NUMBER,
  CEP,
  CITY,
  STATE,
  ADDRESS,
  ADDRESS_COMPLEMENT,
  NEIGHBORHOOD,
  ADDRESS_NUMBER,
} from './reducer'

const convertDateRequest = date => {
  const currentDate = parse(date, 'dd/MM/yyyy', new Date())
  return format(currentDate, 'yyyy-MM-dd')
}

const EditProfile = () => {
  const dispatchRequest = useDispatch()
  const address = useSelector(getAddressSelector)
  const getAdrressLoading = useSelector(adrressLoadingSelector)
  const userId = useSelector(getUserSelector).id
  const isProfileUpdating = useSelector(isUpdatingProfile)
  const wasUpdatingProfile = usePrevious(isProfileUpdating)
  const updateProfileError = useSelector(updatingProfileError)
  const [userImage, setUserImage] = useState(null)
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)
  const inputFile = useRef(null)

  useEffect(() => {
    dispatchRequest(getProfileData())
  }, [dispatchRequest])

  const user = useSelector(getProfileDataSelector)

  const onButtonClick = useCallback(() => {
    inputFile.current.click()
  }, [])

  const handleImage = useCallback(event => {
    const file = event.target.files[0]
    setUserImage({ file, url: URL.createObjectURL(file) })
  }, [])

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target
      dispatch({
        type: UPDATE_FORM,
        payload: {
          [name]: value,
        },
      })
    },
    [dispatch]
  )

  const cepFormated = useMemo(() => formData[CEP].replace(/[^0-9]/g, ''), [formData])

  const wrongCep = useMemo(() => {
    if (cepFormated.length >= 8 && !getAdrressLoading) {
      if (address.address || address.stateInitials || address.city) {
        return ''
      }
      return 'Digite um CEP válido'
    }
    return ''
  }, [cepFormated.length, getAdrressLoading, address.address, address.stateInitials, address.city])

  const wrongEmail = useMemo(() => {
    if (!validateEmail(formData[EMAIL])) {
      return 'Digite um email válido'
    }
    return false
  }, [formData])

  const wrongCpf = useMemo(
    () =>
      formData[CPF] && !validateCpf(formData[CPF]) && formData[CPF].indexOf(' ') === -1
        ? 'CPF inválido'
        : '',
    [formData]
  )

  const wrongDate = useMemo(
    () =>
      !isValid(parse(formData[BIRTH_DATE], 'dd/MM/yyyyy', new Date())) &&
      formData[BIRTH_DATE].indexOf(' ') === -1 &&
      formData[BIRTH_DATE]
        ? 'Date de nascimento inválida'
        : '',
    [formData]
  )

  const disableButton = useMemo(
    () =>
      !formData[EMAIL] ||
      wrongEmail ||
      !validateCpf(formData[CPF]) ||
      !isValid(parse(formData[BIRTH_DATE], 'dd/MM/yyyyy', new Date())) ||
      !formData[ADDRESS_NUMBER] ||
      !formData[ADDRESS] ||
      !formData[NEIGHBORHOOD] ||
      !formData[CITY] ||
      !formData[STATE],
    [formData, wrongEmail]
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      const phone = formData[PHONE_NUMBER].split(' ')
      const payload = {
        ...formData,
        birthDate: convertDateRequest(formData[BIRTH_DATE]),
        phoneAreaCode: phone[0].replace(/[{()}]/g, ''),
        phoneNumber: phone[1],
        profilePicture: userImage?.file || userImage,
      }
      if (typeof payload.profilePicture === 'string') {
        delete payload.profilePicture
      }
      dispatchRequest(updateProfile(userId, payload))
    },
    [formData, userImage, dispatchRequest, userId]
  )

  useEffect(() => {
    if (cepFormated.length >= 8) {
      dispatchRequest(getAddress(cepFormated))
    }
  }, [cepFormated, dispatchRequest])

  useEffect(() => {
    if (Object.values(address).length) {
      Object.keys(address).forEach(key =>
        dispatch({
          type: UPDATE_FORM,
          payload: {
            [key]: address[key],
          },
        })
      )
    }
  }, [address])

  useEffect(() => {
    if (user.id) {
      Object.keys(user).forEach(key => {
        if (key === 'phoneNumber') {
          dispatch({
            type: UPDATE_FORM,
            payload: {
              [key]: `(${user.phoneAreaCode}) ${user[key]}`,
            },
          })
        } else if (key === 'profilePicture' && !userImage) {
          setUserImage(user.profilePicture)
        } else if (user[key])
          dispatch({
            type: UPDATE_FORM,
            payload: {
              [key]: user[key],
            },
          })
      })
    }
  }, [user, userImage])

  useEffect(() => {
    if (wasUpdatingProfile && !isProfileUpdating) {
      if (updateProfileError) {
        toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao salvar alterações !" />)
      } else {
        toast(
          <Alert theme={ALERT_THEMES.SUCCESS} message="Suas alterações foram salvas com sucesso!" />
        )
      }
    }
  }, [wasUpdatingProfile, isProfileUpdating, updateProfileError])

  return (
    <>
      <div className={styles.preview}>
        {userImage ? (
          <div
            className={styles['picture-wrapp']}
            style={{
              backgroundImage: `url(
              ${userImage?.url || userImage}
            )`,
            }}
          />
        ) : (
          <div
            className={styles['picture-wrapp']}
            style={{
              backgroundImage: `url(
              ${DefaultProfile}
            )`,
            }}
          />
        )}

        <button className={styles['picture-button']} onClick={onButtonClick}>
          Inserir foto de perfil
        </button>
        <input
          className={styles.upload}
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          onChange={handleImage}
        />
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles['three-columns']}>
          <Input
            placeholder="Nome"
            autoComplete={FIRST_NAME}
            onChange={onInputChange}
            name={FIRST_NAME}
            highlight={false}
            value={formData[FIRST_NAME]}
          />
          <Input
            placeholder="Sobrenome"
            autoComplete={LAST_NAME}
            onChange={onInputChange}
            name={LAST_NAME}
            highlight={false}
            value={formData[LAST_NAME]}
          />
          <Input
            placeholder="Data de nascimento"
            autoComplete={BIRTH_DATE}
            onChange={onInputChange}
            name={BIRTH_DATE}
            highlight={false}
            mask="99/99/9999"
            value={formData[BIRTH_DATE] || ''}
            error={!!wrongDate}
            errorText={wrongDate}
          />
        </div>
        <div className={styles['three-columns']}>
          <Input
            placeholder="CPF"
            autoComplete={CPF}
            onChange={onInputChange}
            name={CPF}
            error={!!wrongCpf}
            errorText={wrongCpf}
            highlight={false}
            mask="999.999.999-99"
            value={formData[CPF] || ''}
          />
          <Input
            placeholder="Celular"
            autoComplete={PHONE_NUMBER}
            onChange={onInputChange}
            name={PHONE_NUMBER}
            value={formData[PHONE_NUMBER] || ''}
            highlight={false}
            mask="(99) 99999-9999"
          />
          <Input
            placeholder="Email"
            autoComplete={EMAIL}
            onChange={onInputChange}
            name={EMAIL}
            highlight={false}
            value={formData[EMAIL]}
            disabled
          />
        </div>
        <div className={styles['two-columns']}>
          <Input
            placeholder="CEP"
            autoComplete={CEP}
            onChange={onInputChange}
            name={CEP}
            value={formData[CEP]}
            highlight={false}
            mask="99999-999"
            error={!!wrongCep}
            errorText={wrongCep}
          />
          <Input
            placeholder="Endereço"
            highlight={false}
            name={ADDRESS}
            onChange={onInputChange}
            value={formData[ADDRESS]}
          />
        </div>

        <div className={styles['two-columns-small']}>
          <Input
            className={styles.number}
            placeholder="Número"
            type="string"
            autoComplete={ADDRESS_NUMBER}
            onChange={onInputChange}
            name={ADDRESS_NUMBER}
            highlight={false}
            value={formData[ADDRESS_NUMBER]}
          />
          <Input
            placeholder="Complemento"
            autoComplete={ADDRESS_COMPLEMENT}
            onChange={onInputChange}
            name={ADDRESS_COMPLEMENT}
            highlight={false}
            value={formData[ADDRESS_COMPLEMENT]}
          />
        </div>
        <div className={styles['three-columns-small']}>
          <Input
            placeholder="Bairro"
            highlight={false}
            name={NEIGHBORHOOD}
            onChange={onInputChange}
            value={formData[NEIGHBORHOOD]}
          />
          <Input
            placeholder="Estado"
            highlight={false}
            onChange={onInputChange}
            value={formData[STATE]}
            name={STATE}
            maxLength={2}
          />
          <Input
            placeholder="Cidade"
            highlight={false}
            onChange={onInputChange}
            value={formData[CITY]}
            className={styles.disabled}
          />
        </div>
        <Button
          className={styles.button}
          theme={BUTTON_THEMES.PINK_NORMAL}
          disabled={disableButton}
          type="submit"
          isLoading={isProfileUpdating}
        >
          Salvar Alterações
        </Button>
      </form>
    </>
  )
}

export default React.memo(EditProfile)
