import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Loader from 'react-loader-spinner'

import styles from './styles.css'

export const BUTTON_THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PINK_NORMAL: 'pink',
  NO_BACKGROUND: 'no-background',
  OUTLINE: 'outline',
  GRADIENT: 'gradient',
  ICON: 'icon',
  HOME: 'home',
  BACKGROUND_WHITE: 'background-white',
}

const Button = ({ className, children, theme, type, isLoading, ...otherProps }) => {
  return (
    <button className={classnames(styles.button, styles[theme], className)} {...otherProps}>
      {isLoading ? <Loader type="Oval" color="white" height="16px" width="16px" /> : children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  theme: '',
  className: 'button',
  isLoading: false,
}

export default React.memo(Button)
