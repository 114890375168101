import { defineAction } from '_utils/redux'
import * as subjectsRequests from '_services/subjects'

export const GET_SUBJECT = defineAction('GET_SUBJECT')
export const GET_SUBJECTS = defineAction('GET_SUBJECTS')
export const GET_SUBJECT_PERFORMANCE = defineAction('GET_SUBJECT_PERFORMANCE')
export const GET_TOPICS = defineAction('GET_TOPICS')
export const GET_KEEP_WATCHING = defineAction('GET_KEEP_WATCHING')
export const SEARCH_LESSON = defineAction('SEARCH_LESSON')
export const GET_RECOMMENDED_EXERCISES = defineAction('GET_RECOMMENDED_EXERCISES')

export const getSubjects = () => (dispatch, getState) =>
  dispatch({
    type: GET_SUBJECTS.ACTION,
    payload: subjectsRequests.getSubjects(getState().user.key),
  })

export const getTopic = subjectId => (dispatch, getState) =>
  dispatch({
    type: GET_TOPICS.ACTION,
    payload: subjectsRequests.getTopic(subjectId, getState().user.key),
  })

export const getKeepWatching = subjectId => (dispatch, getState) =>
  dispatch({
    type: GET_KEEP_WATCHING.ACTION,
    payload: subjectsRequests.getKeepWatching(subjectId, getState().user.key),
  })

export const getSubject = subjectId => (dispatch, getState) =>
  dispatch({
    type: GET_SUBJECT.ACTION,
    payload: subjectsRequests.getSubject(subjectId, getState().user.key),
  })

export const getSubjectPerformance = subjectId => (dispatch, getState) =>
  dispatch({
    type: GET_SUBJECT_PERFORMANCE.ACTION,
    payload: subjectsRequests.getSubjectPerformance(subjectId, getState().user.key),
  })

export const searchLessoon = searchTerm => (dispatch, getState) =>
  dispatch({
    type: SEARCH_LESSON.ACTION,
    payload: subjectsRequests.searchLessoon(searchTerm, getState().user.key),
  })

export const clearSearchResults = () => ({
  type: SEARCH_LESSON.FULFILLED,
  payload: [],
})

export const getRecommendedExercises = () => (dispatch, getState) =>
  dispatch({
    type: GET_RECOMMENDED_EXERCISES.ACTION,
    payload: subjectsRequests.getRecommendedExercises(getState().user.key),
  })
