import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import parse from 'html-react-parser'

import Loader from '_components/loader'
import Breadcrumb from '_components/breadcrumb'
import Button, { BUTTON_THEMES } from '_components/button'
import { ROUTES } from '_utils/constants'
import SummationAlternatives from '_components/exam-alternatives/summation'
import EnemAlternatives from '_components/exam-alternatives/enem'
import FinishExamModal from '_components/finish-exam-modal'
import {
  getCurrentExam,
  getExamQuestions,
  answerExamQuestion,
  startExamQuestion,
  finishExam,
} from '_modules/mock-exams/actions'
import { examQuestionsSelector, currentExamSelector } from '_modules/mock-exams/selectors'

import styles from './styles.css'

const AnswerExam = ({ uri, selectedUniversityId, examId, areaId }) => {
  const examType = uri.includes(ROUTES.ENEM_EXAMS) ? 'ENEM' : 'Vestibulares'
  const link = uri.includes(ROUTES.ENEM_EXAMS) ? ROUTES.ENEM_EXAMS : ROUTES.MOCK_EXAMS

  const alternativesRef = useRef()
  const finishExamModalRef = useRef()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const examQuestions = useSelector(examQuestionsSelector)
  const currentExam = useSelector(currentExamSelector)

  useEffect(() => {
    if (currentExam?.lastOngoingQuestion?.question?.id) {
      const questionIndex = examQuestions.findIndex(
        ({ id }) => id === currentExam.lastOngoingQuestion.question.id
      )
      if (questionIndex !== -1) {
        setCurrentQuestionIndex(questionIndex)
      }
    }
  }, [currentExam, examQuestions])

  const dispatch = useDispatch()

  const currentQuestion = examQuestions?.[currentQuestionIndex] || {}

  useEffect(() => {
    if (examQuestions.length) {
      dispatch(
        startExamQuestion({
          universityId: selectedUniversityId,
          examId,
          areaId,
          questionId: currentQuestion.id,
        })
      )
    }
  }, [areaId, currentQuestion.id, dispatch, examId, examQuestions.length, selectedUniversityId])

  useEffect(() => {
    dispatch(getCurrentExam())
    dispatch(getExamQuestions({ universityId: selectedUniversityId, examId, areaId }))
  }, [areaId, dispatch, examId, selectedUniversityId])

  const progressStyle = useMemo(() => {
    const currentPercentage = (100 * (currentQuestionIndex + 1)) / examQuestions.length
    return {
      background: `linear-gradient(90deg, var(--light-blue) ${currentPercentage}%, var(--light-gray) ${currentPercentage}%)`,
    }
  }, [currentQuestionIndex, examQuestions.length])

  const handleFinishExam = useCallback(() => {
    dispatch(finishExam({ mockExamId: currentExam.id }))
    navigate(`/${link}`)
  }, [currentExam.id, dispatch, link])

  const handlePrevious = useCallback(() => setCurrentQuestionIndex(prev => prev - 1), [])

  const handleAnswerQuestion = useCallback(() => {
    const selectedAnswer = alternativesRef?.current?.getSelectedAnswer()

    dispatch(
      answerExamQuestion({
        universityId: selectedUniversityId,
        examId,
        areaId,
        questionId: currentQuestion.id,
        answer: selectedAnswer,
      })
    )
  }, [areaId, currentQuestion.id, dispatch, examId, selectedUniversityId])

  const handleFinished = useCallback(() => {
    finishExamModalRef.current.setVisibility(true)
  }, [])

  const handleNext = useCallback(() => {
    handleAnswerQuestion()
    if (currentQuestionIndex === examQuestions.length - 1) {
      finishExamModalRef.current.setVisibility(true)
    } else {
      setCurrentQuestionIndex(prev => prev + 1)
    }
  }, [currentQuestionIndex, examQuestions.length, handleAnswerQuestion])

  const renderNextButton = useMemo(
    () => (
      <Button
        theme={BUTTON_THEMES.PINK_NORMAL}
        className={styles['next-button']}
        onClick={handleNext}
      >
        {examQuestions.length !== currentQuestionIndex + 1 ? 'Próxima' : 'Finalizar'}
      </Button>
    ),
    [currentQuestionIndex, examQuestions.length, handleNext]
  )

  const currentKnowledgeArea = useMemo(() => {
    if (!currentExam?.exam?.knowledgeAreas) return null

    return currentExam.exam.knowledgeAreas.find(({ id }) => id === Number(areaId))
  }, [areaId, currentExam])

  return (
    <div className={styles.container}>
      <Breadcrumb text={`Simulados - ${examType}`} to={`${link}`} />
      <FinishExamModal ref={finishExamModalRef} finishExam={handleFinishExam} />
      <h1
        className={styles.title}
      >{`${currentExam?.exam?.entranceExam?.name} ${currentExam?.exam?.name}`}</h1>
      <div className={styles['subtitle-content']}>
        <h2 className={styles.subtitle}>{currentKnowledgeArea?.name}</h2>
        {examQuestions.length !== currentQuestionIndex + 1 && (
          <Button theme={BUTTON_THEMES.PINK_NORMAL} onClick={handleFinished}>
            Finalizar
          </Button>
        )}
      </div>
      {!currentQuestion ? (
        <Loader />
      ) : (
        <div className={styles.question}>
          {examQuestions.length > 0 ? (
            <>
              <div className={styles['progress-bar']} style={progressStyle} />
              <span className={styles['progress-label']}>
                PROGRESSO: {currentQuestionIndex + 1}/{examQuestions.length}
              </span>
              <h3 className={styles['question-title']}>Questão {currentQuestionIndex + 1}</h3>
              {currentQuestion?.image && (
                <img className={styles.image} src={currentQuestion.image} alt="Imagem da questão" />
              )}
              <span className={styles.description}>
                {currentQuestion?.description && parse(currentQuestion?.description)}
              </span>

              <div className={styles.choices}>
                {currentQuestion?.questionType === 'enem' ? (
                  <EnemAlternatives alternatives={currentQuestion.choices} ref={alternativesRef} />
                ) : (
                  <SummationAlternatives
                    alternatives={currentQuestion.choices}
                    ref={alternativesRef}
                  />
                )}
              </div>
              <div className={styles.buttons}>
                {!!currentQuestionIndex && (
                  <Button
                    theme={BUTTON_THEMES.NO_BACKGROUND}
                    className={styles['prev-button']}
                    onClick={handlePrevious}
                  >
                    Anterior
                  </Button>
                )}
                {renderNextButton}
              </div>
            </>
          ) : (
            <div className={styles['empty-container']}>
              <p className={styles.empty}>Infelizmente está prova não tem questões cadastradas!</p>
              <div className={styles['message-container']}>
                <p className={styles.message}>
                  Sugerimos que você{' '}
                  <Button
                    theme={BUTTON_THEMES.NO_BACKGROUND}
                    className={styles['finish-exam']}
                    onClick={handleFinished}
                  >
                    finalize
                  </Button>{' '}
                  e inicie um novo simulado.
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

AnswerExam.propTypes = {
  uri: PropTypes.string,
  selectedUniversityId: PropTypes.string,
  examId: PropTypes.string,
  areaId: PropTypes.string,
}

AnswerExam.defaultProps = {
  uri: '',
  selectedUniversityId: '',
  examId: '',
  areaId: '',
}

export default AnswerExam
