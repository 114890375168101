import React from 'react'
import PropTypes from 'prop-types'

import ReportIcon from '_assets/svgs/report.svg'

import styles from './styles.css'

const Report = ({ onClick }) => {
  return (
    <button type="button" className={styles.button} onClick={onClick}>
      <img src={ReportIcon} alt="Icone esclamação" />
      <p className={styles.text}>Reportar um erro</p>
    </button>
  )
}

Report.propTypes = {
  onClick: PropTypes.func,
}

Report.defaultProps = {
  onClick: () => {},
}

export default Report
