import {
  GET_SUBJECTS,
  GET_TOPICS,
  SEARCH_LESSON,
  GET_SUBJECT_PERFORMANCE,
  GET_RECOMMENDED_EXERCISES,
} from './actions'

export const getSubjectsSelector = ({ subjects }) => subjects?.data
export const areSubjectsLoadingSelector = ({ loading }) => loading[GET_SUBJECTS.ACTION]

export const currentSubjectSelector = ({ subjects }) => subjects?.currentSubject
export const isCurrentTopicLoadingSelector = ({ loading }) => loading[GET_TOPICS.ACTION]

export const subjectPerformanceSelector = ({ subjects }) => subjects?.performance
export const isLoadingSubjectPerformanceSelector = ({ loading }) =>
  loading[GET_SUBJECT_PERFORMANCE.ACTION]

export const searchResultSelector = ({ subjects }) => subjects?.searchResult
export const isSearchResultLoadingSelector = ({ loading }) => loading[SEARCH_LESSON.ACTION]
export const searchResultErrorSelector = ({ error }) => !!error[SEARCH_LESSON.ACTION]

export const recommendedExercisesSelector = ({ subjects }) =>
  subjects?.recommendedExercises.reduce((res, exercise) => {
    if (!res.some(({ id }) => id === exercise.id)) {
      res.push({ type: 'lessons', ...exercise })
    }

    return res
  }, [])
export const areRecommendedExercisesLoadingSelector = ({ loading }) =>
  loading[GET_RECOMMENDED_EXERCISES.ACTION]

export const subjectsByAreaSelector = ({ subjects }) =>
  Object.values(
    subjects?.data.reduce((res, subject) => {
      const prevArea = res[subject.area] || {}
      const prevSubjects = prevArea.subjects || []
      res[subject.area] = {
        ...prevArea,
        area: subject.area,
        subjects: [...prevSubjects, subject],
      }
      return res
    }, {})
  ).sort((a, b) => {
    if (a.area === 'Especiais') {
      return 1
    }
    if (b.area === 'Especiais') {
      return -1
    }
    return a.area.localeCompare(b.area)
  })
