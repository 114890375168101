import { createReducer } from '_utils/redux'

import {
  GET_MOCK_EXAMS_RESULTS,
  GET_ENTRANCE_EXAMS,
  GET_ENTRANCE_EXAM,
  GET_EXAM_QUESTIONS,
  GET_CURRENT_EXAM,
  FINISH_EXAM,
} from './actions'

const INITIAL_STATE = {
  results: [],
  entranceExams: [],
  entranceExamOptions: [],
  examQuestions: [],
  currentExam: {},
}

const mockExams = createReducer(INITIAL_STATE, {
  [GET_MOCK_EXAMS_RESULTS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      results: payload,
    }
  },
  [GET_ENTRANCE_EXAMS.PENDING]: state => {
    return {
      ...state,
      entranceExamOptions: [],
    }
  },
  [GET_ENTRANCE_EXAMS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      entranceExams: payload,
    }
  },
  [GET_ENTRANCE_EXAM.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      entranceExamOptions: payload,
    }
  },
  [GET_EXAM_QUESTIONS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      examQuestions: payload,
    }
  },
  [GET_CURRENT_EXAM.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      currentExam: payload[0] || {},
    }
  },
  [FINISH_EXAM.FULFILLED]: state => {
    return {
      ...state,
      currentExam: {},
    }
  },
})

export default mockExams
