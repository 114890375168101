import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { getUserSelector } from '_modules/user/selectors'
import useWindowsSize from '_hooks/use-windows-size'
import Breadcrumb from '_components/breadcrumb'
import { ROUTES } from '_utils/constants'
import GenerateStudyPlan from '_components/cards/generate-study-plan'
import StudyPlan from '_components/study-plan'
import {
  hasPlanSelector,
  userHasPlanLoading,
  isGettingPlanLoading,
} from '_modules/study-plan/selectors'
import Button, { BUTTON_THEMES } from '_components/button'
import PencilIcon from '_assets/svgs/pencil.svg'
import DeletePlanModal from '_components/study-plan/delete-plan-modal'

import styles from './styles.css'

const MENU_OPTION = {
  DAY: 'day',
  WEEK: 'week',
}

const Calendar = () => {
  const isDesktop = useWindowsSize().width >= 1025
  const hasPlan = useSelector(hasPlanSelector)
  const [createPlan, setCreatePlan] = useState(false)
  const gettingUserHasPlan = useSelector(userHasPlanLoading)
  const [selectedMenu, setMenu] = useState(isDesktop ? MENU_OPTION.WEEK : MENU_OPTION.DAY)
  const isGettingPlan = useSelector(isGettingPlanLoading)
  const modalRef = useRef()
  const user = useSelector(getUserSelector)
  const handleMenu = useCallback(event => {
    const { name } = event.currentTarget
    setMenu(name)
  }, [])
  const renderContent = useCallback(() => {
    if (createPlan) {
      return <GenerateStudyPlan setCreatePlan={setCreatePlan} />
    }

    return <StudyPlan showOnlyDay={selectedMenu === MENU_OPTION.DAY} />
  }, [createPlan, selectedMenu])

  const toggleModal = useCallback(
    () => modalRef.current && modalRef.current.setVisibility(true),
    [modalRef],
    []
  )

  useEffect(() => {
    if (!isDesktop) {
      setMenu(MENU_OPTION.DAY)
    }
  }, [isDesktop])

  const startCreatePlan = useCallback(() => {
    setCreatePlan(prevState => !prevState)
  }, [])

  const enableCreatePlan = user.hasActivePlan && !user.onFreeTrial

  return (
    <div className={styles.container}>
      <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
      <div className={styles['title-content']}>
        <h1 className={styles.title}>Calendário</h1>
        {hasPlan ? (
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.NO_BACKGROUND}
            onClick={toggleModal}
          >
            <div className={styles['button-content']}>
              <img alt="Icone editar" src={PencilIcon} />
              <p className={styles['button-text']}>Alterar Plano</p>
            </div>
          </Button>
        ) : (
          <Button
            className={styles['create-plan']}
            theme={BUTTON_THEMES.PINK_NORMAL}
            onClick={startCreatePlan}
            disabled={createPlan || !enableCreatePlan}
          >
            Criar Plano de Estudo
          </Button>
        )}
      </div>
      {!gettingUserHasPlan && hasPlan && (
        <div className={styles.view}>
          <h2 className={styles.type}>Visualizar por:</h2>
          <Button
            className={classnames(styles['type-button'], {
              [styles['non-selected']]: selectedMenu !== MENU_OPTION.DAY,
            })}
            theme={BUTTON_THEMES.NO_BACKGROUND}
            name={MENU_OPTION.DAY}
            onClick={handleMenu}
            disabled={isGettingPlan}
          >
            Dia
          </Button>
          {isDesktop && (
            <Button
              className={classnames(styles['type-button'], {
                [styles['non-selected']]: selectedMenu !== MENU_OPTION.WEEK,
              })}
              theme={BUTTON_THEMES.NO_BACKGROUND}
              name={MENU_OPTION.WEEK}
              onClick={handleMenu}
              disabled={isGettingPlan || !isDesktop}
            >
              Semana
            </Button>
          )}
        </div>
      )}
      {!gettingUserHasPlan && renderContent()}
      <DeletePlanModal ref={modalRef} />
    </div>
  )
}

export default Calendar
