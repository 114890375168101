import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import Breadcrumb from '_components/breadcrumb'
import { ROUTES } from '_utils/constants'
import Loader from '_components/loader'
import SubjectCard from '_components/subject-card'
import searchIcon from '_assets/icons/search.svg'
import closeIcon from '_assets/icons/close.svg'
import searchContent from '_assets/svgs/search-content.svg'
import searchErrorIllustration from '_assets/svgs/error.svg'
import LessonMiniature from '_components/lesson-miniature'
import { getSubjects, searchLessoon, clearSearchResults } from '_modules/subjects/actions'
import { getTheme } from '_utils/helpers'
import {
  subjectsByAreaSelector,
  areSubjectsLoadingSelector,
  searchResultSelector,
  isSearchResultLoadingSelector,
  searchResultErrorSelector,
} from '_modules/subjects/selectors'
import { usePrevious } from '_hooks/use-previous'
import useDebounce from '_hooks/use-debounce'

import styles from './styles.css'

const Subjects = () => {
  const dispatch = useDispatch()
  const areas = useSelector(subjectsByAreaSelector)
  const areSubjectsLoading = useSelector(areSubjectsLoadingSelector)
  const [isSearchActive, setSearchActive] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const searchResults = useSelector(searchResultSelector)
  const isSearchResultsLoading = useSelector(isSearchResultLoadingSelector)
  const wasSearchResultsLoading = usePrevious(isSearchResultsLoading)
  const searchError = useSelector(searchResultErrorSelector)

  const debouncedSearchLessoon = useDebounce(searchInput, 650)

  useEffect(() => {
    dispatch(getSubjects())
  }, [dispatch])

  useEffect(() => {
    if (debouncedSearchLessoon?.length > 1) {
      dispatch(searchLessoon(debouncedSearchLessoon))
    }
  }, [dispatch, debouncedSearchLessoon])

  const handleInputChange = useCallback(event => setSearchInput(event.target.value), [
    setSearchInput,
  ])

  const togleSearch = useCallback(() => {
    if (isSearchActive) {
      setSearchInput('')
      dispatch(clearSearchResults())
    }
    setSearchActive(!isSearchActive)
  }, [dispatch, isSearchActive])

  const onClickInput = useMemo(() => (!isSearchActive ? togleSearch : undefined), [
    isSearchActive,
    togleSearch,
  ])

  const renderAreas = useMemo(
    () =>
      areas.map(({ subjects, area: areaName }) => {
        const theme = getTheme(areaName)
        return (
          <div className={styles.area} key={areaName}>
            <h2 className={styles['area-title']}>{areaName}</h2>
            <hr className={classnames(styles.separator, styles[`theme-${theme}`])} />
            <div className={styles.subjects}>
              {subjects.map(({ name, id, totalLessons, watchedLessons, iconUrl }) => (
                <SubjectCard
                  icon={iconUrl}
                  subject={name}
                  subjectId={id}
                  key={id}
                  theme={theme}
                  numberOfLessons={totalLessons}
                  watchedLessons={watchedLessons}
                />
              ))}
            </div>
          </div>
        )
      }),
    [areas]
  )

  const renderSearch = useMemo(() => {
    if (
      searchError ||
      (!searchResults.length && !isSearchResultsLoading && wasSearchResultsLoading)
    ) {
      return (
        <div className={styles['search-result']}>
          <h2>
            Não encontramos resultados para: <span>{searchInput}</span>
          </h2>
          <img
            className={styles['search-error']}
            src={searchErrorIllustration}
            alt="Não foi possivel encontrar sua busca"
          />
        </div>
      )
    }

    if (isSearchResultsLoading) {
      return <Loader />
    }

    if (searchResults.length) {
      return (
        <div className={styles['search-result']}>
          <h2>
            Resultados para: <span>{searchInput}</span>
          </h2>
          <div className={styles.lessons}>
            {searchResults.map(({ name, id, thumbnailUrl, topic }) => (
              <LessonMiniature
                key={id}
                title={name}
                lessonId={id}
                thumbnailUrl={thumbnailUrl}
                topic={topic}
              />
            ))}
          </div>
        </div>
      )
    }

    return (
      <div className={styles['search-result']}>
        <img className={styles['empty-search']} src={searchContent} alt="Digite sua busca" />
      </div>
    )
  }, [isSearchResultsLoading, searchError, searchInput, searchResults, wasSearchResultsLoading])

  const renderContent = useMemo(() => {
    if (isSearchActive) return renderSearch
    if (areSubjectsLoading) {
      return <Loader />
    }
    return renderAreas
  }, [areSubjectsLoading, isSearchActive, renderAreas, renderSearch])

  const renderSearchBar = useMemo(() => {
    return (
      <div className={classnames(styles.search, { [styles.activate]: isSearchActive })}>
        <button className={styles.button} onClick={togleSearch}>
          <img className={styles['search-icon']} src={searchIcon} alt="Icone Pesquisar" />
        </button>
        <input
          onClick={onClickInput}
          className={styles['search-input']}
          placeholder="Buscar conteúdo"
          value={searchInput}
          onChange={handleInputChange}
          type="text"
        />
        <button onClick={togleSearch} className={classnames(styles.button, styles['close-button'])}>
          <img
            className={styles['close-icon']}
            src={closeIcon}
            alt="Icone de Fechar barra de pesquisa"
          />
        </button>
      </div>
    )
  }, [handleInputChange, isSearchActive, onClickInput, searchInput, togleSearch])

  return (
    <div className={styles.container}>
      <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
      <div className={styles.header}>
        <h1 className={classnames(styles.title, { [styles.hide]: isSearchActive })}>Disciplinas</h1>
        {renderSearchBar}
      </div>
      {renderContent}
    </div>
  )
}

export default Subjects
