import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate, Link } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { ROUTES } from '_utils/constants'
import StartExamModal from '_components/start-exam-modal'
import { startExam } from '_modules/mock-exams/actions'
import { ReactComponent as ArrowDown } from '_assets/svgs/arrow-down.svg'
import { ReactComponent as Edit } from '_assets/svgs/edit.svg'
import { ReactComponent as Watched } from '_assets/svgs/watched.svg'
import { getUserSelector } from '_modules/user/selectors'

import styles from './styles.css'

const ExamCollapsibleItem = ({ knowledgeAreas, theme, title, selectedUniversityId, examId }) => {
  const { hasActivePlan } = useSelector(getUserSelector)
  const [isOpen, setOpen] = useState(false)
  const [selectedExam, setSelectedExam] = useState()
  const startExamModal = useRef()

  const dispatch = useDispatch()

  const handleStartExam = useCallback(() => {
    if (!hasActivePlan) {
      navigate(`/${ROUTES.DASHBOARD}`)
      toast(
        <Alert
          theme={ALERT_THEMES.ERROR}
          message="Você precisa ter um plano ativo para realizar novos simulados!"
        />
      )
    } else {
      dispatch(startExam({ examId, selectedUniversityId, selectedExam }))
    }
  }, [dispatch, examId, hasActivePlan, selectedExam, selectedUniversityId])

  const handleOnClick = useCallback(({ currentTarget: { name } }) => {
    setSelectedExam(name)
    startExamModal.current.setVisibility(true)
  }, [])

  const handleToggle = useCallback(() => setOpen(prev => !prev), [])

  return (
    <div className={styles.topic}>
      <button
        className={classnames(styles['top-section'], styles[`theme-${theme}`], {
          [styles['is-open']]: isOpen,
        })}
        type="button"
        onClick={handleToggle}
      >
        <p className={styles.title}>{title}</p>
        <ArrowDown className={classnames(styles.arrow, { [styles['is-open']]: isOpen })} />
      </button>
      <StartExamModal ref={startExamModal} startExam={handleStartExam} />
      {isOpen &&
        knowledgeAreas.map(({ id, name, completed = false, questionCount }) => (
          <div className={styles.row} key={id}>
            <p className={styles.name}>{name}</p>
            <div className={styles.info}>
              <p className={styles['questions-length']}>QUESTÕES: {questionCount}</p>
              {completed && (
                <Link to={`../resultado/${id}`} className={styles.details}>
                  VER DETALHES
                </Link>
              )}
            </div>
            <button type="button" onClick={handleOnClick} name={id} className={styles.button}>
              {completed ? <Watched /> : <Edit />}
            </button>
          </div>
        ))}
    </div>
  )
}

ExamCollapsibleItem.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  selectedUniversityId: PropTypes.number,
  examId: PropTypes.number.isRequired,
  knowledgeAreas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

ExamCollapsibleItem.defaultProps = {
  title: '',
  theme: 'red',
  selectedUniversityId: '',
}

export default ExamCollapsibleItem
