import cookies from 'react-cookies'

import { convertDateResponse } from '_utils/helpers'
import { createReducer, LOGOUT } from '_utils/redux'
import { UPDATE_PROFILE } from '_modules/profile/actions'

import { LOGIN, REGISTER, GET_PROFILE_DATA, GET_USER_PERFORMANCE } from './actions'

const INITIAL_STATE = {}

const saveOnCookies = payload => {
  cookies.save('key', payload.key, { path: '/', sameSite: 'Strict' })
  cookies.save('data', payload.user, { path: '/', sameSite: 'Strict' })
}

const user = createReducer(INITIAL_STATE, {
  [LOGIN.FULFILLED]: (state, { payload }) => {
    saveOnCookies(payload)
    return {
      key: payload.key,
      data: payload.user,
    }
  },

  [REGISTER.FULFILLED]: (state, { payload }) => {
    saveOnCookies(payload)
    return {
      key: payload.key,
      data: payload.user,
    }
  },

  [LOGOUT]: () => {
    cookies.remove('key', { path: '/' })
    cookies.remove('data', { path: '/' })
    return INITIAL_STATE
  },

  [UPDATE_PROFILE.FULFILLED]: (state, { payload }) => {
    const data = { ...payload, birthDate: convertDateResponse(payload.birthDate) }
    cookies.save('data', data, { path: '/', sameSite: 'Strict' })
    return {
      ...state,
      data,
    }
  },

  [GET_PROFILE_DATA.FULFILLED]: (state, { payload }) => {
    const data = { ...payload, birthDate: convertDateResponse(payload.birthDate) }
    cookies.save('data', data, { path: '/', sameSite: 'Strict' })
    return {
      ...state,
      data,
    }
  },

  [GET_PROFILE_DATA.REJECTED]: (state, { payload }) => {
    if (payload.detail === 'Invalid token.') {
      cookies.remove('key', { path: '/' })
      cookies.remove('data', { path: '/' })
      window.location.replace('/login')
    }
  },

  [GET_USER_PERFORMANCE.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      performance: payload,
    }
  },
})

export default user
