import React, { useCallback } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import Modal from '_components/common/modal'
import Button, { BUTTON_THEMES } from '_components/button'

import styles from './styles.css'

const OngoingExamModal = React.forwardRef(({ navigateExam }, ref) => {
  const closeModal = useCallback(() => {
    ref.current.setVisibility(false)
    navigate('./')
  }, [ref])

  const handleFinish = useCallback(() => {
    navigateExam()
    ref.current.setVisibility(false)
  }, [ref, navigateExam])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Finalizar simulado" hideCloseButton>
      <div className={styles.start}>
        <h1 className={styles.title}>Você ainda não acabou um simulado</h1>
        <p className={styles.description}>
          Primeiro finalize o simulado para que possa iniciar outro
        </p>

        <div className={styles.buttons}>
          <Button
            theme={BUTTON_THEMES.NO_BACKGROUND}
            className={styles.button}
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button
            theme={BUTTON_THEMES.PINK_NORMAL}
            onClick={handleFinish}
            className={styles.button}
          >
            Ir para simulado
          </Button>
        </div>
      </div>
    </Modal>
  )
})

OngoingExamModal.propTypes = {
  navigateExam: PropTypes.func,
}

OngoingExamModal.defaultProps = {
  navigateExam: () => {},
}

export default React.memo(OngoingExamModal)
