import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getPlanDetails } from '_modules/plans/actions'
import { getPlanDetailsSelector } from '_modules/plans/selectors'

import GeneralInformation from './general-informations'
import PaymentOption from './payment-options'
import BuyResume from './buy-resume'
import DownloadBoleto from './download-boleto'
import CheckoutCard from './checkout-card'
import styles from './styles.css'

const BuyPlan = ({ planId }) => {
  const [step, setStep] = useState(0)
  const [discount, setDiscount] = useState(0)
  const dispatch = useDispatch()
  const selectedPlan = useSelector(getPlanDetailsSelector)
  const [selectedParcel, setParcel] = useState(null)
  useEffect(() => {
    if (planId) {
      dispatch(getPlanDetails(planId))
    }
  }, [dispatch, planId])

  const renderContent = useMemo(() => {
    switch (step) {
      case 0:
        return <GeneralInformation setStep={setStep} />
      case 1:
        return (
          <PaymentOption
            setStep={setStep}
            planId={planId}
            setDiscount={setDiscount}
            discount={discount}
            plan={selectedPlan}
            setParcel={setParcel}
            parcels={selectedParcel}
          />
        )
      case 2:
        return <DownloadBoleto />
      case 3:
        return <CheckoutCard />
      default:
        return null
    }
  }, [discount, planId, selectedParcel, selectedPlan, step])
  return (
    <div className={styles.container}>
      {renderContent}
      {step < 2 && (
        <BuyResume plan={selectedPlan} selectedParcel={selectedParcel} discount={discount} />
      )}
    </div>
  )
}

BuyPlan.propTypes = {
  planId: PropTypes.string,
}

BuyPlan.defaultProps = {
  planId: undefined,
}

export default BuyPlan
