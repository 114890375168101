import React, { useState, useCallback, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Button, { BUTTON_THEMES } from '_components/button'
import CalendarImage from '_assets/svgs/calendar.svg'
import SelectDropdown from '_components/select-dropdown'
import { getUniversities, getCourses } from '_modules/study-plan/actions'
import { getUniversitiesSelector, getCoursesSelector } from '_modules/study-plan/selectors'
import { getSubjects } from '_modules/subjects/actions'

import styles from './styles.css'

const BUTTONS = {
  COMPLETE: 'enem_and_exams',
  ENEM_ONLY: 'enem_only',
}

const GeneralInformation = ({ nexStep, data, setData }) => {
  const dispatch = useDispatch()
  const [planType, setPlanType] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)

  const universities = useSelector(getUniversitiesSelector)

  const courses = useSelector(getCoursesSelector)

  const selectPlanType = useCallback(event => setPlanType(event.currentTarget.name), [])

  const handleUniversity = useCallback(value => {
    setSelectedUniversity(value)
  }, [])

  const handleCourse = useCallback(value => {
    setSelectedCourse(value)
  }, [])

  const validFields = planType && selectedCourse && selectedUniversity

  const handleNextStep = useCallback(() => {
    setData(prevState => ({
      ...prevState,
      university: selectedUniversity.id,
      course: selectedCourse.id,
      preparationTest: planType,
    }))
    nexStep(prevState => prevState + 1)
  }, [nexStep, selectedUniversity, selectedCourse, planType, setData])

  useEffect(() => {
    if (data.university && !selectedUniversity) {
      const universityLoad = universities.find(item => item.id === data.university)
      setSelectedUniversity(universityLoad)
    }
  }, [data.university, selectedUniversity, universities])

  useEffect(() => {
    if (data.course && !selectedCourse) {
      const courseLoad = courses.find(item => item.id === data.course)
      setSelectedCourse(courseLoad)
    }
  }, [courses, data.course, selectedCourse])

  useEffect(() => {
    if (data.preparationTest) {
      setPlanType(data.preparationTest)
    }
  }, [data.preparationTest])

  useEffect(() => {
    if (!data.university || !data.course) {
      dispatch(getUniversities())
    }
  }, [dispatch, data.university, data.course])

  useEffect(() => {
    if (selectedUniversity && !data.course) {
      setSelectedCourse(null)
      dispatch(getCourses(selectedUniversity.id))
    }
  }, [selectedUniversity, dispatch, data.course])

  useEffect(() => {
    dispatch(getSubjects())
  }, [dispatch])

  return (
    <>
      <h2 className={styles.title}>Informações gerais</h2>
      <div className={styles.content}>
        <div className={styles.info}>
          <p className={styles['info-text']}>
            Que tal começar organizando sua rotina de estudos? Queremos saber algumas coisas para
            podermos criar o melhor plano para você.
          </p>
          <img className={styles.image} alt="Calendário" src={CalendarImage} />
        </div>
        <div className={styles.info}>
          <h2 className={classnames(styles.title, styles.subtitle)}>Selecione o tipo de plano:</h2>
          <p className={styles.text}>
            <span className={styles.highlight}>* </span>ENEM e Vestibulares: Todo o conteúdo da
            plataforma
          </p>
          <p className={styles.text}>
            <span className={styles.highlight}>* </span>Turbo ENEM : Principais conteúdos para o
            ENEM
          </p>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              name={BUTTONS.COMPLETE}
              onClick={selectPlanType}
              theme={
                planType === BUTTONS.COMPLETE ? BUTTON_THEMES.PINK_NORMAL : BUTTON_THEMES.OUTLINE
              }
            >
              ENEM e Vestibulares
            </Button>
            <Button
              className={styles.button}
              name={BUTTONS.ENEM_ONLY}
              onClick={selectPlanType}
              theme={
                planType === BUTTONS.ENEM_ONLY ? BUTTON_THEMES.PINK_NORMAL : BUTTON_THEMES.OUTLINE
              }
            >
              Turbo ENEM
            </Button>
          </div>
          <h2 className={classnames(styles.title, styles.subtitle)}>
            Em qual universidade você pretende estudar?
          </h2>
          <SelectDropdown
            className={styles.input}
            placeholder="Digite o nome da universidade"
            value={selectedUniversity}
            onChange={handleUniversity}
            options={universities}
          />
          <h2 className={classnames(styles.title, styles.subtitle)}>Selecione o curso</h2>
          <SelectDropdown
            className={styles.input}
            placeholder="Digite o nome do curso"
            value={selectedCourse}
            options={courses}
            isDisabled={!selectedUniversity?.id}
            onChange={handleCourse}
          />
          <Button
            className={styles.next}
            theme={BUTTON_THEMES.PINK_NORMAL}
            onClick={handleNextStep}
            disabled={!validFields}
          >
            Avançar
          </Button>
        </div>
      </div>
    </>
  )
}

GeneralInformation.propTypes = {
  nexStep: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    university: PropTypes.number,
    course: PropTypes.number,
    preparationTest: PropTypes.string,
  }),
}

GeneralInformation.defaultProps = {
  data: {},
}

export default GeneralInformation
