import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { getSubjectPerformance } from '_modules/subjects/actions'
import { getUserPerformanceSelector } from '_modules/user/selectors'
import { getUserPerformance } from '_modules/user/actions'
import {
  subjectPerformanceSelector,
  isLoadingSubjectPerformanceSelector,
} from '_modules/subjects/selectors'
import { getColorByTheme } from '_utils/helpers'
import Loader from '_components/loader'

import styles from './styles.css'

const stats = [
  { name: 'Aulas assistidas', key: 'watchedLessons' },
  { name: 'Exercícios resolvidos', key: 'solvedExercises' },
  { name: 'Taxa de acertos dos exercícios', key: 'correctExerciseHitRate' },
]

const PerformanceCard = ({
  subjectName,
  subjectId,
  theme,
  displayOnDashboard,
  isUserPerformance,
}) => {
  const dispatch = useDispatch()
  const subjectPerformance = useSelector(subjectPerformanceSelector)
  const isLoadingSubjectPerformance = useSelector(isLoadingSubjectPerformanceSelector)
  const userPerformance = useSelector(getUserPerformanceSelector)

  const percentageColor = useMemo(() => getColorByTheme(theme), [theme])

  useEffect(() => {
    if (isUserPerformance) {
      dispatch(getUserPerformance())
    } else {
      dispatch(getSubjectPerformance(subjectId))
    }
  }, [dispatch, subjectId, isUserPerformance])

  const chartStyle = useCallback(
    percentage => ({
      background: `linear-gradient(90deg, var(${
        displayOnDashboard ? '--light-green' : percentageColor
      }) ${percentage}%, var(--light-gray) ${percentage}%)`,
    }),
    [displayOnDashboard, percentageColor]
  )

  return (
    <div
      className={classnames(styles.performance, {
        [styles.dashboard]: displayOnDashboard,
      })}
    >
      <h2 className={classnames(styles.title, { [styles.hidden]: displayOnDashboard })}>
        Desempenho
      </h2>
      <h3
        className={classnames(styles.subject, styles[`theme-${theme}`], {
          [styles.hidden]: displayOnDashboard,
        })}
      >
        {subjectName}
      </h3>
      {isLoadingSubjectPerformance ? (
        <Loader />
      ) : (
        stats.map(({ name, key }) => {
          const currentPerformance = displayOnDashboard
            ? parseFloat(userPerformance && userPerformance[key] * 100)
            : parseFloat(subjectPerformance[key] * 100)
          const performance = currentPerformance || 0
          return (
            <div className={styles.row} key={key}>
              <div className={styles.chart} style={chartStyle(performance)} />
              <p>{name}</p>
              <p className={styles.percentage}>{`${performance.toFixed(0)}%`}</p>
            </div>
          )
        })
      )}
    </div>
  )
}

PerformanceCard.propTypes = {
  subjectId: PropTypes.string,
  theme: PropTypes.string,
  subjectName: PropTypes.string,
  displayOnDashboard: PropTypes.bool,
  isUserPerformance: PropTypes.bool,
}

PerformanceCard.defaultProps = {
  theme: 'red',
  subjectName: '',
  displayOnDashboard: false,
  isUserPerformance: false,
  subjectId: '',
}

export default React.memo(PerformanceCard)
