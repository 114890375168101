import React from 'react'
import Lottie from 'react-lottie'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'

import RocketAnimation from '_assets/lotties/rocket.json'
import { lottiesDefaultOptions } from '_utils/helpers'
import DashboardCard from '_components/cards/dashboard-card'
import { ROUTES } from '_utils/constants'

import styles from './styles.css'

const rocketAnimationOptions = {
  ...lottiesDefaultOptions,
  loop: true,
  animationData: RocketAnimation,
}

const BuyPlan = ({ isFreeTrail }) => {
  return (
    <DashboardCard
      large
      disableHeader
      fullContent
      content={
        <div className={styles.content}>
          {isFreeTrail ? (
            <div>
              <h2 className={styles.text}>
                Você está utilizando um plano de teste, com ele você tem acesso limitado as nossas
                funcionalidades e pode assistir até <b>3 vídeo aulas</b>.
              </h2>
              <h2 className={styles.text}>
                Para garantir acesso a todo conteúdo da plataforma adquira um plano e saía na frente
                do seu concorrente.
              </h2>
            </div>
          ) : (
            <h2 className={styles.text}>
              Percebemos que você não tem um plano ativo, para garantir acesso a todo conteúdo da
              plataforma adquira um plano e saía na frente do seu concorrente.
            </h2>
          )}
          <Link to={`/${ROUTES.PAYMENTS}`} className={styles.link}>
            COMPRAR PLANO
          </Link>
          <div className={styles.rocket}>
            <Lottie options={rocketAnimationOptions} width={150} />
          </div>
        </div>
      }
    />
  )
}

BuyPlan.propTypes = {
  isFreeTrail: PropTypes.bool.isRequired,
}

export default BuyPlan
