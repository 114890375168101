import axios from 'axios'

import { createFormData } from '_utils/helpers'

import { put } from './requests'

export const getAddress = cep => axios.get(`https://viacep.com.br/ws/${cep}/json`)

export const updateProfile = (key, id, payload) =>
  put(['user', id], { key }, createFormData(payload))
