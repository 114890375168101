import React, { useCallback, useState, useEffect, useMemo } from 'react'
import classnames from 'classnames'

import useWindowSize from '_hooks/use-windows-size'
import Button from '_components/button'

import { PLATFORM_SECTIONS } from '../constants'

import styles from './styles.css'

const DISPLAY_MODE = {
  MOBILE: 'displayOnMobile',
  DESKTOP: 'displayOnDesktop',
  TABLET: 'displayOnTablet',
}

const PlatformSection = () => {
  const [currentOption, setCurrentOption] = useState(0)
  const currentWidth = useWindowSize().width

  const currentDisplayMode = useMemo(() => {
    if (currentWidth <= 480) {
      return DISPLAY_MODE.MOBILE
    }
    if (currentWidth <= 1024) {
      return DISPLAY_MODE.TABLET
    }
    return DISPLAY_MODE.DESKTOP
  }, [currentWidth])

  const options = PLATFORM_SECTIONS.filter(item => item[currentDisplayMode])

  const handleClickOption = useCallback(event => {
    const { name } = event.currentTarget
    setCurrentOption(Number(name))
  }, [])

  useEffect(() => {
    const changeOptions = setTimeout(() => {
      setCurrentOption(prevState => (prevState + 1) % options.length)
    }, 3000)
    return () => clearTimeout(changeOptions)
  }, [currentOption, options.length])

  return (
    <section id="plataforma" className={styles.platform}>
      <div className={styles.options}>
        <h1 className={styles['options-title']}>
          Praticidade para organizar sua rotina de estudos
        </h1>
        {options.map(
          (option, index) =>
            option[currentDisplayMode] && (
              <Button
                key={option.title}
                name={index}
                onClick={handleClickOption}
                className={classnames(styles.option, {
                  [styles.selected]: index === currentOption,
                })}
              >
                <p className={styles['content-title']}>{option.title}</p>
                <p className={styles['content-text']}>{option.text}</p>
              </Button>
            )
        )}
      </div>
      <div className={styles['platform-image']}>{PLATFORM_SECTIONS[currentOption].image}</div>
    </section>
  )
}

export default PlatformSection
