import React from 'react'
import LoaderSpiner from 'react-loader-spinner'

import styles from './styles.css'

const Loader = () => {
  return (
    <div className={styles.loader}>
      <LoaderSpiner type="Oval" color="#ff4429" height="32px" width="32px" />
    </div>
  )
}

export default Loader
