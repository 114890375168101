import React from 'react'
import { Link } from '@reach/router'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const DashboardCard = ({
  header,
  title,
  content,
  buttonText,
  path,
  large,
  disableHeader,
  fullContent,
}) => {
  return (
    <div
      className={classnames(
        styles.container,
        { [styles.large]: large },
        { [styles['no-division']]: fullContent }
      )}
    >
      {!disableHeader && (
        <div className={classnames(styles.header, { [styles.large]: large })}>
          <div
            className={classnames(styles.background, { [styles.large]: large })}
            style={{ backgroundImage: `url(${header}` }}
          />
          <h2 className={styles.title}>{title}</h2>
        </div>
      )}
      <div className={classnames(styles.content, { [styles['full-content']]: fullContent })}>
        {content}
      </div>
      {buttonText && (
        <div className={styles['button-content']}>
          <Link className={styles.link} to={`/${path}`}>
            {buttonText}
          </Link>
        </div>
      )}
    </div>
  )
}

DashboardCard.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  buttonText: PropTypes.string,
  path: PropTypes.string,
  large: PropTypes.bool,
  disableHeader: PropTypes.bool,
  fullContent: PropTypes.bool,
}

DashboardCard.defaultProps = {
  content: '',
  title: '',
  buttonText: '',
  header: '',
  path: '',
  large: false,
  disableHeader: false,
  fullContent: false,
}
export default React.memo(DashboardCard)
