import React, { useState, useCallback } from 'react'
import classnames from 'classnames'

import EditProfile from './edit-profile'
import ChangePassword from './change-password'
import styles from './styles.css'

const MENU_OPTIONS = {
  EDIT: 'EDITAR PERFIL',
  CHANGE_PASS: 'ALTERAR SENHA',
}

const ProfileCard = () => {
  const [selectedOption, setSelectedOption] = useState(MENU_OPTIONS.EDIT)

  const changeOption = useCallback(event => {
    setSelectedOption(event.currentTarget.name)
  }, [])

  const renderContent = useCallback(() => {
    switch (selectedOption) {
      case MENU_OPTIONS.CHANGE_PASS:
        return <ChangePassword />
      default:
        return <EditProfile />
    }
  }, [selectedOption])

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        {Object.keys(MENU_OPTIONS).map(option => (
          <button
            className={classnames(styles.options, {
              [styles.selected]: selectedOption === MENU_OPTIONS[option],
            })}
            name={MENU_OPTIONS[option]}
            key={option}
            type="button"
            onClick={changeOption}
          >
            {MENU_OPTIONS[option]}
          </button>
        ))}
      </div>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  )
}

export default ProfileCard
