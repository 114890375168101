import React from 'react'

import { ROUTES } from '_utils/constants'
import DashboardIcon from '_assets/svgs/icon-dashboard.svg'
import CalendarIcon from '_assets/svgs/icon-calendar.svg'
import AreasIcon from '_assets/svgs/icon-areas.svg'
import TextIcon from '_assets/svgs/icon-text.svg'
import TestIcon from '_assets/svgs/icon-tests.svg'
import ProfileIcon from '_assets/svgs/icon-profile.svg'
import PlansIcon from '_assets/svgs/icon-plans.svg'
import EnemIcon from '_assets/svgs/icon-enem.svg'
import MockExamIcon from '_assets/svgs/icon-mock-exam.svg'
import Materials from '_assets/svgs/materials.svg'

export const courseButtons = [
  {
    id: 1,
    icon: <img alt="Icone do Perfil" src={DashboardIcon} />,
    label: 'Dashboard',
    link: ROUTES.DASHBOARD,
  },
  {
    id: 2,
    icon: <img alt="Icone das Notificações" src={CalendarIcon} />,
    label: 'Calendário',
    link: ROUTES.CALENDAR,
  },
  {
    id: 3,
    icon: <img alt="Icone dos Planos" src={AreasIcon} />,
    label: 'Disciplinas',
    link: ROUTES.SUBJECTS,
  },
  {
    id: 4,
    icon: <img alt="Icone do Logout" src={TextIcon} />,
    label: 'Redação',
    link: ROUTES.ESSAY,
  },

  {
    id: 6,
    icon: <img alt="Icone de Material didático" src={Materials} />,
    label: 'Material didático',
    link: ROUTES.MATERIALS,
    subMenu: [
      {
        id: 1,
        icon: <img alt="Icone do ENEM" src={EnemIcon} />,
        label: 'ENEM',
        link: ROUTES.MATERIALS_ENEM,
      },
      {
        id: 2,
        icon: <img alt="Icone dos Vestibulares" src={MockExamIcon} />,
        label: 'Vestibulares',
        link: ROUTES.MATERIALS_EXAMS,
      },
    ],
  },
  {
    id: 5,
    icon: <img alt="Icone de Simulados" src={TestIcon} />,
    label: 'Simulados',
    link: ROUTES.EXAMS,
    subMenu: [
      {
        id: 1,
        icon: <img alt="Icone do ENEM" src={EnemIcon} />,
        label: 'ENEM',
        link: ROUTES.ENEM_EXAMS,
      },
      {
        id: 2,
        icon: <img alt="Icone dos Vestibulares" src={MockExamIcon} />,
        label: 'Vestibulares',
        link: ROUTES.MOCK_EXAMS,
      },
    ],
  },
]

export const userButtons = [
  {
    id: 6,
    icon: <img alt="Icone do Perfil" src={ProfileIcon} />,
    label: 'Meu Perfil',
    link: ROUTES.PROFILE,
  },
  {
    id: 8,
    icon: <img alt="Icone de Disciplina" src={PlansIcon} />,
    label: 'Pagamentos',
    link: ROUTES.PAYMENTS,
  },
]
