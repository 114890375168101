import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import StudyPlanTag from '_components/calendar/study-plan-tag'

import styles from './styles.css'
import { WEEK_DAY, MONTHS } from './constants'

const DayColumn = ({ day, isSelected, events, setSelectedEvent, selectedEvent, ...otherProps }) => {
  return (
    <div className={styles.container}>
      <div className={classnames(styles.header, { [styles.selected]: isSelected })} {...otherProps}>
        <p className={styles.text}>{WEEK_DAY[day.date.getDay()]}</p>
        <p className={classnames(styles.number, styles.text)}>{day.date.getDate()}</p>
        <p className={styles.text}>{MONTHS[day.date.getMonth()]}</p>
      </div>

      {Object.keys(events).map(eventType =>
        events[eventType].map(event => (
          <StudyPlanTag
            key={event.id}
            type={eventType}
            event={event}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        ))
      )}
    </div>
  )
}

DayColumn.propTypes = {
  day: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
  }).isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  // TODO: Specify the shapes
  events: PropTypes.shape({}).isRequired,
  selectedEvent: PropTypes.shape({}),
  isSelected: PropTypes.bool,
}

DayColumn.defaultProps = {
  isSelected: false,
  selectedEvent: {},
}

export default DayColumn
