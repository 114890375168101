import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Loader from '_components/loader'
import Breadcrumb from '_components/breadcrumb'
import { ROUTES } from '_utils/constants'
import { getEssayThemes } from '_modules/essay/actions'
import { getEssayThemesSelector, getEssayThemesLoading } from '_modules/essay/selectors'

import ThemeOption from './theme-option'
import styles from './styles.css'

const EssayThemes = () => {
  const dispatch = useDispatch()
  const list = useSelector(getEssayThemesSelector)
  const isGettingThemes = useSelector(getEssayThemesLoading)

  useEffect(() => {
    dispatch(getEssayThemes())
  }, [dispatch])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Breadcrumb text="Redação" to={ROUTES.ESSAY} />
        <div className={styles['header-content']}>
          <h1 className={styles.title}>Temas de redação</h1>
        </div>
        {isGettingThemes && !list.length ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <h2 className={styles.subtitle}>Selecione um tema</h2>
            {list.map(item => (
              <ThemeOption key={item.id} theme={item} />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default EssayThemes
