import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import Breadcrumb from '_components/breadcrumb'
import Topic from '_components/topic'
import PerformanceCard from '_components/performance-card'
import VideoPlaylist from '_components/video-playlist'
import { currentSubjectSelector } from '_modules/subjects/selectors'
import { getTopic, getSubject, getKeepWatching } from '_modules/subjects/actions'
import { getTheme } from '_utils/helpers'

import styles from './styles.css'

const Topics = ({ subjectId }) => {
  const subject = useSelector(currentSubjectSelector)
  const { topics, name: subjectName, area, keepWatching } = subject
  const dispacth = useDispatch()
  const theme = useMemo(() => area && getTheme(area), [area])

  useEffect(() => {
    dispacth(getSubject(subjectId))
    dispacth(getTopic(subjectId))
    dispacth(getKeepWatching(subjectId))
  }, [dispacth, subjectId])

  return (
    <div className={styles['main-container']}>
      <Breadcrumb text="Disciplinas" to="disciplinas" />
      <h1 className={classnames(styles.subject, styles[`theme-${theme}`])}>{subjectName}</h1>
      <div className={styles.container}>
        <div className={styles.topics}>
          {topics.map(({ name, watchedLessons, totalLessons, lessons, id }, index) => (
            <Topic
              title={name}
              theme={theme}
              watchedLessons={watchedLessons}
              totalLessons={totalLessons}
              position={index + 1}
              key={name}
              topicId={id}
              lessons={lessons}
            />
          ))}
        </div>
        <PerformanceCard subjectId={subjectId} theme={theme} subjectName={subjectName} />
        {!!keepWatching?.length && (
          <VideoPlaylist
            videos={keepWatching}
            title="Continue assistindo"
            theme={theme}
            className={styles['keep-watching']}
          />
        )}
      </div>
    </div>
  )
}

Topics.propTypes = {
  subjectId: PropTypes.string,
}

Topics.defaultProps = {
  subjectId: '',
}

export default Topics
