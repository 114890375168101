import axios from 'axios'

import { defineAction, LOGOUT } from '_utils/redux'
import * as userRequests from '_services/user'

export const LOGIN = defineAction('LOGIN')
export const REGISTER = defineAction('REGISTER')
export const RESET_PASSWORD = defineAction('RESET_PASSWORD')
export const RECOVER_PASSWORD = defineAction('RECOVER_PASSWORD')
export const GET_PROFILE_DATA = defineAction('GET_PROFILE_DATA')
export const CHANGE_PASSWORD = defineAction('CHANGE_PASSWORD')
export const GET_USER_PERFORMANCE = defineAction('GET_USER_PERFORMANCE')

export const facebookLogin = payload => dispatch =>
  dispatch({
    type: LOGIN.ACTION,
    payload: userRequests.facebookLogin(payload),
  })

export const googleLogin = payload => dispatch =>
  dispatch({
    type: LOGIN.ACTION,
    payload: userRequests.googleLogin(payload),
  })

export const login = payload => async dispatch => {
  dispatch({ type: LOGIN.PENDING })

  try {
    const response = await userRequests.login(payload)
    dispatch({
      type: LOGIN.FULFILLED,
      payload: response,
    })
  } catch (error) {
    // eslint-disable-next-line camelcase
    if (error?.non_field_errors?.[0] === 'Unable to log in with provided credentials.') {
      try {
        const oldApiResponse = await axios.post(
          'https://app.prouniversidade.com.br/api/access/signin',
          { email: payload.email, senha: payload.password }
        )
        if (oldApiResponse?.data?.error === 'Senha ou usuário inválidos.') {
          dispatch({
            type: LOGIN.REJECTED,
            error: true,
            payload: error,
          })
        } else {
          window.location.replace(
            `https://app.prouniversidade.com.br/page/auto-login/${btoa(
              `${payload.email}:${payload.password}`
            )}`
          )
        }
      } catch (oldApiError) {
        dispatch({
          type: LOGIN.REJECTED,
          error: true,
          payload: error,
        })
      }
    }
  }
}

export const logout = () => dispatch =>
  dispatch({
    type: LOGOUT,
  })

export const register = payload => dispatch =>
  dispatch({
    type: REGISTER.ACTION,
    payload: userRequests.register(payload),
  })

export const recoverPassword = payload => dispatch =>
  dispatch({
    type: RECOVER_PASSWORD.ACTION,
    payload: userRequests.recoverPassword(payload),
  })

export const resetPassword = payload => ({
  type: RESET_PASSWORD.ACTION,
  payload: userRequests.resetPassword(payload),
})

export const getProfileData = () => (dispatch, getState) =>
  dispatch({
    type: GET_PROFILE_DATA.ACTION,
    payload: userRequests.getProfileData(getState().user.key),
  })

export const changePassword = payload => (dispatch, getState) =>
  dispatch({
    type: CHANGE_PASSWORD.ACTION,
    payload: userRequests.changePassword(getState().user.key, payload),
  })

export const getUserPerformance = () => (dispatch, getState) =>
  dispatch({
    type: GET_USER_PERFORMANCE.ACTION,
    payload: userRequests.getUserPerformance(getState().user.key),
  })
