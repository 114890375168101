import React, { useReducer, useCallback, useState, useEffect } from 'react'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import isAfter from 'date-fns/isAfter'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { createCustomEvent } from '_modules/study-plan/actions'
import { isCreatingCustomEventLoading, createCustomEventError } from '_modules/study-plan/selectors'
import Modal from '_components/common/modal'
import CustomEventImage from '_assets/svgs/custom-event.svg'
import Input from '_components/input'
import Button, { BUTTON_THEMES } from '_components/button'
import { usePrevious } from '_hooks/use-previous'
import { convertDateStringToRequest, urlRegexPattern } from '_utils/helpers'

import { INITIAL_STATE, UPDATE_FORM, reducer, TITLE, DATE, TIME, LINK } from './reducer'
import styles from './styles.css'

const CustomEventModal = React.forwardRef((_, ref) => {
  const dispatchRequest = useDispatch()
  const isCreatingCustomEvent = useSelector(isCreatingCustomEventLoading)
  const wasCreateCustomEvent = usePrevious(isCreatingCustomEvent)
  const hasCreateCustomEventError = useSelector(createCustomEventError)
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [inputErrors, setInputErrors] = useState({})

  const onInputChange = useCallback(
    event => {
      const {
        target: { name, value },
      } = event

      dispatch({
        type: UPDATE_FORM,
        payload: {
          [name]: value,
        },
      })
    },
    [dispatch]
  )

  const formatedDate = parse(formData[DATE], 'dd/MM/yyyy', new Date())
  const formatedTime = parse(formData[TIME], 'HH:mm', new Date())

  const formatedDateTime = parse(
    `${formData[DATE]} ${formData[TIME]}`,
    `dd/MM/yyyy HH:mm`,
    new Date()
  )

  const validateDate = formData[DATE] && formData[DATE].indexOf(' ') === -1

  const validateTime = formData[TIME] && formData[TIME].indexOf(' ') === -1

  const enableButton = !!formData[TITLE] && validateDate && validateTime

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()

      if (!isValid(formatedDate)) {
        setInputErrors({
          [DATE]: 'Data inválida. Utilize o formato DD/MM/YYYY!',
        })
        return null
      }
      if (!isValid(formatedTime)) {
        setInputErrors({
          [TIME]: 'Hora inválida. Utilize o formato HH:MM!',
        })
        return null
      }
      if (!isAfter(formatedDateTime, new Date())) {
        setInputErrors({
          [TIME]: 'Data inválida, adicione um evento futuro!',
        })
        return null
      }
      if (formData[LINK] && !urlRegexPattern.test(formData[LINK])) {
        setInputErrors({
          [LINK]: 'Link inválido, utilize url com http ou https.',
        })
        return null
      }
      const payload = {
        ...formData,
        [DATE]: convertDateStringToRequest(formData[DATE]),
      }
      if (!payload.link) {
        delete payload.link
      }
      dispatchRequest(createCustomEvent(payload))
      return null
    },
    [formData, formatedDateTime, dispatchRequest, formatedDate, formatedTime]
  )

  const closeModal = useCallback(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: INITIAL_STATE,
    })
    return ref.current && ref.current.setVisibility(false)
  }, [ref])

  useEffect(() => {
    if (wasCreateCustomEvent && !isCreatingCustomEvent && !hasCreateCustomEventError) {
      toast(<Alert theme={ALERT_THEMES.SUCCESS} message="Evento adicionado com sucesso!" />)
      dispatch({
        type: UPDATE_FORM,
        payload: INITIAL_STATE,
      })
      closeModal()
    }
  }, [wasCreateCustomEvent, isCreatingCustomEvent, hasCreateCustomEventError, closeModal])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Adicionar evento">
      <section className={styles['content-modal']}>
        <img alt="Celular" className={styles['custom-background']} src={CustomEventImage} />
        <h1 className={styles['modal-title']}>Adicionar Evento</h1>
        <p className={styles['modal-subtitle']}>
          Crie eventos personalizados para o seu calendário
        </p>
        <form className={styles['input-content']} onSubmit={handleSubmit}>
          <Input
            placeholder="Título"
            autoComplete={TITLE}
            className={styles.input}
            onChange={onInputChange}
            name={TITLE}
            highlight={false}
            value={formData[TITLE]}
          />
          <Input
            placeholder="Data"
            autoComplete={DATE}
            className={styles.input}
            onChange={onInputChange}
            name={DATE}
            error={!!inputErrors[DATE]}
            errorText={inputErrors[DATE]}
            highlight={false}
            mask="99/99/9999"
            value={formData[DATE] || ''}
          />
          <Input
            placeholder="Hora"
            autoComplete={TIME}
            className={styles.input}
            onChange={onInputChange}
            name={TIME}
            error={!!inputErrors[TIME]}
            errorText={inputErrors[TIME]}
            highlight={false}
            mask="99:99"
            value={formData[TIME] || ''}
          />
          <Input
            placeholder="Link"
            autoComplete={LINK}
            className={styles.input}
            onChange={onInputChange}
            error={!!inputErrors[LINK]}
            errorText={inputErrors[LINK]}
            name={LINK}
            highlight={false}
            value={formData[LINK] || ''}
          />
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.PRIMARY}
            disabled={!enableButton}
            isLoading={isCreatingCustomEvent}
            type="submit"
          >
            Salvar
          </Button>
        </form>
      </section>
    </Modal>
  )
})

export default React.memo(CustomEventModal)
