import React, { useMemo } from 'react'
import classnames from 'classnames'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import ptLocale from 'date-fns/locale/pt-BR'

import { capitalizeFirstLetter } from '_utils/helpers'
import Button, { BUTTON_THEMES } from '_components/button'
import CalendarTag from '_components/calendar/tag'
import Arrow from '_assets/svgs/calendar-arrow.svg'

import styles from './styles.css'

const DisplayByDay = ({
  events,
  handleChangeDay,
  currentDay,
  handleAddCustomEvent,
  isGettingPlan,
}) => {
  const eventsArray = useMemo(
    () =>
      Object.entries(events).reduce(
        (res, val) => [...res, ...val[1].map(event => ({ ...event, type: val[0] }))],
        []
      ),
    [events]
  )

  const formatCurrentDay = useMemo(() => {
    const dayWeek = format(currentDay, 'cccc, dd', { locale: ptLocale })
    const month = format(currentDay, 'LLLL', { locale: ptLocale })
    return `${capitalizeFirstLetter(dayWeek)} de ${capitalizeFirstLetter(month)}`
  }, [currentDay])

  return (
    <div className={styles['content-day']}>
      <button
        className={classnames(styles['step-button'], styles['left-button'])}
        type="button"
        name="previous"
        onClick={handleChangeDay}
        disabled={isGettingPlan}
      >
        <img alt="" src={Arrow} />
      </button>
      <p className={styles.day}>{formatCurrentDay}</p>
      <button
        className={classnames(styles['step-button'], styles['right-button'])}
        type="button"
        name="forward"
        onClick={handleChangeDay}
        disabled={isGettingPlan}
      >
        <img className={styles.rotate} alt="" src={Arrow} />
      </button>
      <div className={styles.events}>
        {eventsArray.length ? (
          eventsArray.map(data => <CalendarTag key={data.id} data={data} />)
        ) : (
          <p className={styles['no-day-event']}>Nenhum evento registrado para esta data</p>
        )}
        <Button
          className={styles.event}
          theme={BUTTON_THEMES.NO_BACKGROUND}
          onClick={handleAddCustomEvent}
        >
          + Adicionar evento
        </Button>
      </div>
    </div>
  )
}

DisplayByDay.propTypes = {
  // TODO: Specify the shapes
  events: PropTypes.shape({}),
  handleChangeDay: PropTypes.func.isRequired,
  handleAddCustomEvent: PropTypes.func.isRequired,
  isGettingPlan: PropTypes.bool.isRequired,
  currentDay: PropTypes.shape({}).isRequired,
}

DisplayByDay.defaultProps = {
  events: {},
}

export default DisplayByDay
