import format from 'date-fns/format'

import { createReducer } from '_utils/redux'

import {
  HAS_PLAN,
  GENERATE_PLAN,
  GET_UNIVERSITIES,
  GET_COURSES,
  GET_STUDY_PLAN,
  CREATE_CUSTOM_EVENT,
  DELETE_PLAN,
  TODAY_EVENTS,
  CLEAR_PLAN,
} from './actions'

const INITIAL_STATE = {
  hasPlan: null,
  universities: [],
  courses: [],
  plan: new Map(),
  todayEvents: [],
}

const studyPlan = createReducer(INITIAL_STATE, {
  [HAS_PLAN.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      hasPlan: payload.hasPlan,
    }
  },
  [GENERATE_PLAN.FULFILLED]: state => {
    return {
      ...state,
      hasPlan: true,
    }
  },
  [GET_UNIVERSITIES.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      universities: payload,
    }
  },
  [GET_COURSES.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      courses: payload,
    }
  },
  [GET_STUDY_PLAN.FULFILLED]: (state, { payload }) => {
    const plan = new Map(state.plan)
    payload.forEach(({ date, lessons, liveClasses, monitoring, customEvents }) => {
      plan.set(format(new Date(date), 'yyyy-MM-dd'), {
        date: new Date(date),
        events: {
          lessons: lessons.length ? lessons[0].lessons : [],
          liveClasses,
          monitoring,
          customEvents,
        },
      })
    })
    return {
      ...state,
      plan,
    }
  },
  [CREATE_CUSTOM_EVENT.FULFILLED]: (state, { payload }) => {
    const { plan } = state
    if (plan.get(payload.scheduledDate)) {
      const { date, events } = plan.get(payload.scheduledDate)
      plan.set(payload.scheduledDate, {
        date,
        events: {
          ...events,
          customEvents: [...events.customEvents, ...[payload]],
        },
      })
    } else {
      plan.set(payload.scheduledDate, {
        events: {
          customEvents: [payload],
        },
      })
    }
    const newPlan = new Map(plan)
    return { ...state, plan: newPlan }
  },
  [DELETE_PLAN.FULFILLED]: state => {
    const newPlan = new Map()
    state.plan.forEach(({ date }) => {
      newPlan.set(format(new Date(date), 'yyyy-MM-dd'), {
        date: new Date(date),
        events: {
          lessons: [],
          liveClasses: [],
          monitoring: [],
          customEvents: [],
        },
      })
    })
    return {
      ...state,
      hasPlan: false,
      plan: newPlan,
      todayEvents: {},
    }
  },
  [CLEAR_PLAN.FULFILLED]: state => {
    const newPlan = new Map()
    state.plan.forEach(({ date }) => {
      newPlan.set(format(new Date(date), 'yyyy-MM-dd'), {
        date: new Date(date),
        events: {
          lessons: [],
          liveClasses: [],
          monitoring: [],
          customEvents: [],
        },
      })
    })
    return {
      ...state,
      hasPlan: false,
      plan: newPlan,
      todayEvents: {},
    }
  },
  [TODAY_EVENTS.FULFILLED]: (state, { payload }) => {
    const { lessons, liveClasses, monitoring, customEvents } = payload
    return {
      ...state,
      todayEvents: {
        lessons: lessons.length ? lessons[0].lessons : [],
        liveClasses,
        monitoring,
        customEvents,
      },
    }
  },
})

export default studyPlan
