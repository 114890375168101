import React, { useMemo, useState, useCallback } from 'react'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '_components/common/modal'
import Input from '_components/input'
import { usePrevious } from '_hooks/use-previous'
import { validateEmail, lottiesDefaultOptions } from '_utils/helpers'
import Button, { BUTTON_THEMES } from '_components/button'
import { recoverPassword, RECOVER_PASSWORD } from '_modules/user/actions'
import {
  isRecoverPasswordLoadingSelector,
  recoverPasswordErrorSelector,
} from '_modules/user/selectors'
import errorIllustration from '_assets/svgs/error.svg'
import forgotPasswordIllustration from '_assets/svgs/forgot-password.svg'
import sendingEmailAnimation from '_assets/lotties/sending-email.json'
import emailSentAnimation from '_assets/lotties/email-sent.json'

import styles from './styles.css'

const ForgotPasswordModal = React.forwardRef((props, ref) => {
  const error = useSelector(recoverPasswordErrorSelector)
  const isLoading = useSelector(isRecoverPasswordLoadingSelector)
  const prevIsLoading = usePrevious(isLoading)
  const [emailValue, setEmailValue] = useState('')
  const dispatch = useDispatch()

  const onChangeEmail = useCallback(({ target: { value } }) => setEmailValue(value), [])

  const sendingEmailAnimationOptions = {
    ...lottiesDefaultOptions,
    loop: true,
    animationData: sendingEmailAnimation,
  }

  const emailSentAnimationOptions = {
    ...lottiesDefaultOptions,
    animationData: emailSentAnimation,
  }

  const resetRecoverPasswordError = useCallback(
    event => {
      event.preventDefault()
      setEmailValue('')
      dispatch({ type: RECOVER_PASSWORD.ACTION })
    },
    [dispatch]
  )

  const onSubmitRecoverPassword = useCallback(
    event => {
      event.preventDefault()
      dispatch(recoverPassword({ email: emailValue }))
    },
    [dispatch, emailValue]
  )

  const closeModal = useCallback(() => ref.current && ref.current.setVisibility(false), [ref])

  const validateFields = useMemo(() => validateEmail(emailValue), [emailValue])

  const renderInitial = useMemo(() => {
    return (
      <div className={styles['forgot-password']}>
        <img className={styles.illustration} src={forgotPasswordIllustration} alt="" />
        <form className={styles.form} onSubmit={onSubmitRecoverPassword}>
          <h1 className={styles.title}>Esqueceu sua senha?</h1>
          <p className={styles.description}>
            Digite o endereço de e-mail que você utilizou para se cadastrar. Nós lhe enviaremos um
            e-mail com informações para recuperá-la.
          </p>
          <Input
            placeholder="Digite seu e-mail"
            value={emailValue}
            onChange={onChangeEmail}
            type="email"
            autoComplete="email"
          />
          <Button
            theme={BUTTON_THEMES.PRIMARY}
            className={styles.button}
            disabled={!validateFields}
          >
            Enviar
          </Button>
        </form>
      </div>
    )
  }, [emailValue, onChangeEmail, onSubmitRecoverPassword, validateFields])

  const renderLoading = useMemo(
    () => (
      <div className={styles['sending-email']}>
        <div className={styles.container}>
          <div className={styles.lottie}>
            <Lottie options={sendingEmailAnimationOptions} height={250} />
          </div>
          <h1 className={styles.title}>Enviando e-mail...</h1>
          <p className={styles.description}>Aguarde um instante</p>
        </div>
      </div>
    ),
    [sendingEmailAnimationOptions]
  )

  const renderError = useMemo(
    () => (
      <div className={styles['forgot-password']}>
        <img className={styles.illustration} src={errorIllustration} alt="" />
        <form className={styles.form} onSubmit={resetRecoverPasswordError}>
          <h1 className={styles.title}>Ops...</h1>
          <p className={styles.description}>
            {`Não foi possível enviar um e-mail de recuperação de senha para `}
            <b>{emailValue}</b>. Verifique se seu e-mail foi digitado corretamente.
          </p>
          <Button theme={BUTTON_THEMES.PRIMARY} className={styles.button}>
            Tentar novamente
          </Button>
        </form>
      </div>
    ),

    [emailValue, resetRecoverPasswordError]
  )

  const rederSuccess = useMemo(() => {
    return (
      <div className={styles['forgot-password']}>
        <div className={styles.illustration}>
          <Lottie options={emailSentAnimationOptions} height={244} width={300} />
        </div>
        <form className={styles.form}>
          <h1 className={styles.title}>E-mail enviado!</h1>
          <p className={styles.description}>
            {`As informações para recuperação da sua senha foram enviadas para `}
            <b>{emailValue}</b>. Caso não tenha recebido, verifique sua pasta de Spam.
          </p>
          <Button theme={BUTTON_THEMES.PRIMARY} className={styles.button} onClick={closeModal}>
            Entrar na minha conta
          </Button>
        </form>
      </div>
    )
  }, [closeModal, emailSentAnimationOptions, emailValue])

  const redenderModalContent = useMemo(() => {
    if (isLoading) return renderLoading
    if (error) return renderError
    if (prevIsLoading && !isLoading) return rederSuccess

    return renderInitial
  }, [prevIsLoading, error, isLoading, rederSuccess, renderError, renderInitial, renderLoading])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Esqueceu sua senha?">
      {redenderModalContent}
    </Modal>
  )
})

export default React.memo(ForgotPasswordModal)
