import { defineAction } from '_utils/redux'
import * as notificatioRequests from '_services/notifications'

export const GET_NOTIFICATIONS = defineAction('GET_NOTIFICATIONS')
export const GET_NOTIFICATIONS_COUNT = defineAction('GET_NOTIFICATIONS_COUNT')
export const READ_NOTIFICATIONS = defineAction('READ_NOTIFICATIONS')

export const getNotifications = () => (dispatch, getState) =>
  dispatch({
    type: GET_NOTIFICATIONS.ACTION,
    payload: notificatioRequests.getNotificationsList(getState().user.key),
  })

export const getNotificationsCount = () => (dispatch, getState) =>
  dispatch({
    type: GET_NOTIFICATIONS_COUNT.ACTION,
    payload: notificatioRequests.getNotificationsCount(getState().user.key),
  })

export const readNotifications = () => (dispatch, getState) =>
  dispatch({
    type: READ_NOTIFICATIONS.ACTION,
    payload: notificatioRequests.markAsReadAllNotifications(getState().user.key),
  })
