import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { isCreatingPlanLoading } from '_modules/study-plan/selectors'

import styles from './styles.css'
import GeneralInformation from './general-information'
import Workload from './workload'
import SelectSubjects from './select-subjects'

const GenerateStudyPlan = ({ setCreatePlan }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const isCreatingPlan = useSelector(isCreatingPlanLoading)

  const [data, setData] = useState({})

  const renderStep = useMemo(() => {
    switch (currentStep) {
      case 0:
        return <GeneralInformation setData={setData} data={data} nexStep={setCurrentStep} />
      case 1:
        return <Workload setData={setData} data={data} handleStep={setCurrentStep} />
      case 2:
        return (
          <SelectSubjects
            setData={setData}
            data={data}
            handleStep={setCurrentStep}
            setCreatePlan={setCreatePlan}
          />
        )
      default:
        return null
    }
  }, [currentStep, data, setCreatePlan])

  return (
    <div className={classnames(styles.card, { [styles.hidden]: isCreatingPlan })}>{renderStep}</div>
  )
}
export default GenerateStudyPlan
