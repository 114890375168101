import React, { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import Alert, { ALERT_THEMES } from '_components/alert'
import { ReactComponent as UploadSvg } from '_assets/svgs/upload.svg'

import styles from './styles.css'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#e01e55',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const Dropzone = ({ setFile }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      setFile(acceptedFiles[0])
    },
    [setFile]
  )

  const onDropRejected = useCallback(() => {
    toast(<Alert theme={ALERT_THEMES.ERROR} message="Verifique o formato ou tamanho do arquivo" />)
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    maxSize: '1e+7',
    onDrop,
    onDropRejected,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <UploadSvg className={styles.upload} />
        {acceptedFiles[0] ? (
          <p
            className={styles.label}
          >{`${acceptedFiles[0].path} - ${acceptedFiles[0].size} bytes`}</p>
        ) : (
          <>
            <p className={classnames(styles.label, styles['upload-text'])}>Selecione seu arquivo</p>
            <p className={styles.label}>ou arraste e solte aqui</p>
            <p className={styles.instructions}>Extensões aceitas: png, jpg e jpge.</p>
            <p className={styles.instructions}>Tamanho máximo: 15mb</p>
          </>
        )}
      </div>
    </div>
  )
}
Dropzone.propTypes = {
  setFile: PropTypes.func.isRequired,
}

export default Dropzone
