/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import classnames from 'classnames'

import styles from './styles.css'

const Input = ({
  className,
  placeholder,
  type,
  required,
  error,
  errorText,
  highlight,
  mask,
  defaultValue,
  ...otherProps
}) => {
  const inputRef = useRef(null)

  const [isInputEmpty, setIsInputEmpty] = useState(true)

  useEffect(() => {
    if (inputRef.current.value !== '') {
      setIsInputEmpty(false)
    }
  }, [otherProps])

  const onBlur = useCallback(({ target: { value } }) => {
    setIsInputEmpty(!value)
  }, [])

  return (
    <div className={styles.container}>
      {mask ? (
        <InputMask
          ref={inputRef}
          className={classnames(
            styles.input,
            className,
            { [styles.active]: !isInputEmpty },
            { [styles.error]: error },
            { [styles['static-border']]: !highlight }
          )}
          type={type}
          name={placeholder}
          required={required}
          mask={mask}
          maskChar=" "
          value={defaultValue}
          onBlur={onBlur}
          {...otherProps}
        />
      ) : (
        <input
          ref={inputRef}
          className={classnames(
            styles.input,
            className,
            { [styles.active]: !isInputEmpty },
            { [styles.error]: error },
            { [styles['static-border']]: !highlight }
          )}
          type={type}
          name={placeholder}
          required={required}
          onBlur={onBlur}
          {...otherProps}
        />
      )}

      <span className={styles.highlight} />
      {highlight && <span className={styles.bar} />}
      <label className={styles.label}>{placeholder}</label>
      {error && <p className={styles['error-text']}>{errorText}</p>}
    </div>
  )
}

Input.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  mask: PropTypes.string,
  highlight: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Input.defaultProps = {
  type: 'text',
  className: '',
  required: false,
  error: false,
  errorText: '',
  mask: '',
  defaultValue: '',
  highlight: true,
}

export default React.memo(Input)
