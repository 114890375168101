import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

export const ALERT_THEMES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
}

const Alert = ({ theme, message }) => (
  <div className={classnames(styles.container, styles[theme])}>
    <p className={styles.message}>{message}</p>
  </div>
)

Alert.propTypes = {
  theme: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default React.memo(Alert)
