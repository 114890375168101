export const OLD_PASS = 'oldPassword'
export const NEW_PASS = 'newPassword1'
export const CONFIRM_PASS = 'newPassword2'

export const INITIAL_STATE = {
  [OLD_PASS]: '',
  [NEW_PASS]: '',
  [CONFIRM_PASS]: '',
}

export const UPDATE_FORM = 'UPDATE_FORM'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
