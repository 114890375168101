import React, { useCallback, useEffect } from 'react'
import { navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from '_utils/constants'
import { getEssays } from '_modules/essay/actions'
import Breadcrumb from '_components/breadcrumb'
import Loader from '_components/loader'
import Button, { BUTTON_THEMES } from '_components/button'
import EssayResumedDetail from '_components/essay-resumed-detail'
import { getEssaysSelector, areEssaysLoadingSelector } from '_modules/essay/selectors'

import styles from './styles.css'

const Essay = () => {
  const essays = useSelector(getEssaysSelector)
  const areEssaysLoading = useSelector(areEssaysLoadingSelector)
  const handleThemes = useCallback(() => navigate(ROUTES.ESSAY_THEMES), [])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEssays())
  }, [dispatch])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
        <div className={styles['header-content']}>
          <h1 className={styles.title}>Redação</h1>
        </div>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>Minhas redações</h2>
          {areEssaysLoading ? <Loader /> : <EssayResumedDetail essayList={essays} />}
          <Button
            theme={BUTTON_THEMES.PINK_NORMAL}
            className={styles.buttons}
            onClick={handleThemes}
          >
            Nova Redação
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Essay
