import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deletePlan, deletePlanAndHistory } from '_modules/study-plan/actions'
import {
  isDeletingPlanLoading,
  deletePlanError,
  isCleaningPlanLoading,
  clearPlanError,
} from '_modules/study-plan/selectors'
import Modal from '_components/common/modal'
import Button, { BUTTON_THEMES } from '_components/button'
import { usePrevious } from '_hooks/use-previous'

import styles from './styles.css'

const DeletePlanModal = React.forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const isDeleting = useSelector(isDeletingPlanLoading)
  const wasDeleting = usePrevious(isDeleting)
  const deleteError = useSelector(deletePlanError)

  const isCleaning = useSelector(isCleaningPlanLoading)
  const wasCleaning = usePrevious(isCleaning)
  const cleanError = useSelector(clearPlanError)

  const closeModal = useCallback(() => ref.current && ref.current.setVisibility(false), [ref])

  const deletePlanDefinitely = useCallback(() => {
    dispatch(deletePlan())
  }, [dispatch])

  const onDeletePlanAndHistory = useCallback(() => {
    dispatch(deletePlanAndHistory())
  }, [dispatch])

  const finishedDelete = wasDeleting && !isDeleting && !deleteError
  const finishedClean = wasCleaning && !isCleaning && !cleanError
  useEffect(() => {
    if (finishedDelete || finishedClean) {
      closeModal()
    }
  }, [closeModal, finishedClean, finishedDelete])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Adicionar evento">
      <section className={styles['content-modal']}>
        <h1 className={styles.title}>Você deseja deletar o seu plano de estudo?</h1>
        <div className={styles.option}>
          <Button
            theme={BUTTON_THEMES.PRIMARY}
            onClick={deletePlanDefinitely}
            isLoading={isDeleting}
          >
            Manter
          </Button>
          <p className={styles.text}>
            Ao optar por <b>manter seus histórico</b> de aulas assistidas, ao gerar um novo plano
            será desconsiderado todas as aulas assistidas.
          </p>
        </div>

        <div className={styles.option}>
          <Button
            theme={BUTTON_THEMES.PRIMARY}
            isLoading={isCleaning}
            onClick={onDeletePlanAndHistory}
          >
            Excluir
          </Button>
          <p className={styles.text}>
            Ao optar por <b>excluir seus histórico</b> de aulas assistidas ao gerar um novo plano
            será considerado todas as aulas assistidas.
          </p>
        </div>
        <p className={styles.warning}>
          * Não é possível restaurar o histórico de aulas assistidas após a exclusão.
        </p>

        <Button className={styles.cancel} theme={BUTTON_THEMES.SECONDARY} onClick={closeModal}>
          Cancelar
        </Button>
      </section>
    </Modal>
  )
})

export default React.memo(DeletePlanModal)
