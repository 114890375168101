import { get, post } from './requests'

export const getPlanDetails = (key, planId) =>
  get(['plans', planId], { key, transformPayload: true })

export const applyCoupon = (key, payload) =>
  post(['coupons', 'apply'], { key, transformPayload: true }, payload)

export const buyPlan = (key, planId, payload) =>
  post(['plans', planId, 'acquire'], { key, transformPayload: true }, payload)

export const plansBought = key => get(['bought-plans'], { key, transformPayload: true })
