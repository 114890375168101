import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { Link } from '@reach/router'
import classnames from 'classnames'

import TrashIcon from '_assets/svgs/trash.svg'
import Button, { BUTTON_THEMES } from '_components/button'
import DeleteEssayModal from '_components/delete-essay-modal'

import styles from './styles.css'

const EssayResumedDetail = ({ essayList }) => {
  const deleteEssayModalRef = useRef()
  const openDeleteModal = useCallback(() => deleteEssayModalRef.current.setVisibility(true), [])

  return essayList.length ? (
    essayList.map(essay => (
      <div key={essay.id} className={styles['essay-details']}>
        <DeleteEssayModal ref={deleteEssayModalRef} essayId={essay.id} />
        <p className={styles.date}>{format(new Date(essay.createdAt), 'dd/MM/yyyy HH:mm')}</p>
        <p className={styles.title}>{essay.theme.title}</p>
        <div className={styles.line}>
          <p className={styles.text}>
            Nota: <span className={styles['red-text']}>{essay.grade?.simpleGrade || '-'}</span>
          </p>
          <p className={styles.text}>
            {'Status: '}
            {essay.status === 'reviewed' ? (
              <span className={styles['green-text']}>Corrigida</span>
            ) : (
              <span className={styles['orange-text']}>Pendente</span>
            )}
          </p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className={classnames(styles.details, {
              [styles.disabled]: essay.status !== 'reviewed',
            })}
            to={essay.status === 'reviewed' ? `resultado/${essay.id}` : ''}
          >
            VER DETALHES
          </Link>
          <Button
            theme={BUTTON_THEMES.NO_BACKGROUND}
            name={essay.id}
            className={styles.button}
            onClick={openDeleteModal}
          >
            <img alt="Deletar Redação" src={TrashIcon} />
          </Button>
        </div>
      </div>
    ))
  ) : (
    <div className={styles['empty-state']}>
      <p className={styles['empty-text']}>Você ainda não enviou redações para correção</p>
    </div>
  )
}

EssayResumedDetail.propTypes = {
  essayList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      theme: PropTypes.shape({}),
      nota: PropTypes.string,
      status: PropTypes.string,
    })
  ),
}

EssayResumedDetail.defaultProps = {
  essayList: [],
}
export default EssayResumedDetail
