import React, { useCallback } from 'react'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'

import { ReactComponent as Success } from '_assets/svgs/success.svg'
import { ReactComponent as OpenBoleto } from '_assets/svgs/open-boleto.svg'
import Button, { BUTTON_THEMES } from '_components/button'
import { getPaymentFeedbackSelector } from '_modules/plans/selectors'

import styles from './styles.css'

const DownloadBoleto = () => {
  const paymentFeedback = useSelector(getPaymentFeedbackSelector)

  const handleClick = useCallback(() => {
    navigate('/dashboard')
  }, [])

  return (
    <div className={styles.boleto}>
      <h1 className={styles.title}>Pagamento</h1>
      <div className={styles.card}>
        <Success className={styles.image} />
        <h2 className={styles.subtitle}>Boleto gerado com sucesso</h2>
        <p className={styles.text}>
          Seja bem-vindo (a) ao Pró Online! Você recebeu uma cópia do boleto por e-mail. Seu acesso
          à plataforma será liberado após a confirmação do pagamento, que pode levar até 3 dias
          úteis.
        </p>
        <div className={styles['boleto-number']}>
          <p className={styles.number}>{paymentFeedback.boletoCode}</p>
          <a
            className={styles.button}
            href={paymentFeedback.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <OpenBoleto />
          </a>
        </div>
        <Button className={styles.end} theme={BUTTON_THEMES.PINK_NORMAL} onClick={handleClick}>
          Ir para plataforma
        </Button>
      </div>
    </div>
  )
}

export default DownloadBoleto
