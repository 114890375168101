import React, { useMemo, useRef, useState, useCallback, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from '_utils/constants'
import Breadcrumb from '_components/breadcrumb'
import EssayModal from '_components/essay-modal'
import Loader from '_components/loader'
import { getEssay } from '_modules/essay/actions'
import { getCurrentEssaySelector, isEssayLoadingSelector } from '_modules/essay/selectors'
import { getReviewType } from '_utils/helpers'

import styles from './styles.css'

const EssayResult = ({ essayId }) => {
  const modalRef = useRef()
  const imageRef = useRef()
  const [selectedComment, setComment] = useState(null)
  const essay = useSelector(getCurrentEssaySelector)
  const isEssayLoading = useSelector(isEssayLoadingSelector)
  const dispatch = useDispatch()
  const isSmallScreen = window.innerWidth <= 690

  const [coordXRate, setCoordXRate] = useState(1)
  const [coordYRate, setCoordYRate] = useState(1)

  const openModal = useCallback(
    text => () => {
      setComment(text)
      modalRef.current.setVisibility(true)
    },
    []
  )

  useLayoutEffect(() => {
    const updateSize = () => {
      if (imageRef.current) {
        if (imageRef.current.offsetWidth && imageRef.current.naturalWidth) {
          setCoordXRate(imageRef.current.offsetWidth / imageRef.current.naturalWidth)
        }
        if (imageRef.current.offsetHeight && imageRef.current.naturalHeight) {
          setCoordYRate(imageRef.current.offsetHeight / imageRef.current.naturalHeight)
        }
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  })

  const renderCompetencesGrades = useMemo(() => {
    const grade = essay.grade || {}
    if (essay.theme?.reviewType === 'enem') {
      return (
        <>
          <td>{grade.enemCompetenceOne || '-'}</td>
          <td>{grade.enemCompetenceTwo || '-'}</td>
          <td>{grade.enemCompetenceThree || '-'}</td>
          <td>{grade.enemCompetenceFour || '-'}</td>
          <td>{grade.enemCompetenceFive || '-'}</td>
          <td className={styles['final-grade']}>{grade.simpleGrade}</td>
        </>
      )
    }
    return (
      <>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>{grade?.simpleGrade || '-'}</td>
      </>
    )
  }, [essay])

  const renderComments = useMemo(
    () =>
      essay?.reviewPins?.map(({ text, coordX, coordY }) => (
        <button
          type="button"
          key={`${coordX}-${coordY}`}
          className={styles.dot}
          aria-label="correção"
          onClick={openModal(text)}
          style={{ bottom: +coordY * coordYRate, left: +coordX * coordXRate }}
        />
      )),
    [coordXRate, coordYRate, essay, openModal]
  )

  useEffect(() => {
    dispatch(getEssay(essayId))
  }, [dispatch, essayId])

  const renderContent = useMemo(() => {
    if (isEssayLoading) {
      return (
        <div className={styles.content}>
          <Loader />
        </div>
      )
    }

    return (
      <>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>Redação corrigida</h2>
          <p>
            {essay.theme?.title}{' '}
            <span className={styles['review-type']}>
              ({getReviewType(essay.theme?.reviewType)})
            </span>
          </p>

          {essay.reviewUrl ? (
            <>
              <p className={styles.instructions}>
                Clique nos balões vermelhos contidos em cima da redação para ver seus erros. No
                final da página veja sua nota e os comentários gerais da correção!
              </p>
              <div className={styles['essay-container']}>
                <div className={styles['essay-wrapper']}>
                  {renderComments}
                  <img
                    className={styles.image}
                    ref={imageRef}
                    src={essay.reviewUrl}
                    alt="Redação corrigida"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles['no-image']}>
              <p>Sua redação não foi convertida para uma imagem.</p>
              <p>
                Verifique os comentários gerais para saber o motivo e em caso de dúvida contante o
                suporte.
              </p>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>Comentários gerais</h2>
          <p>{essay.overallComments}</p>
        </div>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>
            Nota - Critério {getReviewType(essay.theme?.reviewType)}
          </h2>
          <table>
            <thead>
              <tr>
                {[...Array(5)].map((_, i) => (
                  <th>
                    Comp{isSmallScreen ? '.' : 'etência'} {i + 1}
                  </th>
                ))}
                <th>Nota Final</th>
              </tr>
            </thead>
            <tbody>
              <tr>{renderCompetencesGrades}</tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }, [
    essay.overallComments,
    essay.reviewUrl,
    essay.theme,
    isEssayLoading,
    isSmallScreen,
    renderComments,
    renderCompetencesGrades,
  ])

  return (
    <div className={styles.container}>
      <EssayModal ref={modalRef} comment={selectedComment} />
      <Breadcrumb text="Redação" to={ROUTES.ESSAY} />
      <div className={styles['header-content']}>
        <h1 className={styles.title}>Correção</h1>
      </div>
      {renderContent}
    </div>
  )
}

EssayResult.propTypes = {
  essayId: PropTypes.string,
}

EssayResult.defaultProps = {
  essayId: '',
}

export default EssayResult
