import format from 'date-fns/format'

import { GET_ESSAY_THEMES, CREATE_ESSAY, GET_ESSAY, GET_ESSAYS, DELETE_ESSAY } from './actions'

export const getEssayThemesSelector = ({ essay }) =>
  essay.themes?.reduce((acc, { title, pdfId, id, reviewType }) => {
    const themeIndex = acc.findIndex(item => item.title === title)
    if (themeIndex === -1) {
      return [...acc, { title, pdfId, types: [{ id, reviewType }] }]
    }
    acc[themeIndex] = {
      ...acc[themeIndex],
      types: [...acc[themeIndex].types, { id, reviewType }].sort((a, b) => {
        if ((a.reviewType[0] === 'c') !== (b.reviewType[0] === 'c')) {
          return a.reviewType[0] === 'c' ? 1 : -1
        }
        return a.reviewType.localeCompare(b.reviewType)
      }),
    }

    return acc
  }, [])

export const getEssayThemesLoading = ({ loading }) => !!loading[GET_ESSAY_THEMES.ACTION]

export const creatingEssay = ({ loading }) => !!loading[CREATE_ESSAY.ACTION]

export const createEssayError = ({ error }) => error[CREATE_ESSAY.ACTION]

export const getSelectedTheme = ({ essay }) => essay.selectedTheme || {}

export const getEssaysSelector = ({ essay }) => essay.essays
export const areEssaysLoadingSelector = ({ loading }) => !!loading[GET_ESSAYS.ACTION]

export const getCurrentEssaySelector = ({ essay }) => essay.currentEssay
export const isEssayLoadingSelector = ({ loading }) => !!loading[GET_ESSAY.ACTION]

export const isDeletingEssaySelector = ({ loading }) => !!loading[DELETE_ESSAY.ACTION]

const calcPercent = (grade, range) => {
  return grade ? ((Number(grade) / range) * 100).toFixed(2) : 0
}

export const getEssaysGraphSelector = ({ essay }) =>
  essay.essays
    .reduce((acc, { id, status, createdAt, grade }) => {
      if (status === 'reviewed') {
        const gradeInPercent =
          grade?.type === 'enem'
            ? calcPercent(grade?.simpleGrade, 1000)
            : calcPercent(grade?.simpleGrade, 10)
        return [
          ...acc,
          {
            id,
            status,
            date: format(new Date(createdAt), 'dd/MM/yyyy'),
            nota: Number(gradeInPercent),
            type: grade?.type,
            simpleGrade: grade?.simpleGrade || '0.00',
          },
        ]
      }
      return acc
    }, [])
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
