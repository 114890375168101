import { createReducer } from '_utils/redux'

import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_COUNT, READ_NOTIFICATIONS } from './actions'

const INITIAL_STATE = {
  list: [],
  unreadCount: undefined,
}

const notifications = createReducer(INITIAL_STATE, {
  [GET_NOTIFICATIONS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      list: payload,
    }
  },
  [GET_NOTIFICATIONS_COUNT.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      unreadCount: payload.newNotifications,
    }
  },
  [READ_NOTIFICATIONS.FULFILLED]: state => {
    return {
      ...state,
      unreadCount: undefined,
    }
  },
})

export default notifications
