import React, { useCallback, useMemo, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'react-loader-spinner'

import Input from '_components/input'
import Button, { BUTTON_THEMES } from '_components/button'
import InputCheck from '_components/input/input-check'
import { validateEmail, validatePassword } from '_utils/helpers'
import { register } from '_modules/user/actions'
import { isRegisterLoadingSelector, registerErrorSelector } from '_modules/user/selectors'

import { INITIAL_STATE, reducer, UPDATE_FORM, EMAIL, EMAIL_CONFIRMATION, PASSWORD } from './reducer'
import styles from './styles.css'

const SignUpForm = () => {
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)
  const dispatchAction = useDispatch()
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [emailsMatchError, setEmailsMatchError] = useState('')
  const isRegisterLoading = useSelector(isRegisterLoadingSelector)
  const error = useSelector(registerErrorSelector)

  const isEmailRegistred = useMemo(() => error && !!error.email, [error])
  const errorMessage = useMemo(
    () =>
      isEmailRegistred ? 'Email já cadastrado, você pode recuperar sua senha tela de login.' : '',
    [isEmailRegistred]
  )

  const onInputChange = useCallback(event => {
    const { name, value } = event.target

    dispatch({
      type: UPDATE_FORM,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const isCheckedTerms = useCallback(event => {
    setCheckedTerms(event.target.checked)
  }, [])

  const validateFields = useMemo(
    () =>
      validateEmail(formData[EMAIL]) &&
      formData[EMAIL_CONFIRMATION] &&
      validatePassword(formData[PASSWORD]) &&
      checkedTerms,
    [formData, checkedTerms]
  )

  const handleDispatch = useCallback(
    data => {
      const newUser = { role: 'student', ...data }
      dispatchAction(register(newUser))
    },
    [dispatchAction]
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      if (formData[EMAIL] === formData[EMAIL_CONFIRMATION]) {
        setEmailsMatchError('')
        handleDispatch(formData)
      } else {
        setEmailsMatchError('O e-mail e a confirmação de e-mail devem ser iguais.')
      }
    },
    [handleDispatch, formData]
  )

  return (
    <div className={styles.signup} onSubmit={handleSubmit}>
      <h1 className={styles.title}>Cadastre-se com seu e-mail</h1>
      <form className={styles.form}>
        <Input
          placeholder="E-mail"
          type={EMAIL}
          autoComplete={EMAIL}
          onChange={onInputChange}
          name={EMAIL}
          error={isEmailRegistred}
          errorText={errorMessage}
        />
        <Input
          placeholder="Confirme sue e-mail"
          type={EMAIL}
          autoComplete={EMAIL}
          onChange={onInputChange}
          name={EMAIL_CONFIRMATION}
          error={!!emailsMatchError}
          errorText={emailsMatchError}
        />
        <Input
          placeholder="Crie uma senha com 6 caracteres ou mais"
          type="password"
          autoComplete="password"
          onChange={onInputChange}
          name={PASSWORD}
        />
        <div className={styles.check}>
          <InputCheck onChange={isCheckedTerms} />
          <p className={styles.text}>
            Eu aceito os{' '}
            <a href="/TERMO_DE_USO_E_PRESTACAO_DE_SERVICOS.pdf" className={styles.link}>
              Termos e Condições
            </a>{' '}
            do Pró Online.
          </p>
        </div>
        <Button
          className={styles.button}
          theme={BUTTON_THEMES.SECONDARY}
          type="submit"
          disabled={!validateFields}
        >
          {isRegisterLoading ? (
            <Loader type="Oval" color="white" height="16px" width="16px" />
          ) : (
            'Completar Cadastro'
          )}
        </Button>
      </form>
    </div>
  )
}

export default React.memo(SignUpForm)
