import React, { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import parse from 'html-react-parser'

import styles from '../styles.css'

const SummationAlternatives = forwardRef(({ alternatives }, ref) => {
  const [selectedAnswers, setSelectedAnswers] = useState([])

  useEffect(() => {
    if (alternatives) {
      setSelectedAnswers([])
    }
  }, [alternatives])

  const handleSelectAlternative = useCallback(({ currentTarget: { name } }) => {
    setSelectedAnswers(prev =>
      !prev.includes(name) ? [...prev, name] : prev.filter(x => x !== name)
    )
  }, [])

  useImperativeHandle(ref, () => ({
    getSelectedAnswer: () =>
      selectedAnswers
        .sort((a, b) => Number(a) - Number(b))
        .reduce((res, answer, index) => {
          let newRes = res
          if (index !== 0) {
            newRes += '+'
          }
          newRes += answer

          return newRes
        }, ''),
  }))

  return (
    <div className={styles.alternatives}>
      {alternatives &&
        alternatives
          .sort((a, b) => {
            if (typeof a === 'string') {
              return a.value.localeCompare(b.value)
            }
            return Number(a.value) - Number(b.value)
          })
          .map(({ value, text, id, image }) => (
            <button
              type="button"
              key={id}
              name={value}
              onClick={handleSelectAlternative}
              className={classnames(styles.alternative, {
                [styles.selected]: selectedAnswers.includes(value),
              })}
            >
              {image && (
                <div className={styles['image-container']}>
                  <img
                    src={image}
                    alt={`Imagem da alternativa ${value}`}
                    className={styles.image}
                  />
                </div>
              )}
              <span className={styles['alternative-number']}>
                {String(value).padStart(2, '0')}.
              </span>
              <span className={styles.text}>{parse(text)}</span>
            </button>
          ))}
    </div>
  )
})

SummationAlternatives.propTypes = {
  alternatives: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default SummationAlternatives
