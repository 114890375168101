import { APPLY_COUPON, BUY_PLAN, PLANS_BOUGHT } from './actions'

export const getPlanDetailsSelector = ({ plans }) => plans.planDetails

export const isApplyingCupounLoading = ({ loading }) => !!loading[APPLY_COUPON.ACTION]

export const applyCouponError = ({ error }) => error[APPLY_COUPON.ACTION]

export const isBuyingPlanLoading = ({ loading }) => !!loading[BUY_PLAN.ACTION]

export const buyingPlanError = ({ error }) => !!error[BUY_PLAN.ACTION]

export const getPaymentFeedbackSelector = ({ plans }) => plans?.paymentFeedback

export const isGettingPlansSelector = ({ loading }) => !!loading[PLANS_BOUGHT.ACTION]

export const getPlansBoughtSelector = ({ plans }) =>
  plans?.plansBought
    ?.reduce((acc, curr) => {
      const {
        id,
        active,
        createdAt,
        accessDurationMonths,
        plan: { name },
        paymentType,
        payments,
        wirecardOrderStatus,
      } = curr
      const plan =
        payments.length > 1
          ? payments.sort(
              (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
            )[0]
          : payments[0]

      acc.push({
        id,
        active,
        createdAt,
        accessDurationMonths,
        paymentType,
        name,
        plan,
        status: wirecardOrderStatus,
      })
      return acc
    }, [])
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

export const getPlanActiveSelector = ({ plans }) => {
  const planActive = plans.plansBought.filter(plan => plan.active)
  const currentPlan = planActive[planActive.length - 1]
  if (currentPlan?.id) {
    return currentPlan
  }
  return {}
}
