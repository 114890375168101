import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import useWindowSize from '_hooks/use-windows-size'

import styles from './styles.css'

const CommentCard = ({ client }) => {
  const currentWdith = useWindowSize().width

  const animationSize = useMemo(() => {
    if (currentWdith <= 480) {
      return 210
    }
    if (currentWdith <= 1024) {
      return 350
    }
    return 433
  }, [currentWdith])

  return (
    <div className={styles.container} style={{ width: `${animationSize}px` }}>
      <img className={styles.image} alt="Foto do usuário" src={client.picture} />
      <p className={styles.name}>{client.author}</p>
      <p className={styles.course}>{client.approvedIn}</p>
      <p className={styles.text}>{client.text}</p>
    </div>
  )
}
CommentCard.propTypes = {
  client: PropTypes.shape({
    author: PropTypes.string,
    picture: PropTypes.string,
    approvedIn: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
}

export default CommentCard
