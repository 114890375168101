export const DESCRIPTION = 'description'
export const ERROR = 'errorType'
export const FILE = 'file'

export const INITIAL_STATE = {
  [DESCRIPTION]: '',
  [ERROR]: '',
  [FILE]: '',
}

export const UPDATE_FORM = 'UPDATE_FORM'
export const ADD_FILE = 'ADD_FILE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      }
    case ADD_FILE:
      return {
        ...state,
        [FILE]: action.payload,
      }
    default:
      return state
  }
}
