/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useMemo } from 'react'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'

import '!style-loader!css-loader!react-multi-carousel/lib/styles.css'

import useWindowSize from '_hooks/use-windows-size'
import CommentCard from '_components/comment-card'

import styles from './styles.css'

const CarouselComponent = ({ comments }) => {
  const sizeScreen = useWindowSize().width
  const numberOfCards = Math.floor(sizeScreen / (sizeScreen <= 1024 ? 350 : 473))
  const responsive = {
    bigScreen: {
      breakpoint: { min: 480, max: 1920 },
      items: numberOfCards,
      partialVisibilityGutter: 0,
    },
  }

  const evenList = useMemo(
    () =>
      comments.slice(
        0,
        comments.length % 2 ? (comments.length - 1) / 2 : (comments.length - 2) / 2
      ),
    [comments]
  )
  return (
    <Carousel
      arrows={false}
      infinite
      autoPlay
      autoPlaySpeed={2000}
      responsive={responsive}
      className={styles.carousel}
      itemClass={styles.item}
    >
      {evenList.map((comment, index) => {
        const current = index * 2
        return (
          <div key={comment.id} className={styles['double-card']}>
            <CommentCard className={styles['first-card']} client={comments[current]} />
            {comments[current + 1] && <CommentCard client={comments[current + 1]} />}
          </div>
        )
      })}
    </Carousel>
  )
}

CarouselComponent.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default CarouselComponent
