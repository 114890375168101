import { LOGIN, REGISTER, RECOVER_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD } from './actions'

export const getUserSelector = ({ user }) => user?.data

export const getUserKeySelector = ({ user }) => user.key

export const isLoggedSelector = ({ user }) => !!user.key

export const isLoginLoadingSelector = ({ loading }) => loading[LOGIN.ACTION]

export const isRegisterLoadingSelector = ({ loading }) => loading[REGISTER.ACTION]

export const loginErrorSelector = ({ error }) => error[LOGIN.ACTION] && error[LOGIN.ACTION]

export const registerErrorSelector = ({ error }) => error[REGISTER.ACTION] && error[REGISTER.ACTION]

export const isResetPasswordLoadingSelector = ({ loading }) => !!loading[RESET_PASSWORD.ACTION]

export const resetPasswordErrorSelector = ({ error }) => !!error[RESET_PASSWORD.ACTION]

export const isRecoverPasswordLoadingSelector = ({ loading }) => !!loading[RECOVER_PASSWORD.ACTION]

export const recoverPasswordErrorSelector = ({ error }) => error[RECOVER_PASSWORD.ACTION]

export const getProfileDataSelector = ({ user }) => user?.data

export const isChangingPasswordLoading = ({ loading }) => loading[CHANGE_PASSWORD.ACTION]

export const changePasswordError = ({ error }) => error[CHANGE_PASSWORD.ACTION]

export const getUserPerformanceSelector = ({ user }) => user?.performance
