import React, { useEffect, useCallback, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import Breadcrumb from '_components/breadcrumb'
import Button, { BUTTON_THEMES } from '_components/button'
import { ROUTES } from '_utils/constants'
import { getMockExamsResults } from '_modules/mock-exams/actions'
import { mockExamsResultsSelector } from '_modules/mock-exams/selectors'

import styles from './styles.css'

const renderAnswer = (correctAnswer, answer, type) => {
  if (!correctAnswer) return <p>{answer}</p>

  if (type === 'enem') {
    const className = correctAnswer === answer ? styles.green : styles.red
    return <span className={className}>{answer}</span>
  }

  const correctArray = correctAnswer?.split('+')
  const answerArray = answer?.split('+')

  return (
    <p>
      {answerArray?.map((number, i) => {
        const adjunct = i !== 0 ? '+' : ''
        const className = correctArray.includes(number) ? styles.green : styles.red

        return (
          <Fragment key={number}>
            {adjunct}
            <span className={className}>{number}</span>
          </Fragment>
        )
      })}
    </p>
  )
}

const renderDot = score => {
  let className = styles.incorrect

  if (score === 1) {
    className = styles.correct
  } else if (score && score !== 0) {
    className = styles.partial
  }

  return <div className={className} />
}

const ExamResult = ({ examId, uri, navigate }) => {
  const examType = uri.includes(ROUTES.ENEM_EXAMS) ? 'ENEM' : 'Vestibulares'
  const link = uri.includes(ROUTES.ENEM_EXAMS) ? ROUTES.ENEM_EXAMS : ROUTES.MOCK_EXAMS
  const handleGoBack = useCallback(() => navigate(`/${link}`), [link, navigate])

  const dispatch = useDispatch()
  const mockExamsResults = useSelector(mockExamsResultsSelector)
  const currentResult = mockExamsResults.find(({ id }) => Number(examId) === id)

  useEffect(() => {
    dispatch(getMockExamsResults())
  }, [dispatch, examType])

  const renderTableRows = useMemo(
    () =>
      currentResult?.questions.map(({ timeSpent, answerGrade, userAnswer, question }, index) => (
        <tr className={styles.row} key={`${timeSpent}-${index}`}>
          <td className={styles.status}>{renderDot(answerGrade)}</td>
          <td>{index + 1}</td>
          <td>{question?.answer}</td>
          <td>{renderAnswer(question?.answer, userAnswer, question?.questionType)}</td>
          <td>{timeSpent}</td>
          <td className={styles['align-right']}>{answerGrade?.toFixed(2) || '0.00'}</td>
        </tr>
      )),
    [currentResult]
  )

  return (
    <div className={styles.container}>
      <Breadcrumb text={`Simulados - ${examType}`} to={link} />
      <h1 className={styles.title}>
        {currentResult && `${currentResult?.exam?.entranceExam?.name} ${currentResult?.exam?.name}`}
      </h1>
      {/* TODO: GET specific area only */}
      {/* <h2 className={styles.subtitle}>História</h2> */}
      <div className={styles.result}>
        <div className={styles.summary}>
          <h3>Resumo</h3>
          <p>Nota:</p>
          <span>{currentResult?.grade.toFixed(2)}</span>
          <div className={styles.info}>
            <p>Acertos</p>
            <p>Erros</p>
            <p>Parciais</p>
            <span>{currentResult?.correctQuestions}</span>
            <span>{currentResult?.wrongQuestions}</span>
            <span>{currentResult?.partialQuestions}</span>
          </div>
          <p>Tempo de prova:</p>
          <span className={styles.time}>{currentResult?.timeSpent}</span>
          <Button
            onClick={handleGoBack}
            className={styles.button}
            theme={BUTTON_THEMES.PINK_NORMAL}
          >
            Voltar para simulados
          </Button>
        </div>
        <div className={styles.detailed}>
          <h3>Resultado detalhado</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.status}> </th>
                <th>Questão</th>
                <th>Gabarito</th>
                <th>Sua resposta</th>
                <th>Tempo</th>
                <th className={styles['align-right']}>Pontos</th>
              </tr>
            </thead>
            <tbody>{renderTableRows}</tbody>
          </table>
          <div className={styles.legend}>
            <div className={styles.correct} />
            <p>Correta</p>
            <div className={styles.incorrect} />
            <p>Incorreta</p>
            <div className={styles.partial} />
            <p>Parcial</p>
          </div>
        </div>
      </div>
    </div>
  )
}

ExamResult.propTypes = {
  examId: PropTypes.string,
  uri: PropTypes.string,
  navigate: PropTypes.func,
}

ExamResult.defaultProps = {
  navigate: () => {},
  examId: '',
  uri: '',
}

export default ExamResult
