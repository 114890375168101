import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/common/modal'
import Button, { BUTTON_THEMES } from '_components/button'

import styles from './styles.css'

const FinishExamModal = React.forwardRef(({ finishExam }, ref) => {
  const closeModal = useCallback(() => {
    ref.current.setVisibility(false)
  }, [ref])

  const handleFinish = useCallback(() => {
    finishExam()
    ref.current.setVisibility(false)
  }, [ref, finishExam])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Finalizar simulado" hideCloseButton>
      <div className={styles.start}>
        <h1 className={styles.title}>Finalizar simulado</h1>
        <p className={styles.description}>Tem certeza que deseja finalizar este simulado?</p>

        <div className={styles.buttons}>
          <Button
            theme={BUTTON_THEMES.NO_BACKGROUND}
            className={styles.button}
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button
            theme={BUTTON_THEMES.PINK_NORMAL}
            onClick={handleFinish}
            className={styles.button}
          >
            Finalizar
          </Button>
        </div>
      </div>
    </Modal>
  )
})

FinishExamModal.propTypes = {
  finishExam: PropTypes.func,
}

FinishExamModal.defaultProps = {
  finishExam: () => {},
}

export default React.memo(FinishExamModal)
