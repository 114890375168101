import React, { useCallback, useState } from 'react'
import { Link } from '@reach/router'
import classnames from 'classnames'

import { ReactComponent as PlanMan } from '_assets/svgs/plan-man.svg'
import { ReactComponent as BasicPlan } from '_assets/svgs/semi-ao-vivo.svg'
import { ReactComponent as Recommended } from '_assets/svgs/recommended.svg'
import { planPro } from '_utils/helpers'

import styles from './styles.css'

const PlansSection = () => {
  const [isFirstPlanOpen, setFirstPlanOpen] = useState(false)
  const [isSecondPlanOpen, setSecondPlanOpen] = useState(false)
  const [selectValue, setSelectValue] = useState('12')

  const toggleFirstPlan = useCallback(() => {
    setFirstPlanOpen(prev => !prev)
  }, [])

  const toggleSecondPlan = useCallback(() => {
    setSecondPlanOpen(prev => !prev)
  }, [])

  const handleSelect = useCallback(({ target: { value } }) => {
    setSelectValue(value)
  }, [])

  return (
    <section id="planos" className={styles.plans}>
      <div className={styles['plans-content']}>
        <h1 className={styles['plans-title']}>Escolha o melhor plano para você</h1>
        <p className={styles['text-content']}>
          Reunimos os melhores conteúdos em planos especiais para o novo calendário ENEM e
          Vestibulares. Estude pelo tempo que você quiser e pelo melhor preço do mercado.
        </p>
      </div>
      <div className={styles.plan}>
        <Recommended className={styles.recommended} />
        <div className={styles.wrapper}>
          <div className={classnames(styles.container, { [styles.open]: isFirstPlanOpen })}>
            <PlanMan
              className={classnames(styles['plan-man'], { [styles.open]: isFirstPlanOpen })}
            />
            <h2 className={styles['plan-title']}>Plano Pró</h2>
            <p className={styles.description}>
              Monitorias e aulas ao vivo toda semana, + de 2 mil aulas e 2 correções de redação por
              mês
            </p>
            <hr />
            <div className={styles.info}>
              <span>Acesso por</span>
              <span className={styles.parcels}>{`${selectValue}x 20,99`}</span>
              <select
                name="plan-type"
                className={styles.months}
                value={selectValue}
                onChange={handleSelect}
              >
                <option value="12">12 meses</option>
                <option value="6">6 meses</option>
                <option value="3">3 meses</option>
              </select>
              <p className={styles.price}>
                {`${selectValue}x `}
                <span>
                  R${' '}
                  {(planPro.parcelsOptions[selectValue].price / Number(selectValue))
                    .toFixed(2)
                    .replace('.', ',')}
                </span>
              </p>
            </div>
            <Link
              className={styles.button}
              to={`/cadastro/${planPro.parcelsOptions[selectValue].id}`}
            >
              Assine já!
            </Link>
            <button className={styles['details-button']} onClick={toggleFirstPlan}>
              {isFirstPlanOpen ? 'Ocultar detalhes' : 'Ver detalhes'}
            </button>
            <div className={classnames(styles.details, { [styles.open]: isFirstPlanOpen })}>
              <p>» Curso com mais de 2 mil aulas</p>
              <p>» Apostila para ENEM e vestibulares</p>
              <p>» 2 correções de redação por mês</p>
              <p>» Aulas ao vivo toda semana</p>
              <p>» Simulados ENEM e vestibulares</p>
              <p>» Atualidades e temas de redação</p>
              <p>» Planos de estudos personalizados</p>
              <p>» Monitorias ao vivo</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames(styles.plan, styles['basic-plan'])}>
        <div className={styles.wrapper}>
          <div className={classnames(styles.container, { [styles.open]: isSecondPlanOpen })}>
            <BasicPlan
              className={classnames(styles['basic-plan-illustration'], {
                [styles.open]: isSecondPlanOpen,
              })}
            />
            <h2 className={styles['plan-title']}>Semi Ao vivo</h2>
            <p className={styles.description}>
              Detone com o Semiextensivo Ao Vivo do Pró!
              Matutino, vespertino e noturno para você aproveitar.
            </p>
            <hr />
            <div className={styles['init-date']}>
              <span>Início em 02/08</span>
            </div>
            <a className={styles.button} target="_blank" rel="noopener noreferrer" href="https://prouniversidade.com.br/proaovivo">
              Assine já!
            </a>
            <button className={styles['details-button']} onClick={toggleSecondPlan}>
              {isSecondPlanOpen ? 'Ocultar detalhes' : 'Ver detalhes'}
            </button>
            <div className={classnames(styles.details, { [styles.open]: isSecondPlanOpen })}>
              <p>» Curso com mais de mil aulas</p>
              <p>» 1 correção de redação</p>
              <p>» Simulados ENEM e vestibulares</p>
              <p>» Planos de estudos personalizados</p>
              <p>» Apostila para ENEM e vestibulares</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PlansSection
