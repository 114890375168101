import React from 'react'

import Breadcrumb from '_components/breadcrumb'
import ProfileCard from '_components/cards/profile-card'
import { ROUTES } from '_utils/constants'

import styles from './styles.css'

const Profile = () => {
  return (
    <div className={styles.container}>
      <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
      <h1 className={styles.title}>Meu Perfil</h1>
      <ProfileCard />
    </div>
  )
}

export default React.memo(Profile)
