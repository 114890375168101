import { get, post, put } from './requests'

export const getMockExams = key => get(['mock-exams'], { key, transformPayload: true })

export const getMockExamsResults = (exam, key) =>
  get(['mock-exams', 'old-exams'], { key, transformPayload: true, params: { exam } })

export const getEntranceExams = (exam, key) =>
  get(['entrance-exams'], { key, transformPayload: true, params: { exam } })

export const getEntranceExam = (id, key) =>
  get(['entrance-exams', id, 'exams'], { key, transformPayload: true })

export const getExamQuestions = ({ universityId, examId, areaId, key }) =>
  get(['entrance-exams', universityId, 'exams', examId, 'knowledge-areas', areaId, 'questions'], {
    key,
    transformPayload: true,
  })

export const answerExamQuestion = ({ universityId, examId, areaId, questionId, answer, key }) =>
  put(
    [
      'entrance-exams',
      universityId,
      'exams',
      examId,
      'knowledge-areas',
      areaId,
      'questions',
      questionId,
      'answer',
    ],
    { transformPayload: true, key },
    { userAnswer: answer }
  )

export const startExamQuestion = ({ universityId, examId, areaId, questionId, key }) =>
  post(
    [
      'entrance-exams',
      universityId,
      'exams',
      examId,
      'knowledge-areas',
      areaId,
      'questions',
      questionId,
      'start',
    ],
    { transformPayload: true, key }
  )

export const finishExam = ({ mockExamId, key }) =>
  post(['mock-exams', mockExamId, 'finish'], { transformPayload: true, key })

export const startExam = ({ examId, key }) =>
  post(['mock-exams'], { transformPayload: true, key }, { examId })

export const getCurrentExam = key => get(['mock-exams'], { transformPayload: true, key })
