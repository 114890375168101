import React from 'react'

import { ReactComponent as Calendar } from '_assets/svgs/home-platform-1.svg'
import { ReactComponent as Lessons } from '_assets/svgs/home-platform-2.svg'
import { ReactComponent as Exercises } from '_assets/svgs/home-platform-3.svg'
import { ReactComponent as Materials } from '_assets/svgs/home-platform-4.svg'
import { ReactComponent as Texts } from '_assets/svgs/home-platform-5.svg'
import { ReactComponent as Results } from '_assets/svgs/home-platform-6.svg'
import { ReactComponent as WhatsappPink } from '_assets/svgs/whatsapp.svg'
import { ReactComponent as InstagranPink } from '_assets/svgs/instagram.svg'
import { ReactComponent as YoutubePink } from '_assets/svgs/youtube.svg'
import { ReactComponent as FacebookPink } from '_assets/svgs/facebook.svg'
import { ReactComponent as TwitterPink } from '_assets/svgs/twitter.svg'
import { ReactComponent as YoutubeWhite } from '_assets/svgs/youtube-white.svg'
import { ReactComponent as FacebookWhite } from '_assets/svgs/facebook-white.svg'
import { ReactComponent as InstagramWhite } from '_assets/svgs/instagram-white.svg'
import { ReactComponent as TwitterWhite } from '_assets/svgs/twitter-white.svg'

import styles from './styles.css'

export const PLATFORM_SECTIONS = [
  {
    title: 'A escolha é sua',
    text:
      'Selecione as disciplinas, dias de estudo, horários e adicione eventos personalizados para organizar sua rotina.',
    image: <Calendar className={styles.test} />,
    displayOnDesktop: true,
    displayOnMobile: true,
  },
  {
    title: 'Videoaulas e aulas ao vivo',
    text:
      'São mais de 2 mil videoaulas para você estudar, além de monitorias e aulas ao vivo toda semana.',
    image: <Lessons className={styles.test} />,
    displayOnDesktop: true,
    displayOnTablet: true,
    displayOnMobile: true,
  },
  {
    title: 'Exercícios e simulados online',
    text: 'Pratique seus conhecimentos com os exercícios e simulados da plataforma.',
    image: <Exercises className={styles.test} />,
    displayOnDesktop: true,
    displayOnTablet: true,
    displayOnMobile: true,
  },
  {
    title: 'Material didático',
    text:
      'Além de assistir as videoaulas, você pode fazer o download do material didático para estudar onde quiser.',
    image: <Materials className={styles.test} />,
    displayOnDesktop: true,
    displayOnTablet: true,
    displayOnMobile: true,
  },
  {
    title: 'Redações',
    text:
      'A redação é uma das partes mais temida das provas. Quem pratica no Pró recebe a correção com notas por competência e comentários do corretor.',
    image: <Texts className={styles.test} />,
    displayOnDesktop: true,
  },
  {
    title: 'Detone nas provas!',
    text:
      'Quem estuda no Pró Online sai na frente! Conheça nossos planos e faça parte do nosso time.',
    image: <Results className={styles.test} />,
    displayOnDesktop: true,
  },
]

export const MEDIAS_PINK = [
  {
    link:
      'https://api.whatsapp.com/send?phone=55489999999999&text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20uma%20d%C3%BAvida!',
    icon: <WhatsappPink />,
  },
  { link: 'https://www.facebook.com/ProUniversidade', icon: <FacebookPink /> },
  { link: 'https://www.instagram.com/prouniversidade', icon: <InstagranPink /> },
  { link: 'https://www.youtube.com/channel/UCm39xRtwIv7HfTPI1ngbFlg', icon: <YoutubePink /> },
  { link: 'https://twitter.com/cursinhopro', icon: <TwitterPink /> },
]

export const MEDIA_WHITE = [
  { link: 'https://www.facebook.com/ProUniversidade', icon: <FacebookWhite /> },
  { link: 'https://www.instagram.com/prouniversidade', icon: <InstagramWhite /> },
  { link: 'https://www.youtube.com/channel/UCm39xRtwIv7HfTPI1ngbFlg', icon: <YoutubeWhite /> },
  { link: 'https://twitter.com/cursinhopro', icon: <TwitterWhite /> },
]
