import { defineAction } from '_utils/redux'
import * as lessonService from '_services/lesson'

export const GET_LESSON = defineAction('GET_LESSON')
export const GET_MONITORING = defineAction('GET_MONITORING')
export const GET_LIVE_LESSON = defineAction('GET_LIVE_LESSON')
export const RATE_MONITORING = defineAction('RATE_MONITORING')
export const RATE_LESSON = defineAction('RATE_LESSON')
export const GET_RELATED_LESSONS = defineAction('GET_RELATED_LESSONS')
export const ANSWER_QUESTION = defineAction('ANSWER_QUESTION')
export const WATCH_LESSON = defineAction('WATCH_LESSON')

export const getLesson = ({ subjectId, topicId, lessonId }) => (dispatch, getState) =>
  dispatch({
    type: GET_LESSON.ACTION,
    payload: lessonService.getLesson({ lessonId, subjectId, topicId, key: getState().user.key }),
  })

export const getLiveLesson = lessonId => (dispatch, getState) =>
  dispatch({
    type: GET_LIVE_LESSON.ACTION,
    payload: lessonService.getLiveLesson(lessonId, getState().user.key),
  })

export const getMonitoring = lessonId => (dispatch, getState) =>
  dispatch({
    type: GET_MONITORING.ACTION,
    payload: lessonService.getMonitoring(lessonId, getState().user.key),
  })

export const rateLesson = ({ rating, topicId, lessonId, subjectId }) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: RATE_LESSON.PENDING,
    payload: rating,
  })
  try {
    await lessonService.rateLesson({
      rating,
      topicId,
      lessonId,
      subjectId,
      key: getState().user.key,
    })
    dispatch({
      type: RATE_LESSON.FULFILLED,
      payload: rating,
    })
  } catch (e) {
    dispatch({
      type: RATE_LESSON.REJECTED,
      payload: { error: true },
    })
  }
}

export const rateMonitoring = (lessonId, rating) => async (dispatch, getState) => {
  dispatch({
    type: RATE_MONITORING.PENDING,
    payload: rating,
  })
  try {
    await lessonService.rateMonitoring(lessonId, rating, getState().user.key)
    dispatch({
      type: RATE_MONITORING.FULFILLED,
      payload: rating,
    })
  } catch (e) {
    dispatch({
      type: RATE_MONITORING.REJECTED,
      payload: { error: true },
    })
  }
}

export const answerQuestion = ({ answer, subjectId, topicId, lessonId, questionId }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: ANSWER_QUESTION.ACTION,
    payload: lessonService.answerQuestion({
      answer,
      subjectId,
      topicId,
      lessonId,
      questionId,
      key: getState().user.key,
    }),
    meta: { questionId, userAnswer: answer },
  })

export const getRelatedLessons = ({ subjectId, topicId, lessonId }) => (dispatch, getState) =>
  dispatch({
    type: GET_RELATED_LESSONS.ACTION,
    payload: lessonService.getRelatedLessons({
      subjectId,
      topicId,
      lessonId,
      key: getState().user.key,
    }),
  })

export const watchLesson = ({ subjectId, topicId, lessonId, progress, key }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: WATCH_LESSON.ACTION,
    payload: lessonService.watchLesson({
      subjectId,
      topicId,
      lessonId,
      progress,
      key: key || getState().user.key,
    }),
  })
