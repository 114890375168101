import React, { useCallback, useState, useMemo, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import InputRangeStyled from '_components/input-range'
import Button, { BUTTON_THEMES } from '_components/button'

import styles from './styles.css'

const daysOptions = {
  SEG: { name: 'Segunda-feira', hours: 2 },
  TER: { name: 'Terça-feira', hours: 2 },
  QUA: { name: 'Quarta-feira', hours: 2 },
  QUI: { name: 'Quinta-feira', hours: 2 },
  SEX: { name: 'Sexta-feira', hours: 2 },
  SAB: { name: 'Sábado', hours: 2 },
  DOM: { name: 'Domingo', hours: 2 },
}

const TRANSLATE_TO_ENGLISH = {
  SEG: 'monday',
  TER: 'tuesday',
  QUA: 'wednesday',
  QUI: 'thursday',
  SEX: 'friday',
  SAB: 'saturday',
  DOM: 'sunday',
}

const TRANSLATE_TO_PORTUGUESE = {
  monday: 'SEG',
  tuesday: 'TER',
  wednesday: 'QUA',
  thursday: 'QUI',
  friday: 'SEX',
  saturday: 'SAB',
  sunday: 'DOM',
}

const onFormatInputRange = item => `${item}h`

const Workload = ({ data, setData, handleStep }) => {
  const days = useMemo(() => Object.keys(daysOptions), [])
  const [currentDays, setCurrentDays] = useState([])

  const [week, setWeek] = useState(
    Object.values(TRANSLATE_TO_PORTUGUESE).reduce((res, val) => ({ ...res, [val]: 2 }), {})
  )
  useEffect(() => {
    if (!data.weekDaysHours) {
      const formatedDays = days.slice(0, 5).map(item => ({ day: item, hours: 2 }))
      setCurrentDays(formatedDays)
    }
  }, [days, data])

  const handlePreviousStep = useCallback(() => handleStep(prevState => prevState - 1), [handleStep])

  const handleNextStep = useCallback(() => {
    const daysAndhours = currentDays.reduce(
      (res, { day }) => ({
        ...res,
        [TRANSLATE_TO_ENGLISH[day]]: week[day],
      }),
      {}
    )
    setData(prevState => ({ ...prevState, weekDaysHours: daysAndhours }))
    handleStep(prevState => prevState + 1)
  }, [currentDays, setData, handleStep, week])

  const shouldDisable = useCallback(
    day => {
      return !Object.values(currentDays).find(item => item.day === day)
    },
    [currentDays]
  )

  const onChangeRange = useCallback(
    day => currentValue => setWeek(prev => ({ ...prev, [day]: currentValue })),
    []
  )

  const selectTimePerDay = useMemo(
    () =>
      currentDays.map(({ day }) => (
        <div key={day}>
          <p className={styles.text}>{daysOptions[day]?.name}</p>
          <InputRangeStyled
            minValue={1}
            maxValue={10}
            value={week[day]}
            name={day}
            onFormat={onFormatInputRange}
            onChange={onChangeRange(day)}
          />
        </div>
      )),
    [currentDays, week, onChangeRange]
  )

  const handleDays = useCallback(
    event => {
      const { name } = event.currentTarget
      const isSelected = currentDays?.find(item => item.day === name)
      if (isSelected?.day) {
        setCurrentDays(prevState => prevState.filter(current => current.day !== isSelected.day))
        return null
      }
      setCurrentDays(prevState => [...prevState, { day: name, hours: 2 }])
      return null
    },
    [currentDays]
  )

  useEffect(() => {
    if (data.weekDaysHours) {
      const daysLoaded = Object.keys(data.weekDaysHours).map(item => {
        setWeek(prevState => ({
          ...prevState,
          [TRANSLATE_TO_PORTUGUESE[item]]: data.weekDaysHours[item],
        }))
        return {
          day: `${TRANSLATE_TO_PORTUGUESE[item]}`,
          hours: data.weekDaysHours[item],
        }
      })
      setCurrentDays(daysLoaded)
    }
  }, [data])

  return (
    <>
      <h1 className={styles.title}>Carga horária</h1>
      <h2 className={classnames(styles.title, styles.subtitle)}>
        Em quais dias da semana você deseja estudar?
      </h2>
      <div className={styles.week}>
        {days.map(day => (
          <Button
            className={classnames(styles.day, {
              [styles['no-selected']]: shouldDisable(day),
            })}
            theme={BUTTON_THEMES.PINK_NORMAL}
            name={day}
            key={day}
            onClick={handleDays}
          >
            {day}
          </Button>
        ))}
      </div>
      <h2 className={classnames(styles.title, styles.subtitle)}>
        Quantas horas por dia você deseja estudar?
      </h2>
      <div className={styles.time}>{selectTimePerDay}</div>
      <div className={styles.buttons}>
        <Button
          className={styles.next}
          theme={BUTTON_THEMES.NO_BACKGROUND}
          onClick={handlePreviousStep}
        >
          Voltar
        </Button>
        <Button className={styles.next} theme={BUTTON_THEMES.PINK_NORMAL} onClick={handleNextStep}>
          Avançar
        </Button>
      </div>
    </>
  )
}

Workload.propTypes = {
  handleStep: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    weekDaysHours: PropTypes.shape({
      day: PropTypes.string,
      hours: PropTypes.number,
    }),
  }),
}

Workload.defaultProps = {
  data: {},
}
export default Workload
