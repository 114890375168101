import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import OngoingExamModal from '_components/ongoing-exam-modal'
import Loader from '_components/loader'
import Breadcrumb from '_components/breadcrumb'
import ExamCollapsibleItem from '_components/exam-collapsible-item'
import SelectDropdown from '_components/select-dropdown'
import { ROUTES } from '_utils/constants'
import { getEntranceExams, getEntranceExam, getCurrentExam } from '_modules/mock-exams/actions'
import {
  entranceExamsSelector,
  entranceExamOptionsSelector,
  areEntranceExamOptionsLoadingSelector,
  currentExamSelector,
} from '_modules/mock-exams/selectors'

import styles from './styles.css'

const NewExam = ({ uri, navigate }) => {
  const previousRoute = uri.includes(ROUTES.ENEM_EXAMS) ? ROUTES.ENEM_EXAMS : ROUTES.MOCK_EXAMS
  const isEnem = uri.includes(ROUTES.ENEM_EXAMS)

  const currentExam = useSelector(currentExamSelector)
  const currentExamId = currentExam?.id
  const entranceExamOptions = useSelector(entranceExamOptionsSelector)
  const areEntranceExamOptionsLoading = useSelector(areEntranceExamOptionsLoadingSelector)
  const entranceExams = useSelector(entranceExamsSelector)
  const fistExamId = entranceExams?.[0]?.id
  const onRef = useRef()

  const [selectedUniversity, setSelectedUniversity] = useState('')

  const navigateExam = useCallback(() => {
    if (currentExam?.exam) {
      navigate(
        `../prova/${currentExam.exam.entranceExam.id}/edicao/${currentExam.exam.id}/area/${currentExam.exam.knowledgeAreas[0].id}`
      )
    }
  }, [currentExam, navigate])

  useEffect(() => {
    if (!!currentExamId && onRef?.current) {
      onRef.current.setVisibility(true)
    }
  }, [currentExamId, onRef])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentExam())
    dispatch(getEntranceExams(isEnem))
  }, [dispatch, isEnem])

  useEffect(() => {
    if (isEnem && entranceExams.length) {
      setSelectedUniversity({ id: fistExamId })
      dispatch(getEntranceExam(fistExamId))
    }
  }, [dispatch, entranceExams.length, fistExamId, isEnem])

  const handleUniversity = useCallback(
    university => {
      setSelectedUniversity(university)
      dispatch(getEntranceExam(university.id))
    },
    [dispatch]
  )

  const renderUniversities = useMemo(() => {
    if (isEnem) return null

    return (
      <>
        <p className={styles.topic}>Selecione a universidade</p>
        <SelectDropdown
          className={styles.input}
          placeholder="Digite o nome da universidade"
          value={selectedUniversity}
          onChange={handleUniversity}
          options={entranceExams}
        />
      </>
    )
  }, [entranceExams, handleUniversity, isEnem, selectedUniversity])

  const renderYears = useMemo(() => {
    if (!selectedUniversity && !isEnem) return null

    return (
      <>
        <p className={styles.topic}>Selecione o ano</p>
        {areEntranceExamOptionsLoading ? (
          <Loader />
        ) : (
          entranceExamOptions.map(({ id, name, knowledgeAreas }) => (
            <ExamCollapsibleItem
              key={id}
              title={name}
              examId={id}
              knowledgeAreas={knowledgeAreas}
              selectedUniversityId={selectedUniversity.id}
            />
          ))
        )}
      </>
    )
  }, [areEntranceExamOptionsLoading, entranceExamOptions, isEnem, selectedUniversity])

  return (
    <div className={styles.container}>
      <Breadcrumb
        text={`Simulados - ${previousRoute === ROUTES.ENEM_EXAMS ? 'ENEM' : 'Vestibulares'}`}
        to={previousRoute}
      />
      <OngoingExamModal ref={onRef} navigateExam={navigateExam} />
      <h1 className={styles.title}>Novo simulado</h1>
      {renderUniversities}
      {renderYears}
    </div>
  )
}

NewExam.propTypes = {
  uri: PropTypes.string,
  navigate: PropTypes.func,
}

NewExam.defaultProps = {
  uri: '',
  navigate: () => {},
}

export default NewExam
