import React from 'react'
import { Link } from '@reach/router'

import { ReactComponent as Logo } from '_assets/svgs/pro-logo-white.svg'

import styles from './styles.css'

const HeaderMenu = () => {
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.buttons}>
        <a className={styles.button} href="#planos">
          Planos
        </a>
        <Link className={styles.button} to="/login">
          Login
        </Link>
      </div>
    </div>
  )
}

export default HeaderMenu
