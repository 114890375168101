import { get, post } from './requests'

export const getLesson = ({ key, subjectId, topicId, lessonId }) =>
  get(['subjects', subjectId, 'topics', topicId, 'lessons', lessonId], {
    key,
    transformPayload: true,
  })

export const getLessons = ({ key, subjectId, topicId }) =>
  get(['subjects', subjectId, 'topics', topicId, 'lessons'], { key })

export const getLiveLesson = (lessonId, key) =>
  get(['live-classes', lessonId], { key, transformPayload: true })

export const getMonitoring = (lessonId, key) =>
  get(['monitoring', lessonId], { key, transformPayload: true })

export const rateMonitoring = (lessonId, rating, key) =>
  post(['monitoring', lessonId, 'rate'], { transformPayload: true, key }, { rating })

export const rateLesson = ({ subjectId, rating, topicId, lessonId, key }) =>
  post(
    ['subjects', subjectId, 'topics', topicId, 'lessons', lessonId, 'rate'],
    { transformPayload: true, key },
    { rating }
  )

export const answerQuestion = ({ answer, subjectId, topicId, lessonId, questionId, key }) =>
  post(
    [
      'subjects',
      subjectId,
      'topics',
      topicId,
      'lessons',
      lessonId,
      'questions',
      questionId,
      'answer',
    ],
    { transformPayload: true, key },
    { answer }
  )

export const getRelatedLessons = ({ subjectId, topicId, lessonId, key }) =>
  get(['subjects', subjectId, 'topics', topicId, 'lessons', lessonId, 'related'], {
    transformPayload: true,
    key,
  })

export const watchLesson = ({ subjectId, topicId, lessonId, progress, key }) =>
  post(
    ['subjects', subjectId, 'topics', topicId, 'lessons', lessonId, 'watch'],
    { transformPayload: true, key },
    { progress }
  )
