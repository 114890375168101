import React, { useEffect } from 'react'
import { Link } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { getTestimonialsSelector, isGettingTestimonials } from '_modules/testimonials/selectors'
import CarouselComponent from '_components/carousel'
import { getTestimonials } from '_modules/testimonials/actions'
import Loader from '_components/loader'

import styles from './styles.css'

const RecommendsSection = () => {
  const dispatch = useDispatch()

  const testimonials = useSelector(getTestimonialsSelector)
  const isLoading = useSelector(isGettingTestimonials)

  useEffect(() => {
    dispatch(getTestimonials())
  }, [dispatch])
  return (
    <section id="comentarios" className={styles.recommends}>
      <h1 className={styles['recommends-title']}>Quem fez Pró detonou!</h1>
      {isLoading ? <Loader /> : <CarouselComponent comments={testimonials} />}
      <div className={styles['button-content']}>
        <h2 className={styles['recommends-subtitle']}>Seja mais um aprovado Pró Online!</h2>
        <Link className={styles['recommends-button']} to="/cadastro">
          Quero ser Pró
        </Link>
      </div>
    </section>
  )
}

export default RecommendsSection
