import React, { useState, useCallback, useMemo, useReducer, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { MoipCreditCard, MoipValidator } from 'moip-sdk-js'
import JSEncrypt from 'node-jsencrypt'
import classnames from 'classnames'
import { toast } from 'react-toastify'

import SelectDropdown from '_components/select-dropdown'
import Alert, { ALERT_THEMES } from '_components/alert'
import InputCheck from '_components/input/input-check'
import Input from '_components/input'
import { validateCpf } from '_utils/helpers'
import { getProfileDataSelector } from '_modules/user/selectors'
import { getAddress } from '_modules/profile/actions'
import { applyCoupon, buyPlan } from '_modules/plans/actions'
import {
  isApplyingCupounLoading,
  applyCouponError,
  isBuyingPlanLoading,
  buyingPlanError,
  getPaymentFeedbackSelector,
} from '_modules/plans/selectors'
import Button, { BUTTON_THEMES } from '_components/button'
import { WIRECARD_KEY } from '_config/environment'
import { usePrevious } from '_hooks/use-previous'

import styles from './styles.css'
import {
  INITIAL_STATE,
  reducer,
  UPDATE_FORM,
  FIRST_NAME,
  CPF,
  CEP,
  CARD_NAME,
  CARD_NUMBER,
  PARCELS,
  CARD_EXPIRATION,
  SECURITY_CODE,
} from './reducer'

const calculateParcelValue = (parcels, value) => {
  return (value / parcels).toFixed(2)
}

const PaymentOptions = ({ setStep, setDiscount, plan, setParcel, parcels, discount }) => {
  const currentValue = useMemo(() => (plan.priceInCents / 100) * (1 - discount), [
    discount,
    plan.priceInCents,
  ])
  const possibleParcels = [...Array(Number(plan?.possibleParcels))].map((_, index) => ({
    label: `${index + 1}x  R$ ${calculateParcelValue(index + 1, currentValue)} sem juros`,
    value: index + 1,
  }))

  const [paymentWithCard, setPaymentWithCard] = useState(true)
  const [couponCode, setCouponCode] = useState('')
  const dispatchRequest = useDispatch()
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)
  const user = useSelector(getProfileDataSelector)
  const isApplyCoupon = useSelector(isApplyingCupounLoading)
  const wasApplyCoupon = usePrevious(isApplyCoupon)
  const hasApplyCouponError = useSelector(applyCouponError)
  const isBuyingPlan = useSelector(isBuyingPlanLoading)
  const wasBuyingPlan = usePrevious(isBuyingPlan)
  const hasBuyPlanError = useSelector(buyingPlanError)
  const paymentFeedback = useSelector(getPaymentFeedbackSelector)

  const onChangeCredCard = useCallback(() => {
    setPaymentWithCard(prevState => !prevState)
  }, [])

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target
      if (CARD_NAME === name) {
        dispatch({
          type: UPDATE_FORM,
          payload: {
            [name]: value.replace(/["'~`!@#$%^&()_={}[\]:;,.<>+/?-]+|\d+|^\s+$/g, '').toUpperCase(),
          },
        })
      } else {
        dispatch({
          type: UPDATE_FORM,
          payload: {
            [name]: value,
          },
        })
      }
    },
    [dispatch]
  )

  const errorCpf = useMemo(
    () =>
      formData[CPF] && !validateCpf(formData[CPF]) && formData[CPF].indexOf(' ') === -1
        ? 'CPF inválido'
        : '',

    [formData]
  )

  const cepFormated = useMemo(() => formData[CEP].replace(/[^0-9]/g, ''), [formData])

  useEffect(() => {
    if (cepFormated.length >= 8) {
      dispatchRequest(getAddress(cepFormated))
    }
  }, [cepFormated, dispatchRequest])

  const wrongCardExpiration = useMemo(() => {
    const cardExpiration = formData[CARD_EXPIRATION].split('/')
    const currentYear = Number(String(new Date().getFullYear()).slice(-2))
    if (
      formData[CARD_EXPIRATION].indexOf(' ') === -1 &&
      (Number(cardExpiration[0]) > 12 || Number(cardExpiration[1]) < currentYear)
    ) {
      return 'Validade do cartão inválido'
    }
    return ''
  }, [formData])

  const validateCardExpirationSize =
    formData[CARD_EXPIRATION].length >= 5 && formData[CARD_EXPIRATION].indexOf(' ') === -1

  const validateCardCodeSize =
    formData[SECURITY_CODE].length >= 3 && formData[SECURITY_CODE].indexOf(' ') === -1

  const validateCardNumberSize = formData[CARD_NUMBER].replace(/ /g, '').length >= 16

  const wrongCardNumber = useMemo(() => {
    if (!MoipValidator.isValidNumber(formData[CARD_NUMBER]) && validateCardNumberSize) {
      return 'Número do cartão inválido'
    }
    return ''
  }, [formData, validateCardNumberSize])

  const shouldDisable = useMemo(
    () =>
      paymentWithCard
        ? !formData[CARD_NAME] ||
          !formData[PARCELS] ||
          !formData[CARD_NUMBER] ||
          wrongCardExpiration ||
          wrongCardNumber ||
          !validateCardCodeSize ||
          !validateCardExpirationSize ||
          !validateCardNumberSize ||
          !formData[SECURITY_CODE]
        : false,
    [
      paymentWithCard,
      formData,
      validateCardCodeSize,
      validateCardExpirationSize,
      wrongCardNumber,
      validateCardNumberSize,
      wrongCardExpiration,
    ]
  )

  useEffect(() => {
    if (user?.firstName && !paymentWithCard && !formData[FIRST_NAME]) {
      Object.keys(user).map(key => {
        if (user[key])
          dispatch({
            type: UPDATE_FORM,
            payload: {
              [key]: user[key],
            },
          })
        return ''
      })
    }
  }, [formData, paymentWithCard, user])

  const handleBackStep = useCallback(
    event => {
      event.preventDefault()
      setStep(0)
    },
    [setStep]
  )
  useEffect(() => {
    if (paymentWithCard) {
      dispatch({
        type: UPDATE_FORM,
        payload: INITIAL_STATE,
      })
    }
  }, [paymentWithCard])

  const handleChangeCupom = useCallback(event => {
    const { value } = event.target
    setCouponCode(value.toUpperCase())
  }, [])

  const onRequestDiscount = useCallback(
    event => {
      event.preventDefault()
      dispatchRequest(applyCoupon({ planId: plan.id, couponCode }))
    },
    [couponCode, dispatchRequest, plan.id]
  )

  const encrptyCardData = useCallback(async () => {
    const cardExpiration = formData[CARD_EXPIRATION].split('/')
    const cardDetails = {
      number: formData[CARD_NUMBER].replace(/ /g, ''),
      cvc: formData[SECURITY_CODE],
      expirationMonth: cardExpiration[0],
      expirationYear: cardExpiration[1],
    }
    const hash = await MoipCreditCard.setEncrypter(JSEncrypt, 'node')
      .setPubKey(WIRECARD_KEY)
      .setCreditCard(cardDetails)
      .hash()
    return hash
  }, [formData])

  const handleSubmit = useCallback(
    async event => {
      let payload
      const { id } = plan
      event.preventDefault()
      if (paymentWithCard) {
        const hash = await encrptyCardData()
        payload = {
          [CPF]: formData[CPF],
          encryptedCreditCard: hash,
          paymentMethod: 'CREDIT_CARD',
          numberOfParcels: formData[PARCELS],
          couponCode,
        }
      } else {
        payload = {
          paymentMethod: 'BOLETO',
          numberOfParcels: 1,
          couponCode,
        }
      }
      dispatchRequest(buyPlan(id, payload))
    },
    [couponCode, dispatchRequest, encrptyCardData, formData, paymentWithCard, plan]
  )

  useEffect(() => {
    if (wasApplyCoupon && !isApplyCoupon) {
      if (hasApplyCouponError) {
        if (hasApplyCouponError.error === 'Invalid coupon') {
          toast(<Alert theme={ALERT_THEMES.ERROR} message="Cupom inváldio" />)
        } else {
          toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao aplicar o cupom" />)
        }
      } else {
        setDiscount(plan.discount)
        setParcel(null)
        dispatch({
          type: UPDATE_FORM,
          payload: { [PARCELS]: '' },
        })
        toast(<Alert theme={ALERT_THEMES.SUCCESS} message="Desconto aplicado com sucesso!" />)
      }
    }
  }, [hasApplyCouponError, isApplyCoupon, plan.discount, setDiscount, setParcel, wasApplyCoupon])

  const renderPaymentSlip = useMemo(() => {
    return (
      <>
        <div className={styles['payment-slip-form']}>
          <Button
            className={styles.pay}
            theme={BUTTON_THEMES.PINK_NORMAL}
            disabled={shouldDisable}
            onClick={handleSubmit}
            isLoading={isBuyingPlan}
          >
            Finalizar
          </Button>
          <Button
            className={styles.back}
            theme={BUTTON_THEMES.NO_BACKGROUND}
            type="button"
            onClick={handleBackStep}
          >
            Voltar
          </Button>
        </div>
      </>
    )
  }, [handleBackStep, handleSubmit, shouldDisable, isBuyingPlan])

  useEffect(() => {
    if (wasBuyingPlan && !isBuyingPlan) {
      if (!hasBuyPlanError) {
        if (paymentFeedback.payedWithCard) {
          setStep(3)
        } else {
          setStep(2)
        }
      } else {
        toast(
          <Alert
            theme={ALERT_THEMES.ERROR}
            message={
              paymentFeedback.payedWithCard
                ? 'Erro ao realizar pagamento'
                : 'Erro ao gerar o boleto'
            }
          />
        )
      }
    }
  }, [hasBuyPlanError, isBuyingPlan, wasBuyingPlan, paymentFeedback, setStep])

  const onChangeParcels = useCallback(
    option => {
      setParcel(option)
      dispatch({
        type: UPDATE_FORM,
        payload: { [PARCELS]: option.value },
      })
    },
    [setParcel]
  )

  const renderCardForm = useMemo(() => {
    return (
      <>
        <h3 className={styles['data-title']}>Dados do pagamento:</h3>
        <form className={styles['payment-card']} onSubmit={handleSubmit}>
          <Input
            placeholder="Número do cartão"
            onChange={onInputChange}
            name={CARD_NUMBER}
            highlight={false}
            value={formData[CARD_NUMBER]}
            mask="9999  9999  9999  9999"
            error={!!wrongCardNumber}
            errorText={wrongCardNumber}
            className={styles.input}
          />
          <Input
            placeholder="Nome impresso no cartão"
            onChange={onInputChange}
            className={classnames(styles.input, styles.upper)}
            name={CARD_NAME}
            highlight={false}
            value={formData[CARD_NAME]}
          />
          <Input
            placeholder="CPF do titular"
            autoComplete={CPF}
            onChange={onInputChange}
            className={styles.input}
            name={CPF}
            error={!!errorCpf}
            errorText={errorCpf}
            highlight={false}
            mask="999.999.999-99"
            value={formData[CPF]}
          />
          <Input
            placeholder="Validade do cartão (MM/AA)"
            onChange={onInputChange}
            className={styles.input}
            name={CARD_EXPIRATION}
            highlight={false}
            error={!!wrongCardExpiration}
            errorText={wrongCardExpiration}
            mask="99/99"
            value={formData[CARD_EXPIRATION]}
          />
          <Input
            placeholder="Código de segurança"
            onChange={onInputChange}
            className={styles.input}
            name={SECURITY_CODE}
            highlight={false}
            mask="999"
            value={formData[SECURITY_CODE]}
          />
          <SelectDropdown
            className={classnames(styles.input, styles.parcels)}
            placeholder="Número de parcelas"
            value={parcels}
            onChange={onChangeParcels}
            options={possibleParcels}
          />
          <Button
            className={styles.pay}
            theme={BUTTON_THEMES.PINK_NORMAL}
            disabled={shouldDisable}
            type="submit"
            isLoading={isBuyingPlan}
          >
            Finalizar
          </Button>

          <Button
            className={styles.back}
            theme={BUTTON_THEMES.NO_BACKGROUND}
            type="button"
            onClick={handleBackStep}
          >
            Voltar
          </Button>
        </form>
      </>
    )
  }, [
    errorCpf,
    onChangeParcels,
    parcels,
    formData,
    handleBackStep,
    handleSubmit,
    onInputChange,
    shouldDisable,
    possibleParcels,
    wrongCardExpiration,
    wrongCardNumber,
    isBuyingPlan,
  ])

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <h1 className={styles.title}>Pagamento</h1>
        <form className={styles.cupom} onSubmit={onRequestDiscount}>
          <input
            type="text"
            className={styles['cupom-input']}
            placeholder="Cupom de desconto"
            value={couponCode}
            onChange={handleChangeCupom}
          />
          <Button className={styles['cupom-button']} type="submit">
            Aplicar
          </Button>
        </form>
      </div>
      <div className={styles.card}>
        <h2 className={styles.subtitle}>Selecione a forma de pagamento</h2>
        <div className={styles.select}>
          <div className={styles.option}>
            <InputCheck checked={paymentWithCard} onChange={onChangeCredCard} />
            <p className={styles['payment-method']}>Cartão de crédito</p>
          </div>
          <div className={styles.option}>
            <InputCheck checked={!paymentWithCard} onChange={onChangeCredCard} />
            <p className={styles['payment-method']}>Boleto bancário</p>
          </div>
        </div>

        {paymentWithCard ? (
          <>
            {renderCardForm}
            <p className={styles.info}>
              * O seu acesso à plataforma será liberado após a confirmação do pagamento.
            </p>
          </>
        ) : (
          <>
            {renderPaymentSlip}
            <p className={styles.info}>
              * Após a emissão do seu boleto, o pagamento pode ser feito em até 5 dias úteis em
              qualquer agência bancária, lotérica ou Internet Banking. Caso o vencimento seja
              domingo ou feriado, o pagamento pode ser realizado no próximo dia útil. O seu acesso à
              plataforma será liberado após a confirmação do pagamento, que pode levar até 3 dias
              úteis.
            </p>
          </>
        )}
      </div>
    </div>
  )
}

PaymentOptions.propTypes = {
  setStep: PropTypes.func.isRequired,
  setDiscount: PropTypes.func.isRequired,
  setParcel: PropTypes.func.isRequired,
  parcels: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  discount: PropTypes.number,
  plan: PropTypes.shape({
    id: PropTypes.number,
    possibleParcels: PropTypes.number,
    discount: PropTypes.number,
    priceInCents: PropTypes.number,
  }).isRequired,
}

PaymentOptions.defaultProps = {
  parcels: null,
  discount: 0,
}
export default PaymentOptions
