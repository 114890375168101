import { createReducer } from '_utils/redux'

import { GET_PLAN_DETAILS, APPLY_COUPON, BUY_PLAN, PLANS_BOUGHT } from './actions'

const INITIAL_STATE = {
  plans: {},
  planDetails: {},
  paymentFeedback: {},
  plansBought: [],
}

const plans = createReducer(INITIAL_STATE, {
  [GET_PLAN_DETAILS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      planDetails: payload,
    }
  },
  [APPLY_COUPON.FULFILLED]: (state, { payload }) => {
    const { finalValue, discount } = payload
    return {
      ...state,
      planDetails: { ...state.planDetails, finalValue, discount },
    }
  },
  [BUY_PLAN.FULFILLED]: (state, { payload }) => {
    const boletoLink = payload?.paymentInfo?.links?.payBoleto?.printHref
    const boletoCode = payload?.paymentInfo?.info?.lineCode
    return {
      ...state,
      paymentFeedback: { link: boletoLink, boletoCode, payedWithCard: !boletoLink },
    }
  },
  [PLANS_BOUGHT.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      plansBought: payload,
    }
  },
})

export default plans
