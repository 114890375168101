import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const NotificationCard = ({ text, newNotification }) => {
  return <div className={classnames(styles.card, { [styles.new]: newNotification })}>{text}</div>
}

NotificationCard.propTypes = {
  text: PropTypes.string.isRequired,
  newNotification: PropTypes.bool,
}

NotificationCard.defaultProps = {
  newNotification: false,
}

export default React.memo(NotificationCard)
