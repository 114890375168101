import React, { useState, useCallback, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AriaModal from 'react-aria-modal'

import closeIcon from '_assets/icons/close.svg'

import styles from './styles.css'

const Modal = React.forwardRef(
  (
    {
      children,
      defaultVisibility,
      backgroundClassName,
      className,
      titleText,
      modelProps,
      hideCloseButton,
      ...otherProps
    },
    ref
  ) => {
    const [isVisible, setVisibility] = useState(defaultVisibility)
    const closeModal = useCallback(() => {
      setVisibility(false)
    }, [])

    useImperativeHandle(ref, () => ({
      setVisibility: value => {
        setVisibility(value)
      },
    }))

    return (
      <AriaModal
        mounted={isVisible}
        titleText={titleText}
        scrollDisabled={false}
        onExit={closeModal}
        {...modelProps}
      >
        <div className={classnames(styles.modal, className)} {...otherProps}>
          {!hideCloseButton && (
            <button className={styles['close-button']} type="button" onClick={closeModal}>
              <img src={closeIcon} alt="Botão para fechar a modal" />
            </button>
          )}
          {children}
        </div>
      </AriaModal>
    )
  }
)

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  defaultVisibility: PropTypes.bool,
  backgroundClassName: PropTypes.string,
  className: PropTypes.string,
  modelProps: PropTypes.shape(),
  titleText: PropTypes.string,
  hideCloseButton: PropTypes.bool,
}

Modal.defaultProps = {
  defaultVisibility: false,
  hideCloseButton: false,
  backgroundClassName: '',
  className: '',
  titleText: '',
  modelProps: {
    verticallyCenter: true,
    focusDialog: true,
  },
}

export default Modal
