import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { useSelector, useDispatch } from 'react-redux'
import addDays from 'date-fns/addDays'
import Loader from 'react-loader-spinner'

import CalendarTag from '_components/calendar/tag'
import { convertDateRequest, getResetToday } from '_utils/helpers'
import Arrow from '_assets/svgs/calendar-arrow.svg'
import Button, { BUTTON_THEMES } from '_components/button'
import { getStudyPlan } from '_modules/study-plan/actions'
import { getPlan, isGettingPlanLoading } from '_modules/study-plan/selectors'

import DisplayByDay from './display-by-day'
import CustomEventModal from './custom-event-modal'
import DayColumn from './day-column'
import styles from './styles.css'

const FORWARD = 'forward'
const PREVIOUS = 'previous'

const isSameDay = (dayOne, dayTwo) => {
  return (
    dayOne.getDate() === dayTwo.getDate() &&
    dayOne.getMonth() === dayTwo.getMonth() &&
    dayOne.getFullYear() === dayTwo.getFullYear()
  )
}

const StudyPlan = ({ showOnlyDay }) => {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const isGettingPlan = useSelector(isGettingPlanLoading)
  const currentPlan = useSelector(getPlan)
  const [currentDay, setCurrentDay] = useState(getResetToday())
  const [selectedEvent, setSelectedEvent] = useState(null)

  const currentRange = useMemo(
    () =>
      new Array(7)
        .fill(null)
        .map((_, index) => format(addDays(currentDay, -3 + index), 'yyyy-MM-dd')),
    [currentDay]
  )

  const isToday = useCallback(day => isSameDay(day, new Date()), [])

  const handleChangeDay = useCallback(
    ({ currentTarget: { name } }) => {
      const dateDiff = name === FORWARD ? 1 : -1
      setCurrentDay(prevState => addDays(prevState, dateDiff))

      if (!currentPlan.get(format(addDays(currentDay, dateDiff * 5), 'yyyy-MM-dd'))) {
        const startDate = name === FORWARD ? dateDiff : dateDiff * 10
        const endDate = name === FORWARD ? dateDiff * 10 : dateDiff
        dispatch(
          getStudyPlan({
            startDate: convertDateRequest(addDays(currentDay, startDate)),
            endDate: convertDateRequest(addDays(currentDay, endDate)),
          })
        )
      }
    },
    [currentDay, currentPlan, dispatch]
  )

  useEffect(() => {
    const todayTimestamp = new Date().getTime()
    const startDate = convertDateRequest(new Date(todayTimestamp + 24 * 60 * 60 * 1000 * -10))
    const endDate = convertDateRequest(new Date(todayTimestamp + 24 * 60 * 60 * 1000 * +10))

    dispatch(getStudyPlan({ startDate, endDate }))
  }, [dispatch])

  const handleAddCustomEvent = useCallback(
    () => modalRef.current && modalRef.current.setVisibility(true),
    [modalRef],
    []
  )

  return (
    <div className={styles.card}>
      {showOnlyDay ? (
        <DisplayByDay
          handleChangeDay={handleChangeDay}
          currentDay={currentDay}
          handleAddCustomEvent={handleAddCustomEvent}
          events={currentPlan.get(format(currentDay, 'yyyy-MM-dd'))?.events}
          isGettingPlan={isGettingPlan}
        />
      ) : (
        <>
          <div className={styles.content}>
            <button
              className={styles['step-button']}
              type="button"
              name={PREVIOUS}
              onClick={handleChangeDay}
              disabled={isGettingPlan}
            >
              <img alt="" src={Arrow} />
            </button>
            {currentRange.map(current => {
              const day = currentPlan.get(current)
              if (!day) {
                return <div key={current} />
              }
              return (
                <DayColumn
                  key={current}
                  day={day}
                  events={day?.events}
                  isSelected={isToday(day.date)}
                  name={day?.date.getTime()}
                  setSelectedEvent={setSelectedEvent}
                  selectedEvent={selectedEvent}
                />
              )
            })}
            <button
              className={styles['step-button']}
              type="button"
              name={FORWARD}
              onClick={handleChangeDay}
              disabled={isGettingPlan}
            >
              <img className={styles.rotate} alt="" src={Arrow} />
            </button>
          </div>
          {selectedEvent && (
            <div>
              <h2 className={styles.subtitle}>Detalhes do evento</h2>
              <CalendarTag data={selectedEvent} />
            </div>
          )}
          {isGettingPlan && (
            <div className={styles['loader-content']}>
              <Loader type="Oval" color="#ff4429" height="32px" width="32px" />
            </div>
          )}
          <Button
            className={styles.event}
            theme={BUTTON_THEMES.NO_BACKGROUND}
            onClick={handleAddCustomEvent}
            disabled={isGettingPlan}
          >
            + Adicionar evento
          </Button>
        </>
      )}
      <CustomEventModal ref={modalRef} />
    </div>
  )
}

StudyPlan.propTypes = {
  showOnlyDay: PropTypes.bool.isRequired,
}

export default React.memo(StudyPlan)
