import { createReducer } from '_utils/redux'

import {
  GET_ESSAY_THEMES,
  CREATE_ESSAY,
  SELECT_THEME,
  GET_ESSAYS,
  GET_ESSAY,
  DELETE_ESSAY,
} from './actions'

const INITIAL_STATE = {
  essays: [],
  themes: [],
  selectedTheme: {},
  currentEssay: {},
}

const essay = createReducer(INITIAL_STATE, {
  [GET_ESSAY_THEMES.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      themes: payload,
    }
  },
  [CREATE_ESSAY.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      essays: [...state.essays, { ...payload, createdAt: new Date() }],
    }
  },
  [SELECT_THEME]: (state, { payload }) => {
    return {
      ...state,
      selectedTheme: payload,
    }
  },
  [GET_ESSAYS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      essays: payload,
    }
  },
  [GET_ESSAY.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      currentEssay: payload,
    }
  },
  [DELETE_ESSAY.FULFILLED]: (state, { payload: essayId }) => {
    return {
      ...state,
      essays: state.essays.filter(({ id }) => id !== essayId),
    }
  },
})

export default essay
