import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'
import classnasmes from 'classnames'

import Modal from '_components/common/modal'
import Input from '_components/input'
import { usePrevious } from '_hooks/use-previous'
import { validatePassword, lottiesDefaultOptions } from '_utils/helpers'
import Button, { BUTTON_THEMES } from '_components/button'
import { resetPassword, RESET_PASSWORD } from '_modules/user/actions'
import { isResetPasswordLoadingSelector, resetPasswordErrorSelector } from '_modules/user/selectors'
import errorIllustration from '_assets/svgs/error.svg'
import forgotPasswordIllustration from '_assets/svgs/forgot-password.svg'
import sendingEmailAnimation from '_assets/lotties/sending-email.json'
import { ReactComponent as ResetPasswordSuccess } from '_assets/svgs/reset-password-success.svg'

import styles from './styles.css'

const ResetPasswordModal = React.forwardRef(({ defaultVisibility, token, uid }, ref) => {
  const error = useSelector(resetPasswordErrorSelector)
  const isLoading = useSelector(isResetPasswordLoadingSelector)
  const prevIsLoading = usePrevious(isLoading)
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('')
  const dispatch = useDispatch()

  const onChangePasswordValue = useCallback(({ target: { value } }) => setPasswordValue(value), [])
  const onChangeConfirmPasswordValue = useCallback(
    ({ target: { value } }) => setConfirmPasswordValue(value),
    []
  )

  const sendingEmailAnimationOptions = {
    ...lottiesDefaultOptions,
    loop: true,
    animationData: sendingEmailAnimation,
  }

  const resetRecoverPasswordError = useCallback(
    event => {
      event.preventDefault()
      setPasswordValue('')
      setConfirmPasswordValue('')
      dispatch({ type: RESET_PASSWORD.ACTION })
    },
    [dispatch]
  )

  const onSubmitRecoverPassword = useCallback(
    event => {
      event.preventDefault()
      dispatch(
        resetPassword({
          token,
          uid,
          newPassword1: passwordValue,
          newPassword2: confirmPasswordValue,
        })
      )
    },
    [confirmPasswordValue, dispatch, passwordValue, token, uid]
  )

  const closeModal = useCallback(() => ref.current && ref.current.setVisibility(false), [ref])

  const validateFields = useMemo(
    () => passwordValue === confirmPasswordValue && validatePassword(passwordValue),
    [confirmPasswordValue, passwordValue]
  )

  const renderInitial = useMemo(() => {
    return (
      <div className={styles['reset-password']}>
        <img className={styles.illustration} src={forgotPasswordIllustration} alt="" />
        <form className={styles.form} onSubmit={onSubmitRecoverPassword}>
          <h1 className={classnasmes(styles.title, styles.initial)}>Crie uma nova senha</h1>
          <Input
            placeholder="Crie uma nova senha com 6 caracteres ou mais"
            value={passwordValue}
            onChange={onChangePasswordValue}
            type="password"
            autoComplete="password"
            className={styles.input}
          />
          <Input
            placeholder="Confirme sua nova senha"
            value={confirmPasswordValue}
            onChange={onChangeConfirmPasswordValue}
            type="password"
            autoComplete="password"
          />
          <Button
            theme={BUTTON_THEMES.PRIMARY}
            className={styles.button}
            disabled={!validateFields}
          >
            Alterar senha
          </Button>
        </form>
      </div>
    )
  }, [
    confirmPasswordValue,
    onChangeConfirmPasswordValue,
    onChangePasswordValue,
    onSubmitRecoverPassword,
    passwordValue,
    validateFields,
  ])

  const renderLoading = useMemo(
    () => (
      <div className={styles['sending-passord']}>
        <div className={styles.container}>
          <div className={styles.lottie}>
            <Lottie options={sendingEmailAnimationOptions} height={250} />
          </div>
          <h1 className={styles.title}>Alterando sua senha...</h1>
          <p className={styles.description}>Aguarde um instante</p>
        </div>
      </div>
    ),
    [sendingEmailAnimationOptions]
  )

  const renderError = useMemo(
    () => (
      <div className={styles['reset-password']}>
        <img className={styles.illustration} src={errorIllustration} alt="" />
        <form className={styles.form} onSubmit={resetRecoverPasswordError}>
          <h1 className={styles.title}>Ops...</h1>
          <p className={styles.description}>
            Não foi possível alterar sua senha, por favor tente novamente.
          </p>
          <Button theme={BUTTON_THEMES.PRIMARY} className={styles.button}>
            Tentar novamente
          </Button>
        </form>
      </div>
    ),

    [resetRecoverPasswordError]
  )

  const rederSuccess = useMemo(() => {
    return (
      <div className={styles['reset-password']}>
        <div className={styles['success-illustration']}>
          <ResetPasswordSuccess />
        </div>
        <div className={styles.form}>
          <h1 className={classnasmes(styles.title, styles.success)}>Senha alterada com sucesso!</h1>
          <p className={styles.description}>
            Você criou uma nova senha. É com ela que você poderá acessar sua conta a partir de
            agora.
          </p>
          <Button
            type="button"
            theme={BUTTON_THEMES.PRIMARY}
            className={styles.button}
            onClick={closeModal}
          >
            Entrar na minha conta
          </Button>
        </div>
      </div>
    )
  }, [closeModal])

  const redenderModalContent = useMemo(() => {
    if (isLoading) return renderLoading
    if (error) return renderError
    if (prevIsLoading && !isLoading) return rederSuccess

    return renderInitial
  }, [isLoading, renderLoading, error, renderError, prevIsLoading, rederSuccess, renderInitial])

  return (
    <Modal
      ref={ref}
      className={styles.modal}
      titleText="Crie uma nova senha"
      defaultVisibility={defaultVisibility}
    >
      {redenderModalContent}
    </Modal>
  )
})

ResetPasswordModal.propTypes = {
  token: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  defaultVisibility: PropTypes.bool,
}

ResetPasswordModal.defaultProps = {
  defaultVisibility: false,
}

export default ResetPasswordModal
