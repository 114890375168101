import React, { useMemo, useCallback, useReducer, useState } from 'react'
import classnames from 'classnames'

import Modal from '_components/common/modal'
import { calculateFileSize } from '_utils/helpers'
import Button, { BUTTON_THEMES } from '_components/button'
import attachmentIllustration from '_assets/svgs/attachment.svg'
import successIllustration from '_assets/svgs/success.svg'
import { sendReport } from '_services/report'

import { UPDATE_FORM, reducer, INITIAL_STATE, DESCRIPTION, ERROR, ADD_FILE, FILE } from './reducer'
import styles from './styles.css'

const options = [
  {
    value: 'page_structure',
    description: 'Estrutura da página',
  },
  {
    value: 'video_playback',
    description: 'Reprodução de vídeo',
  },
  {
    value: 'exercises',
    description: 'Exercícios',
  },
  {
    value: 'related_classes',
    description: 'Aulas relacionadas',
  },
  {
    value: 'other',
    description: 'Outro',
  },
]

const ReportErrorModal = React.forwardRef((props, ref) => {
  const [hasSent, setSent] = useState(false)

  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)

  const validateFields = useMemo(() => formData[DESCRIPTION].length && formData[ERROR].length, [
    formData,
  ])

  const onInputChange = useCallback(({ target: { name, value } }) => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const onFileChange = useCallback(event => {
    dispatch({
      type: ADD_FILE,
      payload: event.target.files[0],
    })
  }, [])

  const onSubmit = useCallback(
    async event => {
      event.preventDefault()
      await sendReport({ ...formData, attachmentUrl: formData.file })
      setSent(true)
    },
    [formData]
  )

  const closeModal = useCallback(() => {
    setSent(false)
    dispatch({
      type: UPDATE_FORM,
      payload: INITIAL_STATE,
    })
    return ref.current && ref.current.setVisibility(false)
  }, [ref])

  const renderOptions = useMemo(
    () =>
      options.map(({ value, description }) => (
        <React.Fragment key={value}>
          <input type="radio" id={value} value={value} name={ERROR} onChange={onInputChange} />
          <label
            htmlFor={value}
            className={classnames(styles['radio-label'], {
              [styles.selected]: value === formData[ERROR],
            })}
          >
            {description}
          </label>
        </React.Fragment>
      )),
    [formData, onInputChange]
  )

  const renderFiles = useMemo(() => {
    if (!formData[FILE]) return null

    return (
      <div className={styles.files}>
        <p>
          <span>{formData[FILE].name}</span>
          {` (${calculateFileSize(formData[FILE].size)})`}
        </p>
      </div>
    )
  }, [formData])

  const renderInitial = useMemo(() => {
    return (
      <div className={styles['report-error']}>
        <form className={styles.form} onSubmit={onSubmit}>
          <h1 className={styles.title}>Reportar erro</h1>
          <p className={styles.description}>
            A equipe do Pró Online está sempre buscando corrigir eventuais erros para lhe oferecer
            uma boa experiência na plataforma.
            <br />
            <span>Onde você detectou o erro?</span>
          </p>
          <div className={styles['radio-group']}>{renderOptions}</div>
          <textarea
            className={styles['text-area']}
            name="description"
            onChange={onInputChange}
            placeholder="Descreva o erro encontrado"
          />
          {renderFiles}
          <label htmlFor={FILE} className={styles['inputfile-label']}>
            <input
              type={FILE}
              name={FILE}
              id={FILE}
              className={styles.inputfile}
              onChange={onFileChange}
              accept="image/*"
            />
            <img src={attachmentIllustration} alt="Icone de Anexo" className={styles.attachment} />
            Adicionar anexo
          </label>
          <div className={styles.buttons}>
            <Button theme={BUTTON_THEMES.OUTLINE} className={styles.button} onClick={closeModal}>
              Cancelar
            </Button>
            <Button
              theme={BUTTON_THEMES.GRADIENT}
              className={styles.button}
              disabled={!validateFields}
            >
              Enviar
            </Button>
          </div>
        </form>
      </div>
    )
  }, [
    closeModal,
    onFileChange,
    onInputChange,
    onSubmit,
    renderFiles,
    renderOptions,
    validateFields,
  ])

  const rederSuccess = useMemo(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: INITIAL_STATE,
    })
    return (
      <div className={styles.success}>
        <img alt="Sucesso" src={successIllustration} />
        <h1 className={styles.title}>Obrigado!</h1>
        <p className={styles.description}>Seu feedback foi enviado com sucesso!</p>
        <Button theme={BUTTON_THEMES.GRADIENT} className={styles.button} onClick={closeModal}>
          Fechar
        </Button>
      </div>
    )
  }, [closeModal])

  const redenderModalContent = useMemo(() => {
    if (hasSent) return rederSuccess

    return renderInitial
  }, [hasSent, rederSuccess, renderInitial])

  return (
    <Modal ref={ref} className={styles.modal} titleText="Reportar erro">
      {redenderModalContent}
    </Modal>
  )
})

export default React.memo(ReportErrorModal)
