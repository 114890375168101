import { combineReducers } from 'redux'

import user from './user/reducer'
import error from './error/reducer'
import loading from './loading/reducer'
import notifications from './notifications/reducer'
import subjects from './subjects/reducer'
import profile from './profile/reducer'
import studyPlan from './study-plan/reducer'
import lesson from './lesson/reducer'
import mockExams from './mock-exams/reducer'
import plans from './plans/reducer'
import materials from './materials/reducer'
import testimonials from './testimonials/reducer'
import essay from './essay/reducer'

const rootReducer = combineReducers({
  user,
  error,
  loading,
  notifications,
  subjects,
  profile,
  studyPlan,
  lesson,
  mockExams,
  plans,
  materials,
  testimonials,
  essay,
})

export default rootReducer
