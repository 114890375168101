import { get, post, del } from './requests'

export const hasPlan = key => get(['study-plan', 'has-plan'], { key, transformPayload: true })

export const generateStudyPlan = (key, payload) =>
  post(['study-plan'], { key, transformPayload: true }, payload)

export const getUniversities = key => get(['universities'], { key, transformPayload: true })

export const getCourses = (key, universityId) =>
  get(['universities', universityId, 'courses'], { key, transformPayload: true })

export const getStudyPlan = (key, payload) =>
  get(['study-plan', 'my'], {
    key,
    params: { start_date: payload.startDate, end_date: payload.endDate },
    transformPayload: true,
  })

export const createCustomEvent = (key, payload) =>
  post(['custom-events'], { key, transformPayload: true }, payload)

export const getEventsByDay = (key, payload) =>
  get(['study-plan', 'day'], { key, transformPayload: true, params: { day: payload } })

export const deletePlan = key => del(['study-plan', 'erase'], { key })

export const deletePlanAndHistory = key => del(['study-plan', 'clear'], { key })
