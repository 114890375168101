import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { ROUTES } from '_utils/constants'
import Menu from '_assets/svgs/menu.svg'
import ArrowRight from '_assets/svgs/arrow-right.svg'
import ArrowLeft from '_assets/svgs/arrow-left.svg'
import Logo from '_assets/svgs/white-logo.svg'
import LogoIcon from '_assets/svgs/white-logo-icon.svg'
import LogoutIcon from '_assets/svgs/icon-logout.svg'
import { logout } from '_modules/user/actions'
import NotificationsIcon from '_assets/svgs/icon-notifications.svg'
import { getNotificationsCountSelector } from '_modules/notifications/selectors'

import { courseButtons, userButtons } from './constants'
import BarItem from './bar-item'
import styles from './styles.css'

const Bar = ({ isOpen, setIsOpen, pathname }) => {
  const dispatch = useDispatch()

  const handleMenu = useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [setIsOpen])

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch])

  const notificationsCount = useSelector(getNotificationsCountSelector)

  return (
    <div className={styles.bar}>
      <div className={classnames(styles.menu, { [styles.close]: !isOpen })}>
        {isOpen ? (
          <img alt="Logotipo" src={Logo} className={styles.logo} />
        ) : (
          <img alt="Logotipo" src={LogoIcon} className={styles.icon} />
        )}
        <div className={styles.scroll}>
          <div className={styles['menu-buttons']}>
            {isOpen && <span className={styles.category}>MEUS ESTUDOS</span>}
            {courseButtons.map(({ id, link, icon, label, subMenu }) => (
              <BarItem
                key={id}
                id={id}
                link={link}
                icon={icon}
                pathname={pathname}
                label={label}
                subMenu={subMenu}
                isMenuOpen={isOpen}
                toggle={handleMenu}
              />
            ))}
          </div>
          <div className={styles['menu-buttons']}>
            {isOpen && <span className={styles.category}>MINHA CONTA</span>}
            <Link className={classnames(styles['menu-option'])} to={ROUTES.NOTIFICATIONS}>
              <img
                alt="Icone de Notificações"
                src={NotificationsIcon}
                className={styles['notification-icon']}
              />
              {isOpen && <span className={styles['menu-label']}>Notificações</span>}
              {notificationsCount > 0 && (
                <div className={styles.notification}>
                  <span className={styles.number}>{notificationsCount}</span>
                </div>
              )}
            </Link>
            {userButtons.map(({ id, link, icon, label, subMenu }) => (
              <BarItem
                id={id}
                key={id}
                link={link}
                icon={icon}
                pathname={pathname}
                label={label}
                subMenu={subMenu}
                isMenuOpen={isOpen}
              />
            ))}
          </div>
          <button
            className={classnames(styles['menu-option'], styles.logout)}
            type="button"
            onClick={handleLogout}
          >
            <img alt="Icone de Logout" src={LogoutIcon} />
            {isOpen && <span className={styles['menu-label']}>Sair</span>}
          </button>
        </div>
      </div>
      <div className={styles['button-content']}>
        <button className={styles.button} type="button" onClick={handleMenu}>
          <img className={styles.img} alt="" src={Menu} />
          {isOpen ? (
            <img className={styles.arrow} alt="" src={ArrowLeft} />
          ) : (
            <img className={styles.arrow} alt="" src={ArrowRight} />
          )}
        </button>
      </div>
    </div>
  )
}

Bar.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  pathname: PropTypes.string,
}

Bar.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  pathname: '',
}

export default React.memo(Bar)
