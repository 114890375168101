import React, { useEffect, useMemo, useState, useCallback } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import addMonths from 'date-fns/addMonths'
import { Link } from '@reach/router'

import { ReactComponent as PlanMan } from '_assets/svgs/plan-man.svg'
import { ReactComponent as BasicPlan } from '_assets/svgs/basic-plan.svg'
import { ReactComponent as Recommended } from '_assets/svgs/recommended.svg'
import { getUserSelector } from '_modules/user/selectors'
import Loader from '_components/loader'
import Breadcrumb from '_components/breadcrumb'
import { ROUTES } from '_utils/constants'
import { plansBought } from '_modules/plans/actions'
import {
  getPlansBoughtSelector,
  getPlanActiveSelector,
  isGettingPlansSelector,
} from '_modules/plans/selectors'
import { planPro } from '_utils/helpers'

import styles from './styles.css'

const HEADER_COLUMNS = [
  { id: 1, label: 'Cód.' },
  { id: 2, label: 'Plano' },
  { id: 4, label: 'Data' },
  { id: 5, label: 'Status' },
]

const STATUS_OPTIONS = {
  PAID: 'Aprovado',
  WAITING: 'Aguardando Pgto',
  IN_ANALYSIS: 'Em análise',
  PAYMENT_CREATED: 'Em análise',
  CREATED: 'Em análise',
  PRE_AUTHORIZED: 'Aprovado',
  AUTHORIZED: 'Aprovado',
  SETTLED: 'Aprovado',
  REFUNDED: 'Extorno',
  REVERSED: 'Extorno',
  REVERTED: 'Extorno',
  CANCELLED: 'Cancelado',
  NOT_PAiD: 'Não pago',
}

const formatDate = date => {
  if (!date) return ''
  return format(new Date(date), 'dd/MM/yyyy')
}

const PAYMENTS_TYPE_OPTIONS = {
  CREDIT_CARD: 'Cartão de crédito',
  BOLETO: 'Boleto',
}

const Payments = () => {
  const dispatch = useDispatch()

  const [isFirstPlanOpen, setFirstPlanOpen] = useState(false)
  const [isSecondPlanOpen, setSecondPlanOpen] = useState(false)
  const [selectValue, setSelectValue] = useState('12')

  const toggleFirstPlan = useCallback(() => {
    setFirstPlanOpen(prev => !prev)
  }, [])

  const toggleSecondPlan = useCallback(() => {
    setSecondPlanOpen(prev => !prev)
  }, [])

  const handleSelect = useCallback(({ target: { value } }) => {
    setSelectValue(value)
  }, [])

  const plans = useSelector(getPlansBoughtSelector)
  const activePlan = useSelector(getPlanActiveSelector)
  const isGettingPlan = useSelector(isGettingPlansSelector)
  const isTrial = useSelector(getUserSelector)?.onFreeTrial
  const startPlanDate = formatDate(activePlan?.updatedAt)
  const endPlanDate = useMemo(() => {
    if (activePlan?.id) {
      return format(
        new Date(
          addMonths(new Date(activePlan?.updatedAt), activePlan.accessDurationMonths)?.toISOString()
        ),
        'dd/MM/yyyy'
      )
    }
    return ''
  }, [activePlan])

  const planActiveType = activePlan.paymentType ? PAYMENTS_TYPE_OPTIONS[activePlan.paymentType] : ''

  useEffect(() => {
    dispatch(plansBought())
  }, [dispatch])

  const renderCreditCardData = useCallback(() => {
    if (
      planActiveType === PAYMENTS_TYPE_OPTIONS.CREDIT_CARD &&
      activePlan?.payments[0].creditCardFlag &&
      activePlan?.payments[0].paymentLastFour
    ) {
      return (
        <p
          className={styles['card-text']}
        >{`${activePlan.payments[0].creditCardFlag.toUpperCase()} **** ${
          activePlan.payments[0].paymentLastFour
        }`}</p>
      )
    }
    return ''
  }, [activePlan, planActiveType])

  return (
    <div className={styles.container}>
      <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
      <div className={styles['title-content']}>
        <h1 className={styles.title}>Pagamentos</h1>
      </div>
      {!isGettingPlan && (!activePlan?.id || isTrial) && (
        <div className={styles.card}>
          <h2 className={classnames(styles.title, styles.subtitle)}>
            Escolha o melhor plano para você
          </h2>
          <div className={styles.plans}>
            <div className={styles.plan}>
              <Recommended className={styles.recommended} />
              <div className={styles.wrapper}>
                <div
                  className={classnames(styles['card-container'], {
                    [styles.open]: isFirstPlanOpen,
                  })}
                >
                  <PlanMan
                    className={classnames(styles['plan-man'], { [styles.open]: isFirstPlanOpen })}
                  />
                  <h2 className={styles['plan-title']}>Plano Pró</h2>
                  <p className={styles.description}>
                    Monitorias e aulas ao vivo toda semana, + de 2 mil aulas e 2 correções de
                    redação por mês
                  </p>
                  <hr />
                  <div className={styles.info}>
                    <span>Acesso por</span>
                    <span className={styles.parcels}>{`${selectValue}x 20,99`}</span>
                    <select
                      name="plan-type"
                      className={styles.months}
                      value={selectValue}
                      onChange={handleSelect}
                    >
                      <option value="12">12 meses</option>
                      <option value="6">6 meses</option>
                      <option value="3">3 meses</option>
                    </select>
                    <p className={styles.price}>
                      {`${selectValue}x `}
                      <span>
                        R${' '}
                        {(planPro.parcelsOptions[selectValue].price / Number(selectValue))
                          .toFixed(2)
                          .replace('.', ',')}
                      </span>
                    </p>
                  </div>
                  <Link
                    className={styles.button}
                    to={`/cadastro/${planPro.parcelsOptions[selectValue].id}`}
                  >
                    Assine já!
                  </Link>
                  <button className={styles['details-button']} onClick={toggleFirstPlan}>
                    {isFirstPlanOpen ? 'Ocultar detalhes' : 'Ver detalhes'}
                  </button>
                  <div className={classnames(styles.details, { [styles.open]: isFirstPlanOpen })}>
                    <p>» Curso com mais de 2 mil aulas</p>
                    <p>» Apostila para ENEM e vestibulares</p>
                    <p>» 2 correções de redação por mês</p>
                    <p>» Aulas ao vivo toda semana</p>
                    <p>» Simulados ENEM e vestibulares</p>
                    <p>» Atualidades e temas de redação</p>
                    <p>» Planos de estudos personalizados</p>
                    <p>» Monitorias ao vivo</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classnames(styles.plan, styles['basic-plan'])}>
              <div className={styles.wrapper}>
                <div
                  className={classnames(styles['card-container'], {
                    [styles.open]: isSecondPlanOpen,
                  })}
                >
                  <BasicPlan
                    className={classnames(styles['basic-plan-illustration'], {
                      [styles.open]: isSecondPlanOpen,
                    })}
                  />
                  <h2 className={styles['plan-title']}>Plano Básico</h2>
                  <p className={styles.description}>
                    Um mês de acesso à plataforma com mais de mil aulas e uma correção de redação.
                  </p>
                  <hr />
                  <div className={styles.info}>
                    <span>Acesso por 1 mês</span>
                    <p className={styles.price}>
                      <span> R$ 20,99</span>
                    </p>
                  </div>
                  <Link className={styles.button} to="/cadastro/37">
                    Assine já!
                  </Link>
                  <button className={styles['details-button']} onClick={toggleSecondPlan}>
                    {isSecondPlanOpen ? 'Ocultar detalhes' : 'Ver detalhes'}
                  </button>
                  <div className={classnames(styles.details, { [styles.open]: isSecondPlanOpen })}>
                    <p>» Curso com mais de mil aulas</p>
                    <p>» 1 correção de redação</p>
                    <p>» Simulados ENEM e vestibulares</p>
                    <p>» Planos de estudos personalizados</p>
                    <p>» Apostila para ENEM e vestibulares</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.card}>
        {isGettingPlan ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <h2 className={classnames(styles.title, styles.subtitle)}>Histórico de pagamentos</h2>
            <table className={styles.table}>
              <thead>
                <tr className={styles.row}>
                  {HEADER_COLUMNS.map(column => (
                    <th
                      className={classnames(styles['column-header'], styles.content, {
                        [styles.status]: column.label === 'Status',
                      })}
                      key={column.id}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {plans.map(plan => (
                  <tr key={plan.id}>
                    <td align="left" className={classnames(styles.data, styles.content)}>
                      {plan.id}
                    </td>
                    <td align="left" className={classnames(styles.data, styles.content)}>
                      {plan.name}
                    </td>
                    <td align="left" className={classnames(styles.data, styles.content)}>
                      {formatDate(plan.createdAt)}
                    </td>
                    <td
                      align="right"
                      className={classnames(styles.data, styles.content, styles.status)}
                    >
                      {STATUS_OPTIONS[plan.status] || ' - '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {activePlan?.id && (
              <>
                {planActiveType && (
                  <section className={styles['user-content']}>
                    <h1 className={classnames(styles.title, styles.subtitle)}>
                      Forma de pagamento
                    </h1>
                    <h2 className={classnames(styles['card-text'], styles.highlight)}>
                      {planActiveType}
                    </h2>
                    {renderCreditCardData()}
                    <p className={styles['card-text']}>
                      {activePlan?.payments[0]?.price
                        ? `Valor pago: R$: ${(Number(activePlan?.payments[0]?.price) / 100).toFixed(
                            2
                          )} `
                        : `Valor pago: R$: 0,00`}
                    </p>
                  </section>
                )}
                <section className={styles['user-content']}>
                  <h1 className={classnames(styles.title, styles.subtitle)}>Plano ativo:</h1>
                  <h2 className={classnames(styles['card-text'], styles.highlight)}>
                    {activePlan?.plan.name}
                  </h2>
                  <p className={styles['card-text']}>{`Acesso por ${
                    activePlan?.accessDurationMonths
                  } ${activePlan?.accessDurationMonths === 1 ? 'mês' : 'meses'}`}</p>
                  <p className={styles['card-text']}>{`Data compra: ${startPlanDate}`}</p>
                  <p className={styles['card-text']}>{`Vencimento do plano: ${endPlanDate}`}</p>
                </section>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Payments
