import React, { useReducer, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import {
  adrressLoadingSelector,
  getAddressSelector,
  isUpdatingProfile,
  updatingProfileError,
} from '_modules/profile/selectors'
import Alert, { ALERT_THEMES } from '_components/alert'
import Button, { BUTTON_THEMES } from '_components/button'
import Input from '_components/input'
import { getAddress, updateProfile } from '_modules/profile/actions'
import { getProfileData } from '_modules/user/actions'
import { getProfileDataSelector, getUserSelector } from '_modules/user/selectors'
import { validateCpf, validateEmail } from '_utils/helpers'
import { usePrevious } from '_hooks/use-previous'

import styles from './styles.css'
import {
  INITIAL_STATE,
  reducer,
  UPDATE_FORM,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  BIRTH_DATE,
  CPF,
  PHONE_NUMBER,
  CEP,
  CITY,
  STATE,
  ADDRESS,
  ADDRESS_COMPLEMENT,
  NEIGHBORHOOD,
  ADDRESS_NUMBER,
} from './reducer'

const convertDateRequest = date => {
  const currentDate = parse(date, 'dd/MM/yyyy', new Date())
  return format(currentDate, 'yyyy-MM-dd')
}
const GeneralInformation = ({ setStep }) => {
  const [formData, dispatch] = useReducer(reducer, INITIAL_STATE)
  const address = useSelector(getAddressSelector)
  const dispatchRequest = useDispatch()
  const user = useSelector(getProfileDataSelector)
  const userId = useSelector(getUserSelector)?.id
  const isProfileUpdating = useSelector(isUpdatingProfile)
  const wasUpdatingProfile = usePrevious(isProfileUpdating)
  const updateProfileError = useSelector(updatingProfileError)
  const getAdrressLoading = useSelector(adrressLoadingSelector)

  useEffect(() => {
    dispatchRequest(getProfileData())
  }, [dispatchRequest])

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target

      dispatch({
        type: UPDATE_FORM,
        payload: {
          [name]: value,
        },
      })
    },
    [dispatch]
  )

  const cepFormated = useMemo(() => formData[CEP].replace(/[^0-9]/g, ''), [formData])

  const wrongCep = useMemo(() => {
    if (cepFormated.length >= 8 && !getAdrressLoading) {
      if (address.address || address.stateInitials || address.city) {
        return ''
      }
      return 'Digite um CEP válido'
    }
    return ''
  }, [cepFormated.length, getAdrressLoading, address.address, address.stateInitials, address.city])

  const wrongEmail = useMemo(() => {
    if (!validateEmail(formData[EMAIL])) {
      return 'Digite um email válido'
    }
    return false
  }, [formData])

  const wrongBirthDate = useMemo(() => {
    const isDateValid = isValid(parse(formData[BIRTH_DATE], 'dd/MM/yyyyy', new Date()))
    if (isDateValid) {
      return ''
    }
    return 'Data de nascimento inválida'
  }, [formData])

  const errorCpf = useMemo(
    () =>
      formData[CPF] && !validateCpf(formData[CPF]) && formData[CPF].indexOf(' ') === -1
        ? 'CPF inválido'
        : '',

    [formData]
  )

  const disableButton = useMemo(
    () =>
      !formData[EMAIL] ||
      wrongEmail ||
      !validateCpf(formData[CPF]) ||
      !isValid(parse(formData[BIRTH_DATE], 'dd/MM/yyyyy', new Date())) ||
      !formData[ADDRESS_NUMBER] ||
      !formData[ADDRESS] ||
      !formData[NEIGHBORHOOD] ||
      !formData[CITY] ||
      !formData[STATE],
    [formData, wrongEmail]
  )

  useEffect(() => {
    if (cepFormated.length >= 8) {
      dispatchRequest(getAddress(cepFormated))
    }
  }, [cepFormated, dispatchRequest])

  useEffect(() => {
    if (user?.email) {
      Object.keys(user).forEach(key => {
        if (key === 'phoneNumber') {
          dispatch({
            type: UPDATE_FORM,
            payload: {
              [key]: `(${user.phoneAreaCode}) ${user[key]}`,
            },
          })
        } else if (user[key])
          dispatch({
            type: UPDATE_FORM,
            payload: {
              [key]: user[key],
            },
          })
      })
    }
  }, [user])

  useEffect(() => {
    if (Object.values(address).length) {
      Object.keys(address).forEach(key =>
        dispatch({
          type: UPDATE_FORM,
          payload: {
            [key]: address[key],
          },
        })
      )
    }
  }, [address])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      const phone = formData[PHONE_NUMBER].split(' ')
      const payload = {
        ...formData,
        birthDate: convertDateRequest(formData[BIRTH_DATE]),
        phoneAreaCode: phone[0].replace(/[{()}]/g, ''),
        phoneNumber: phone[1],
      }
      if (typeof payload.profilePicture === 'string') {
        delete payload.profilePicture
      }
      dispatchRequest(updateProfile(userId, payload))
    },
    [formData, userId, dispatchRequest]
  )

  useEffect(() => {
    if (wasUpdatingProfile && !isProfileUpdating) {
      if (!updateProfileError) {
        setStep(1)
      } else {
        toast(<Alert theme={ALERT_THEMES.ERROR} message="Erro ao tentar salvar dados" />)
      }
    }
  }, [wasUpdatingProfile, isProfileUpdating, updateProfileError, setStep])

  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Pagamento</h1>
      <div className={styles.card}>
        <h2 className={styles.subtitle}>Dados pessoais</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            placeholder="Nome"
            autoComplete={FIRST_NAME}
            onChange={onInputChange}
            className={styles.input}
            name={FIRST_NAME}
            highlight={false}
            value={formData[FIRST_NAME]}
          />
          <Input
            placeholder="Sobrenome"
            autoComplete={LAST_NAME}
            onChange={onInputChange}
            className={styles.input}
            name={LAST_NAME}
            highlight={false}
            value={formData[LAST_NAME]}
          />
          <Input
            placeholder="Email"
            autoComplete={EMAIL}
            onChange={onInputChange}
            className={styles.input}
            name={EMAIL}
            highlight={false}
            value={formData[EMAIL]}
            disabled
          />
          <Input
            placeholder="Data de nascimento"
            autoComplete={BIRTH_DATE}
            onChange={onInputChange}
            className={styles.input}
            name={BIRTH_DATE}
            highlight={false}
            error={!wrongBirthDate}
            errorText={wrongBirthDate}
            mask="99/99/9999"
            value={formData[BIRTH_DATE] || ''}
          />
          <Input
            placeholder="CPF"
            autoComplete={CPF}
            onChange={onInputChange}
            className={styles.input}
            name={CPF}
            error={!!errorCpf}
            errorText={errorCpf}
            highlight={false}
            mask="999.999.999-99"
            value={formData[CPF] || ''}
          />
          <Input
            placeholder="Celular"
            autoComplete={PHONE_NUMBER}
            onChange={onInputChange}
            className={styles.input}
            name={PHONE_NUMBER}
            value={formData[PHONE_NUMBER] || ''}
            highlight={false}
            mask="(99) 99999-9999"
          />
          <Input
            placeholder="CEP"
            autoComplete={CEP}
            onChange={onInputChange}
            className={styles.input}
            name={CEP}
            value={formData[CEP]}
            highlight={false}
            mask="99999-999"
            error={!!wrongCep}
            errorText={wrongCep}
          />
          <Input
            placeholder="Endereço"
            highlight={false}
            name={ADDRESS}
            className={styles.input}
            onChange={onInputChange}
            value={formData[ADDRESS]}
          />
          <div className={styles['double-column']}>
            <Input
              placeholder="Número"
              type="number"
              autoComplete={ADDRESS_NUMBER}
              className={styles.input}
              onChange={onInputChange}
              name={ADDRESS_NUMBER}
              highlight={false}
              value={formData[ADDRESS_NUMBER]}
            />
            <Input
              placeholder="Complemento"
              autoComplete={ADDRESS_COMPLEMENT}
              onChange={onInputChange}
              className={styles.input}
              name={ADDRESS_COMPLEMENT}
              highlight={false}
              value={formData[ADDRESS_COMPLEMENT]}
            />
          </div>
          <Input
            placeholder="Bairro"
            highlight={false}
            onChange={onInputChange}
            className={styles.input}
            value={formData[NEIGHBORHOOD]}
            name={NEIGHBORHOOD}
          />
          <div className={styles['double-column']}>
            <Input
              placeholder="Estado"
              highlight={false}
              onChange={onInputChange}
              value={formData[STATE]}
              name={STATE}
              maxLength={2}
              className={styles.input}
            />
            <Input
              placeholder="Cidade"
              highlight={false}
              onChange={onInputChange}
              value={formData[CITY]}
              name={CITY}
              className={styles.input}
            />
          </div>
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.PINK_NORMAL}
            disabled={disableButton}
            type="submit"
            isLoading={isProfileUpdating}
          >
            Confirmar dados
          </Button>
        </form>
      </div>
    </div>
  )
}

GeneralInformation.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default GeneralInformation
