import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import classnames from 'classnames'

import { formattedTime } from '_utils/helpers'

import styles from './styles.css'

const VideoPlaylist = ({ title, videos, className, theme }) => (
  <div className={classnames(styles.container, className)}>
    <p className={styles.title}>{title}</p>
    {videos.length ? (
      videos.map(video => (
        <Link
          className={styles.content}
          key={video.id}
          to={`/disciplinas/${video?.topic?.subject?.id}/assuntos/${video?.topic?.id}/aula/${video.id}`}
        >
          <img className={styles.thumbnail} src={video.thumbnailUrl} alt="Thumbnail" />
          <div className={styles.info}>
            <p className={styles.video}>{video.name}</p>

            <div className={styles.data}>
              <p className={styles.time}>
                {video.progress
                  ? `${formattedTime(
                      ((100 - video.progress) / 100) * video.durationSeconds
                    )} restantes`
                  : formattedTime(video.durationSeconds)}
              </p>
              <div className={styles.bars}>
                <div
                  className={classnames(styles.progress, styles[`theme-${theme}`])}
                  style={{ width: `${video.progress}%` }}
                />
                <div className={styles.background} />
              </div>
            </div>
          </div>
        </Link>
      ))
    ) : (
      <div className={styles['no-content']}>
        <p className={styles['empty-list']}>
          Este tópico não tem mais vídeos!
          <span role="img" className={styles.emoji} aria-label="Emoji">
            &#128517;
          </span>
        </p>
        <Link to="../../../../" className={styles.link}>
          Voltar para lista de tópicos
        </Link>
      </div>
    )}
  </div>
)

VideoPlaylist.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.shape()),
}

VideoPlaylist.defaultProps = {
  title: 'Continue assistindo:',
  className: null,
  videos: [],
  theme: null,
}

export default React.memo(VideoPlaylist)
