import { GET_MATERIALS } from './actions'

export const getMaterialsSubjectSelector = ({ materials }) =>
  materials?.subjects?.map(subject => ({
    value: subject.id,
    label: subject.title,
    type: subject.type,
  }))

export const getMaterialSelector = ({ materials }) => materials?.currentMaterial

export const isGettingMaterialSelector = ({ loading }) => !!loading[GET_MATERIALS.ACTION]
export const getMaterialError = ({ error }) => error[GET_MATERIALS.ACTION]
