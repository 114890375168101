import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from '../modules/reducers'

const configureStore = preloadedState => {
  const middlewareList = [thunk, promise]

  const applyedMiddlewares =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(applyMiddleware(...middlewareList, logger))
      : applyMiddleware(...middlewareList)

  return createStore(rootReducer, preloadedState, applyedMiddlewares)
}

export default configureStore
