import React from 'react'
import { Router as ReachRouter, Redirect } from '@reach/router'

import Dashboard from '_views/dashboard'
import Subjects from '_views/subjects'
import Topics from '_views/topics'
import Login from '_views/login'
import NotFoundPage from '_views/not-found'
import App from '_views/app'
import Lesson from '_views/lesson'
import Notifications from '_views/notifications'
import Profile from '_views/profile'
import Calendar from '_views/calendar'
import LiveLesson from '_views/live-lesson'
import Materials from '_views/materials'
import Payments from '_views/payments'
import Home from '_views/home'
import MockExams from '_views/mock-exams'
import NewExam from '_views/new-exam'
import AnswerExam from '_views/answer-exam'
import ExamResult from '_views/exam-result'
import BuyPlan from '_views/buy-plan'
import Essay from '_views/essay'
import EssayResult from '_views/essay-result'
import EssayThemes from '_views/essay/themes'
import TokenAccess from '_views/token-access'
import CreateEssay from '_views/essay/create-essay'

const Router = () => (
  <ReachRouter primary={false}>
    <Redirect from="/index.html" to="/" noThrow />
    <Home path="/" />
    <Login path="login" />
    <Login path="login/:planId" />
    <Login path="cadastro" />
    <Login path="cadastro/:planId" />
    <Login path="recuperar-senha/:uid/:token" />
    <BuyPlan path="comprar-plano/:planId" />
    <TokenAccess path="dashboard/:token" />
    <App path="/">
      <Dashboard path="dashboard" />
      <Notifications path="notificacoes" />
      <Subjects path="disciplinas" />
      <Topics path="disciplinas/:subjectId" />
      <Lesson path="disciplinas/:subjectId/assuntos/:topicId/aula/:lessonId" />
      <LiveLesson path="monitoria/:lessonId" />
      <LiveLesson path="aula-ao-vivo/:lessonId" />
      <Profile path="/meu-perfil" />
      <Calendar path="/calendario" />
      <Materials path="/material-didatico/enem" />
      <Materials path="/material-didatico/vestibular" />
      <Payments path="/pagamentos" />
      <MockExams path="simulados/:examType" />
      <NewExam path="simulados/:examType/novo" />
      <AnswerExam path="simulados/:examType/prova/:selectedUniversityId/edicao/:examId/area/:areaId" />
      <ExamResult path="simulados/:examType/resultado/:examId" />
      <Essay path="redacao" />
      <EssayThemes path="redacao/temas" />
      <CreateEssay path="redacao/temas/:pdfId" />
      <EssayResult path="redacao/resultado/:essayId" />
    </App>
    <NotFoundPage default />
  </ReachRouter>
)

export default Router
