import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '_components/common/modal'
import Button, { BUTTON_THEMES } from '_components/button'
import { isDeletingEssaySelector } from '_modules/essay/selectors'
import { deleteEssay } from '_modules/essay/actions'

import styles from './styles.css'

const DeleteEssayModal = forwardRef(({ essayId }, ref) => {
  const closeModal = useCallback(() => ref?.current.setVisibility(false), [ref])
  const dispatch = useDispatch()
  const isDeletingEssay = useSelector(isDeletingEssaySelector)

  const onDelete = useCallback(() => dispatch(deleteEssay(essayId, closeModal)), [
    closeModal,
    dispatch,
    essayId,
  ])

  return (
    <Modal ref={ref} titleText="Excluir Redação" className={styles.modal} hideCloseButton>
      <h1 className={styles.title}>Excluir redação</h1>
      <p className={styles.comment}>
        Tem certeza que deseja cancelar o envio de sua redação? Todas as informações salvas serão
        perdidas.
      </p>
      <div className={styles.buttons}>
        <Button theme={BUTTON_THEMES.NO_BACKGROUND} className={styles.button} onClick={closeModal}>
          Voltar
        </Button>
        <Button
          theme={BUTTON_THEMES.PINK_NORMAL}
          className={styles.button}
          onClick={onDelete}
          isLoading={isDeletingEssay}
        >
          Excluir
        </Button>
      </div>
    </Modal>
  )
})

DeleteEssayModal.propTypes = {
  essayId: PropTypes.number,
}

DeleteEssayModal.defaultProps = {
  essayId: 0,
}

export default DeleteEssayModal
