import { getActionName } from '_utils/redux'

export const INITIAL_STATE = {}

const isPending = actionType => actionType.toString().includes('PENDING')

const isResponse = actionType =>
  actionType.toString().includes('FULFILLED') || actionType.toString().includes('REJECTED')

export default (state = INITIAL_STATE, action) => {
  const actionName = getActionName(action.type)
  const actionCount = `${actionName}_COUNT`

  const count = state[actionCount] || 0

  if (isPending(action.type)) {
    return { ...state, [actionCount]: count + 1, [actionName]: count + 1 > 0 }
  }

  if (isResponse(action.type)) {
    return { ...state, [actionCount]: count - 1, [actionName]: count - 1 > 0 }
  }
  return state
}
