import { createReducer, LOGOUT } from '_utils/redux'

import {
  GET_LIVE_LESSON,
  GET_MONITORING,
  RATE_MONITORING,
  GET_LESSON,
  RATE_LESSON,
  ANSWER_QUESTION,
  GET_RELATED_LESSONS,
} from './actions'

const INITIAL_STATE = {
  liveLesson: {},
  monitoring: {},
  subjectLesson: {
    questions: [],
  },
  relatedLessons: [],
}

const lesson = createReducer(INITIAL_STATE, {
  [GET_LIVE_LESSON.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      liveLesson: payload,
    }
  },
  [GET_MONITORING.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      monitoring: payload,
    }
  },
  [GET_LESSON.PENDING]: state => {
    return {
      ...state,
      subjectLesson: INITIAL_STATE.subjectLesson,
    }
  },
  [GET_LESSON.FULFILLED]: (state, { payload: { questions, ...otherPayload } }) => {
    const sortedQuestions = questions.map(({ choices, ...q }) => ({
      ...q,
      choices: choices.sort((a, b) => a.index.localeCompare(b.index)),
    }))
    return {
      ...state,
      subjectLesson: { questions: sortedQuestions, ...otherPayload },
    }
  },

  [RATE_MONITORING.PENDING]: (state, { payload: rating }) => {
    return {
      ...state,
      monitoring: { ...state.monitoring, rating },
    }
  },

  [RATE_LESSON.PENDING]: (state, { payload: rating }) => {
    return {
      ...state,
      subjectLesson: { ...state.subjectLesson, rating },
    }
  },

  [ANSWER_QUESTION.FULFILLED]: (
    state,
    { payload: { correctAnswer: questionAnswer }, meta: { questionId, userAnswer } }
  ) => {
    const questions = state.subjectLesson?.questions.reduce((res, question) => {
      if (question.id === questionId) {
        const patchedQuestion = {
          ...question,
          userAnswer,
          questionAnswer,
          correctAnswer: userAnswer === questionAnswer,
        }
        res.push(patchedQuestion)
      } else {
        res.push(question)
      }
      return res
    }, [])
    return {
      ...state,
      subjectLesson: { ...state.subjectLesson, questions },
    }
  },

  [GET_RELATED_LESSONS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      relatedLessons: payload,
    }
  },

  [LOGOUT]: () => {
    return INITIAL_STATE
  },
})

export default lesson
