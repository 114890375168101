import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'
import parse from 'html-react-parser'

import { ReactComponent as ArrowDown } from '_assets/svgs/arrow-down.svg'
import { ReactComponent as ProCamera } from '_assets/svgs/pro-camera-logo.svg'
import { ReactComponent as Play } from '_assets/svgs/play.svg'
import { ReactComponent as Watched } from '_assets/svgs/watched.svg'

import styles from './styles.css'

const Topic = ({ position, title, watchedLessons, totalLessons, lessons, topicId, theme }) => {
  const [isOpen, setOpen] = useState(false)

  const handleClick = useCallback(() => setOpen(prev => !prev), [])

  const renderLessons = useMemo(() => {
    if (!isOpen) return null

    return lessons.map(({ name, completed, id }) => (
      <div className={styles.lesson} key={id}>
        <p>{name}</p>
        <Link to={`assuntos/${topicId}/aula/${id}`} className={styles.button}>
          {completed ? <Watched /> : <Play />}
        </Link>
      </div>
    ))
  }, [isOpen, lessons, topicId])

  return (
    <div className={styles.topic}>
      <button
        className={classnames(styles['top-section'], styles[`theme-${theme}`], {
          [styles['is-open']]: isOpen,
        })}
        type="button"
        onClick={handleClick}
      >
        <span className={styles.position}>{`${position}.`}</span>
        <p className={styles.title}>{parse(title)}</p>
        <ProCamera className={styles['pro-camera']} />
        <span className={styles.info}>{`${watchedLessons}/${totalLessons} aula${
          totalLessons !== 1 ? 's' : ''
        }`}</span>
        <ArrowDown className={classnames(styles.arrow, { [styles['is-open']]: isOpen })} />
      </button>
      {renderLessons}
    </div>
  )
}

Topic.propTypes = {
  position: PropTypes.number,
  title: PropTypes.string,
  watchedLessons: PropTypes.number,
  totalLessons: PropTypes.number,
  topicId: PropTypes.number,
  lessons: PropTypes.arrayOf(PropTypes.shape()),
  theme: PropTypes.string,
}

Topic.defaultProps = {
  position: 0,
  watchedLessons: 0,
  totalLessons: 0,
  topicId: 0,
  title: '',
  lessons: [],
  theme: 'red',
}

export default Topic
