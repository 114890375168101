import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { getTheme, getColorByTheme, getColorEvent, EVENTS } from '_utils/helpers'

import styles from './styles.css'

const { LESSON } = EVENTS

const StudyPlanTag = ({ event, type, selectedEvent, setSelectedEvent }) => {
  const onClick = useCallback(() => setSelectedEvent({ ...event, type }), [
    event,
    type,
    setSelectedEvent,
  ])
  const tagColor = getColorByTheme(getTheme(event?.topic?.subject?.area))
  const customStyle = {
    backgroundColor: `var(${type !== LESSON ? getColorEvent(type) : tagColor})`,
  }
  return (
    <button
      className={classnames(styles.button, { [styles.selected]: event?.id === selectedEvent?.id })}
      type="button"
      onClick={onClick}
    >
      <span className={classnames(styles.color, styles[type])} style={customStyle} />
      <p className={styles.text}>{event?.topic?.subject?.name || event.title}</p>
    </button>
  )
}

StudyPlanTag.propTypes = {
  type: PropTypes.string.isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  event: PropTypes.oneOfType([
    PropTypes.shape({ title: PropTypes.string }),
    PropTypes.shape({
      topic: PropTypes.shape({
        subject: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }),
  ]).isRequired,
  // TODO: Specify shape
  selectedEvent: PropTypes.shape({}),
}

StudyPlanTag.defaultProps = {
  selectedEvent: {},
}

export default StudyPlanTag
