import React, { useMemo } from 'react'
import classnames from 'classnames'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'

import Watch from '_assets/svgs/watch.svg'
import ExternLink from '_assets/svgs/extern-link.svg'
import Play from '_assets/svgs/play.svg'
import {
  getTheme,
  getColorByTheme,
  getColorEvent,
  EVENTS,
  removeBrFromString,
} from '_utils/helpers'

import styles from './styles.css'

const Tag = ({ data }) => {
  const tagColor = getColorByTheme(getTheme(data?.topic?.subject?.area))

  const customStyle =
    data.type !== EVENTS.LESSON
      ? {
          borderColor: `var(${getColorEvent(data.type)})`,
          color: `var(${getColorEvent(data.type)})`,
        }
      : { borderColor: `var(${tagColor})`, color: `var(${tagColor})` }

  const isInternLink =
    data.type === EVENTS.MONITORING ||
    data.type === EVENTS.LIVE_CLASSES ||
    data.type === EVENTS.LESSON

  const isLiveEvent = data.type === EVENTS.MONITORING || data.type === EVENTS.LIVE_CLASSES

  const formatInternalLink = useMemo(() => {
    switch (data.type) {
      case EVENTS.MONITORING:
        return `/monitoria/${data.id}`
      case EVENTS.LIVE_CLASSES:
        return `/aula-ao-vivo/${data.id}`
      case EVENTS.LESSON:
        return `/disciplinas/${data.topic?.subject?.id}/assuntos/${data.topic?.id}/aula/${data.id}`
      default:
        return ''
    }
  }, [data])
  const parseTopicName = useMemo(() => {
    if (data.type === EVENTS.LESSON && data.topic) {
      return removeBrFromString(data.topic.name)
    }
  }, [data.topic, data.type])

  return (
    <div className={styles.container}>
      <div className={classnames(styles.tag, styles[data.type])} style={customStyle}>
        <p className={styles.title}>{data.title || data.name}</p>
        {data.type === EVENTS.CUSTOM_EVENTS && (
          <div className={styles.time}>
            <p className={styles.subtitle}>{data.subtitle}</p>
            <p>{data.scheduledTime && ` às ${data.scheduledTime?.slice(0, 5)}`}</p>
          </div>
        )}
        {data.type === EVENTS.LESSON && (
          <p className={styles.subtitle} style={{ color: `#${data.fontColor}` }}>
            {`${data.topic?.subject?.name} - `}
            {parseTopicName}
          </p>
        )}
        {isLiveEvent && (
          <div className={styles.time}>
            <p className={styles.subtitle} style={customStyle}>
              {data.type === EVENTS.MONITORING ? 'MONITORIA' : 'AULA AO VIVO'}
            </p>
            <img className={styles.icon} alt="Icone de um Relógio" src={Watch} />
            <p>{data.scheduledTime && ` às ${data.scheduledTime?.slice(0, 5)}`}</p>
          </div>
        )}
      </div>
      {!isInternLink ? (
        <a
          className={styles.button}
          href={data.link}
          disabled={!data.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.link && <img alt="Abrir link" src={ExternLink} />}
        </a>
      ) : (
        <Link to={formatInternalLink} className={styles.button}>
          <img alt="Acessar conteúdo" src={Play} />
        </Link>
      )}
    </div>
  )
}

Tag.propTypes = {
  // TODO: Specify the shape
  data: PropTypes.shape({}).isRequired,
}

export default React.memo(Tag)
