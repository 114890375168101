import humps from 'humps'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import addSeconds from 'date-fns/addSeconds'

import redBackground from '_assets/svgs/subject-card-background-red.svg'
import blueBackground from '_assets/svgs/subject-card-background-blue.svg'
import greenBackground from '_assets/svgs/subject-card-background-green.svg'
import orangeBackground from '_assets/svgs/subject-card-background-orange.svg'

export const validateEmail = email => {
  const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailExpression.test(String(email).toLowerCase())
}

export const validatePassword = password => {
  return password.length >= 6
}

export const lottiesDefaultOptions = {
  autoplay: true,
  loop: false,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMax',
  },
}

export const createFormData = (data, isCamelized = false) => {
  const formData = new FormData()

  Object.keys(data).forEach(field => {
    const fieldValue = data[field]
    const formDataValue = (() => {
      if (!fieldValue) {
        return ''
      }

      if (fieldValue instanceof Blob || typeof fieldValue !== 'object') {
        return fieldValue
      }

      return JSON.stringify(fieldValue)
    })()

    formData.append(isCamelized ? field : humps.decamelize(field), formDataValue)
  })

  return formData
}

export const SUBJECT_THEMES = {
  RED: 'red',
  GREEN: 'green',
  BLUE: 'blue',
  ORANGE: 'orange',
}

// TO DO: this should come from the backend
export const getTheme = name => {
  switch (name) {
    case 'Exatas':
      return SUBJECT_THEMES.RED
    case 'Humanas':
      return SUBJECT_THEMES.GREEN

    case 'Especiais':
      return SUBJECT_THEMES.BLUE

    case 'Linguagens':
      return SUBJECT_THEMES.ORANGE

    default:
      break
  }
  return SUBJECT_THEMES.RED
}

export const getColorByTheme = theme => {
  switch (theme) {
    case SUBJECT_THEMES.GREEN:
      return '--dark-green'
    case SUBJECT_THEMES.ORANGE:
      return '--dark-orange'
    case SUBJECT_THEMES.blue:
      return '--light-blue'
    case SUBJECT_THEMES.RED:
    default:
      return '--dark-red'
  }
}

export const getThemeBackground = theme => {
  switch (theme) {
    case SUBJECT_THEMES.RED:
      return redBackground

    case SUBJECT_THEMES.GREEN:
      return greenBackground

    case SUBJECT_THEMES.BLUE:
      return blueBackground

    case SUBJECT_THEMES.ORANGE:
      return orangeBackground

    default:
      return redBackground
  }
}

export const EVENTS = {
  MONITORING: 'monitoring',
  CUSTOM_EVENTS: 'customEvents',
  LIVE_CLASSES: 'liveClasses',
  LESSON: 'lessons',
}

export const getColorEvent = theme => {
  switch (theme) {
    case EVENTS.MONITORING:
      return '--dark-blue'
    case EVENTS.CUSTOM_EVENTS:
      return '--light-red-hover'
    case EVENTS.LIVE_CLASSES:
      return '--light-red'
    default:
      return null
  }
}

export const validateCpf = cpf => {
  const cpfFormated = cpf.replace(/[^\d]+/g, '')
  let numbers
  let digits
  let sum
  let i
  let result
  let equals
  equals = 1
  if (cpfFormated.length < 11) return false
  for (i = 0; i < cpfFormated.length - 1; i += 1)
    if (cpfFormated.charAt(i) !== cpfFormated.charAt(i + 1)) {
      equals = 0
      break
    }
  if (!equals) {
    numbers = cpfFormated.substring(0, 9)
    digits = cpfFormated.substring(9)
    sum = 0
    for (i = 10; i > 1; i -= 1) sum += numbers.charAt(10 - i) * i
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result !== parseInt(digits.charAt(0), 10)) return false
    numbers = cpfFormated.substring(0, 10)
    sum = 0
    for (i = 11; i > 1; i -= 1) sum += numbers.charAt(11 - i) * i
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result !== parseInt(digits.charAt(1), 10)) return false
    return true
  }
  return false
}

export const convertDateResponse = date => {
  if (!date) return null
  const currentDate = parse(date, 'yyyy-MM-dd', new Date())
  return format(currentDate, 'dd/MM/yyyy')
}

export const convertDateRequest = date => {
  if (!date) return null
  return format(date, 'yyyy-MM-dd')
}

export const convertDateStringToRequest = date => {
  if (!date) return null
  const currentDate = parse(date, 'dd/MM/yyyy', new Date())
  return format(currentDate, 'yyyy-MM-dd')
}

export const calculateFileSize = size => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  if (size === 0) return '0 Bytes'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / 1024 ** i).toFixed(2)} ${sizes[[i]]}`
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getYoutubeVideoId = videoUrl => videoUrl?.match(/(?:v=|youtu.be\/)(.*)/)?.[1]

export const getYoutubeEmbedURL = videoId =>
  videoId ? `https://www.youtube.com/embed/${videoId}` : null

export const getYoutubeLiveCommentsURL = videoId =>
  videoId
    ? `https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${window.location.hostname}`
    : null

export const getResetToday = () => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
}

export const formattedTime = seconds => {
  if (Number.isNaN(seconds)) return ''

  const helperDate = addSeconds(new Date(0), seconds)
  return format(helperDate, 'mm:ss')
}

export const removeBrFromString = str => {
  let res = str
  const re = new RegExp('( ?<br/?>)', 'gi')
  let match = re.exec(res)
  do {
    const nextMatch = re.exec(res)
    if (nextMatch) {
      res = res.replace(/( ?<br\/?>)/, ', ')
    } else {
      res = res.replace(/( ?<br\/?>)/, ' e ')
    }
    match = nextMatch
  } while (match)

  return res
}

export const getReviewType = type => {
  switch (type) {
    case 'exam':
      return 'Vestibular'
    case 'enem':
      return 'ENEM'
    default:
      return 'Concurso'
  }
}

export const urlRegexPattern = /^http(s)?:\/\/(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

export const planPro = {
  name: 'Plano Pró',
  parcelsOptions: {
    '12': { price: 119.88, id: 36 },
    '6': { price: 83.94, id: 38 },
    '3': { price: 47.94, id: 39 },
  },
}
