import React, { useCallback, useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import StarRatingComponent from 'react-star-rating-component'
import { Link } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Arrow from '_assets/svgs/pink-left-arrow.svg'
import Report from '_components/report'
import Exercise from '_components/exercise'
import { getLesson, rateLesson, getRelatedLessons, watchLesson } from '_modules/lesson/actions'
import { getSubject } from '_modules/subjects/actions'
import { currentSubjectSelector } from '_modules/subjects/selectors'
import { getSubjectLesson, relatedLessonsSelector } from '_modules/lesson/selectors'
import VideoPlayList from '_components/video-playlist'
import TodayLessonsCard from '_components/cards/today-lessons-card'
import ReportErrorModal from '_components/report-error-modal'
import { getProfileData } from '_modules/user/actions'
import { getUserSelector, getUserKeySelector } from '_modules/user/selectors'

import styles from './styles.css'

const Lesson = ({ subjectId, topicId, lessonId }) => {
  const reportErrorModalRef = useRef()
  const reactPlayerRef = useRef()
  const currentSubject = useSelector(currentSubjectSelector)
  const relatedLessons = useSelector(relatedLessonsSelector)
  const { rating: currentRating, videoUrl, name: subjectName, questions, progress } = useSelector(
    getSubjectLesson
  )
  const dispatch = useDispatch()
  const userKey = useSelector(getUserKeySelector)
  const isTrial = useSelector(getUserSelector)?.onFreeTrial

  useEffect(() => {
    if (isTrial) {
      dispatch(getProfileData())
    }
  }, [dispatch, isTrial, lessonId])

  const initialTime = useCallback(() => {
    const currentPlayer = reactPlayerRef?.current
    if (progress && currentPlayer) {
      const duration = currentPlayer.getDuration()
      const secondToSeek = (duration * progress) / 100
      currentPlayer.seekTo(secondToSeek, 'seconds')
    }
  }, [progress])

  const setLessonProgress = useCallback(() => {
    const currentTime = reactPlayerRef?.current?.getCurrentTime() || 0
    const duration = reactPlayerRef?.current?.getDuration()
    const newProgress = (currentTime * 100) / duration
    if (newProgress) {
      dispatch(watchLesson({ subjectId, topicId, lessonId, progress: newProgress, key: userKey }))
    }
  }, [dispatch, lessonId, subjectId, topicId, userKey])

  const startVideoTracking = useCallback(() => {
    setLessonProgress()
    setInterval(setLessonProgress, 60000)
  }, [setLessonProgress])

  const stopVideoTracking = useCallback(() => {
    if (userKey) {
      setLessonProgress()
      clearInterval(setLessonProgress)
    }
  }, [setLessonProgress, userKey])

  useEffect(() => {
    dispatch(getSubject(subjectId))
    dispatch(getRelatedLessons({ subjectId, topicId, lessonId }))
    dispatch(getLesson({ subjectId, topicId, lessonId }))
  }, [dispatch, lessonId, subjectId, topicId])

  useEffect(() => {
    return () => {
      stopVideoTracking()
    }
  }, [stopVideoTracking])

  // TODO: Implement content selector
  const isDayLessons = false

  const [rating, setRating] = useState(currentRating)

  useEffect(() => {
    setRating(currentRating || 0)
  }, [currentRating])

  const onHating = useCallback(
    newRating => {
      dispatch(rateLesson({ subjectId, rating: newRating, topicId, lessonId }))
      setRating(newRating)
    },
    [dispatch, lessonId, subjectId, topicId]
  )

  const handleTemporaryRate = useCallback(value => setRating(value), [])

  const onRatingOut = useCallback(() => {
    setRating(currentRating || 0)
  }, [currentRating])

  const openModal = useCallback(
    () => reportErrorModalRef.current && reportErrorModalRef.current.setVisibility(true),
    []
  )

  return (
    <div className={styles.container}>
      <div className={styles['video-content']}>
        <div className={styles.wrapper}>
          {videoUrl && (
            <ReactPlayer
              ref={reactPlayerRef}
              url={videoUrl}
              onPlay={startVideoTracking}
              onPause={stopVideoTracking}
              onEnded={stopVideoTracking}
              onStart={initialTime}
              width="100%"
              height="100%"
              controls
              className={styles['react-player']}
            />
          )}
        </div>
        <div className={styles['video-footer']}>
          <h1 className={styles.topic}>{subjectName}</h1>

          <div className={styles.buttons}>
            <StarRatingComponent
              name=""
              className={styles.rater}
              starCount={5}
              value={rating}
              onStarHover={handleTemporaryRate}
              onStarHoverOut={onRatingOut}
              editing
              emptyStarColor="#afafaf"
              onStarClick={onHating}
            />
            <Report className={styles.report} onClick={openModal} />
            <ReportErrorModal ref={reportErrorModalRef} />
          </div>
        </div>
        <div className={styles.content}>
          <Link to={`/disciplinas/${subjectId}`} className={styles.link}>
            <img src={Arrow} alt={`Icone voltar para ${currentSubject.name}`} />
            <span className={styles['link-text']}>{currentSubject.name}</span>
          </Link>
        </div>
      </div>
      {!!questions?.length && (
        <Exercise
          exercises={questions}
          subjectId={subjectId}
          topicId={topicId}
          lessonId={lessonId}
        />
      )}
      <div className={styles.side}>
        {!isDayLessons ? <VideoPlayList videos={relatedLessons} /> : <TodayLessonsCard />}
      </div>
    </div>
  )
}

Lesson.propTypes = {
  subjectId: PropTypes.string.isRequired,
  topicId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
}

Lesson.defaultProps = {}

export default React.memo(Lesson)
