import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from '@reach/router'
import format from 'date-fns/format'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as Info } from '_assets/svgs/information.svg'
import Breadcrumb from '_components/breadcrumb'
import Loader from '_components/loader'
import Button, { BUTTON_THEMES } from '_components/button'
import { getMockExamsResults } from '_modules/mock-exams/actions'
import {
  mockExamsResultsSelector,
  mockExamsResultsLoadingSelector,
} from '_modules/mock-exams/selectors'
import { ROUTES } from '_utils/constants'

import styles from './styles.css'

const EXAMS_TYPE = {
  ENEM: { title: 'ENEM', param: 'enem' },
  EXAM: { title: 'Vestibulares', param: 'entrance_exam' },
}

const MockExams = ({ navigate, uri }) => {
  const examType = uri.includes(ROUTES.ENEM_EXAMS) ? EXAMS_TYPE.ENEM.title : EXAMS_TYPE.EXAM.title
  const mockExamsResults = useSelector(mockExamsResultsSelector)?.sort(
    (a, b) => new Date(b.finishedAt).getTime() - new Date(a.finishedAt).getTime()
  )
  const isLoadingExamsResults = useSelector(mockExamsResultsLoadingSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getMockExamsResults(examType === 'ENEM' ? EXAMS_TYPE.ENEM.param : EXAMS_TYPE.EXAM.param)
    )
  }, [dispatch, examType])

  const handleNewExam = useCallback(() => navigate('novo'), [navigate])

  const renderResults = useMemo(() => {
    if (isLoadingExamsResults) {
      return <Loader />
    }
    if (!mockExamsResults.length) {
      return <p className={styles['no-results']}>Você ainda não fez nenhum simulado.</p>
    }

    return mockExamsResults.map(({ id, grade, timeSpent, exam, finishedAt }) => (
      <div className={styles.row} key={id}>
        <span className={styles.exam}>{`${exam?.entranceExam?.name} ${exam?.name}`}</span>
        <p className={styles.subject}>{`Finalizado: ${format(
          new Date(finishedAt),
          `dd/MM/yyyy 'às' HH:mm`
        )}`}</p>
        <div className={styles.info}>
          <p>
            Nota: <span>{grade?.toFixed(2)}</span>
          </p>
          <p>
            Tempo: <span>{timeSpent}</span>
          </p>
          <Link className={styles['details-link']} to={`resultado/${id}`}>
            VER DETALHES
          </Link>
        </div>
      </div>
    ))
  }, [isLoadingExamsResults, mockExamsResults])

  return (
    <div className={styles.container}>
      <Breadcrumb text="Dashboard" to={ROUTES.DASHBOARD} />
      <h1 className={styles.title}>Simulados - {examType}</h1>
      <div className={styles.results}>
        <div className={styles.subtitle}>
          <h2 className={styles['results-title']}>Resultados</h2>
          <Info data-tip data-for="info-tooltip" className={styles['tooltip-icon']} />
          <ReactTooltip
            id="info-tooltip"
            type="dark"
            effect="solid"
            aria-haspopup="true"
            place="right"
          >
            <p>O resultado pode demorar alguns segundos</p>
            <p>para ser processado após finalizado.</p>
          </ReactTooltip>
        </div>
        {renderResults}
        <Button theme={BUTTON_THEMES.PINK_NORMAL} className={styles.button} onClick={handleNewExam}>
          Novo simulado
        </Button>
      </div>
    </div>
  )
}

MockExams.propTypes = {
  navigate: PropTypes.func,
  uri: PropTypes.string,
}

MockExams.defaultProps = {
  navigate: () => {},
  uri: '',
}

export default MockExams
