import { defineAction } from '_utils/redux'
import * as requests from '_services/materials'

export const GET_MATERIALS_SUBJECTS = defineAction('GET_MATERIALS_SUBJECTS')
export const GET_MATERIALS = defineAction('GET_MATERIALS')

export const getMaterialsSubject = params => (dispatch, getState) =>
  dispatch({
    type: GET_MATERIALS_SUBJECTS.ACTION,
    payload: requests.getMaterialsSubject(getState().user.key, params),
  })

export const getMaterials = id => (dispatch, getState) =>
  dispatch({
    type: GET_MATERIALS.ACTION,
    payload: requests.getMaterials(getState().user.key, id),
  })
