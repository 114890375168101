import { createReducer } from '_utils/redux'

import { GET_MATERIALS_SUBJECTS, GET_MATERIALS } from './actions'

const INITIAL_STATE = {
  subjects: [],
  currentMaterial: '',
}

const materials = createReducer(INITIAL_STATE, {
  [GET_MATERIALS_SUBJECTS.FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      subjects: payload,
    }
  },
  [GET_MATERIALS.FULFILLED]: (state, { payload }) => {
    const currentMaterial = payload.length ? payload[0].handoutFile : ''
    return {
      ...state,
      currentMaterial,
    }
  },
})

export default materials
