import React from 'react'

import { ReactComponent as WhatsappIcon } from '_assets/svgs/whatsapp-green.svg'

import HeaderSection from './header-section'
import PlatformSection from './platform-section'
import PlansSection from './plans-section'
import TourSection from './tour-section'
import RecommendsSection from './recommends-section'
import MediaSection from './media-section'
import BlogSection from './blog-section'
import FooterSection from './footer-section'
import styles from './styles.css'

const Home = () => {
  return (
    <div className={styles.container}>
      <HeaderSection />
      <PlatformSection />
      <PlansSection />
      <TourSection />
      <RecommendsSection />
      <MediaSection />
      <BlogSection />
      <FooterSection />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://api.whatsapp.com/send?phone=+5548996582633&text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20uma%20d%C3%BAvida!"
        className={styles.whats}
      >
        <WhatsappIcon />
      </a>
    </div>
  )
}

export default Home
